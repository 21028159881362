import "shards-ui/dist/css/shards.min.css";
import "./AddQuestion.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useParams,
} from "react-router-dom";
import Al from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  FormTextarea,
  FormCheckbox,
  Fade,
  FormRadio,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
} from "shards-react";
import { Form, FormInput, FormGroup } from "shards-react";
import { SubmitEditDeckGroupInfo , GetGroupInfo } from "../../APIManager";

const EditDeckGroupInfo = () => {
  const [groupName, SetGroupName] = useState("");
  const [groupDescription, SetGroupDescription] = useState("");
  const [image, SetImage] = useState("");

  const [showModal, SetShowModal] = useState(false);
  const [showErrorModal , SetShowErrorModal] = useState(false);

  const [isValidationError, setisValidationError] = useState(false);
  const [validationErrorMessages, setValidationErrorMessages] = useState([]);
  var fileInput;

  let { name } = useParams();

  useEffect(() => {
    GetGroupInfo(name).then((res) => {
      console.log(res)
      if(res.data.Error){
       SetShowErrorModal(true)
      }
      else{
        var Group = res.data[0];
        console.log(Group);
        SetGroupName(Group.Name);
        SetGroupDescription(Group.Description);
        SetImage(Group.Image);
      }
     
    });
  }, []);




  function ResetForm() {
    fileInput.value = "";
    SetImage("");

    SetGroupName("");
    SetGroupDescription("");
  }

  function ValidateInputs() {
    var messages = [];
    // Validate Deck Name
    if (groupName === "" || groupName.length === 0) {
      messages.push("Group Name cannot be left empty");
    }
    // Validate Deck Description
    if (groupDescription === "" || groupDescription.length === 0) {
      messages.push("Group Description cannot be left empty");
    }
    /*  if (
      image === undefined ||
      image === null ||
      image === "" ||
      image.length === 0
    ) {
      messages.push("An image for the deck preview must be uploaded");
    } */
    //Return Logic for Function
    if (messages.length === 0) {
      setValidationErrorMessages([]);
      setisValidationError(false);
      return true;
    } else {
      setValidationErrorMessages([...messages]);
      setisValidationError(true);
      scrollToTop();
      return false;
    }
  }

  function OnSubmitClicked() {
    if (ValidateInputs() === true) {
      SubmitEditDeckGroupInfo({
        groupName: groupName,
        groupDescription: groupDescription,
        groupImage: image,
      })
        .then((res) => ResolveResponse(res))
        .catch((error) => {
          HandleSubmitError(error);
        });
    } else {
      scrollToTop();
    }
  }
  function ResolveResponse(res) {
    var data = res.data;
    if (data.Error) {
      setValidationErrorMessages([...[data.ErrorText]]);
      setisValidationError(true);
      scrollToTop();
    }
    if (data.Status === "Success") {
      console.log("Saved Successfully");
      setisValidationError(false);
      SetShowModal(true);
    }
  }
  function HandleSubmitError(error) {
    if (error.response) {
      var messages = [];
      messages.push("Could not save as server returned an error");
      setisValidationError(true);
      setValidationErrorMessages([...messages]);
      scrollToTop();
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      var messages = [];
      messages.push("Network Error / Cannot reach server");
      setisValidationError(true);
      setValidationErrorMessages([...messages]);
      scrollToTop();
    } else {
      // Something happened in setting up the request that triggered an Error
      var messages = [];
      messages.push("Could not save due to unknown error");
      setisValidationError(true);
      setValidationErrorMessages([...messages]);
      scrollToTop();
    }
  }
  return (
    <div className="flexMe">

<Modal open={showErrorModal} toggle={() => {}}>
        <ModalHeader>Error</ModalHeader>
        <ModalBody>
          <h4> Could not find Group to edit</h4>
          It is possible that the group was deleteted /  info was changed such that this link is no longer valid. Please go back to the Group Manager to generate a new link
          <br/>
          <br/>
          <Button>Return to Group Manager</Button>
        </ModalBody>
      </Modal>

      <Modal open={showModal} toggle={() => {}}>
        <ModalHeader>Success</ModalHeader>
        <ModalBody>
          <h4> Deck Group Successfully Saved</h4> <br />
          
          <Button>Return to Group Manager</Button>
        </ModalBody>
      </Modal>

      <Card>
        <CardHeader className="Header">
          <h3 style={{ textAlign: "center", fontfamily: "Roboto" }}>
            Edit Group Info
          </h3>
        </CardHeader>

        <CardBody className="CardBody">
          <ErrorCard
            isVisible={isValidationError}
            messages={validationErrorMessages}
          />

          <label htmlFor="Deck Name">
            <h5 style={{ marginTop: "1.5rem" }}>Group Name / Title</h5>
          </label>
          <FieldInput
            id="#Group Name"
            placeholder="Group Name"
            type="text"
            multiline={false}
            size="sm"
            value={groupName}
            onChange={(e) => {
           
            }}
            /*  invalid={!this.state.TextFields.FullNameIsValid}
                errorText={this.state.TextFields.FullNameErrorText} */
          />

          <hr />
          <label htmlFor="Group Description">
            <h5 style={{ marginTop: "0rem" }}>Group Description</h5>
          </label>
          <FieldInput
            style={{ height: "20px" }}
            id="#Group Description"
            placeholder="Group Description"
            onChange={() => {}}
            type="text"
            multiline={true}
            value={groupDescription}
            onChange={(e) => {
              SetGroupDescription(e.target.value);
            }}
            /*  invalid={!this.state.TextFields.FullNameIsValid}
                errorText={this.state.TextFields.FullNameErrorText} */
          />
          <hr />
          <h5 style={{ marginTop: "0rem" }}>Add image for Group Preview</h5>
          <input
            style={{ marginTop: ".5rem" }}
            type="file"
            name="Question Image"
            accept=".png,.jpeg,.jpg"
            ref={(ref) => (fileInput = ref)}
            onChange={(e) => {
              console.log(e.target.files[0]);
              if (e.target.files[0] !== undefined) {
                let file = e.target.files[0];
                let reader = new FileReader();
                reader.addEventListener("load", function (e) {
                  let text = e.target.result;
                  console.log(text);
                  SetImage(text);
                });
                reader.readAsDataURL(file);
              } else {
                SetImage("");
              }
            }}
          />
          <ImagePreview
            image={image}
            onDelete={() => {
              fileInput.value = "";
              SetImage("");
            }}
          />
          <hr />
          <div class="form-row text-center">
            <div class="col-12">
              <div className="centerMe">
                <Button
                  style={{ marginTop: "2rem" }}
                  pill
                  theme="success"
                  onClick={() => {
                    OnSubmitClicked();
                  }}
                >
                 Save Edits
                </Button>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

function FieldInput(props) {
  const id = props.id;
  const placeholder = props.placeholder;
  const type = props.type;
  const errorText = props.errorText;
  const invalid = props.invalid;
  const valid = props.valid;
  const onChange = props.onChange;
  const shake = props.shake;
  const onAnimationEnd = props.onAnimationEnd;

  function handleChange(e) {
    onChange(e);
  }

  var shakeStyle;
  if (shake == true) {
    shakeStyle = {
      animation: "shake .5s",
    };
  } else {
    shakeStyle = {
      animation: "",
    };
  }
  if (props.multiline === true) {
    return (
      <div>
        <FormTextarea
          id={props.id}
          placeholder={props.placeholder}
          type={props.type}
          value={props.value}
          onChange={handleChange}
          invalid={invalid}
          valid={valid}
          style={shakeStyle}
          onAnimationEnd={onAnimationEnd}
          style={{ height: "100px" }}
        />
        <span style={{ color: "red" }}>{props.errorText}</span>
      </div>
    );
  }
  return (
    <div>
      <FormInput
        id={props.id}
        placeholder={props.placeholder}
        type={props.type}
        value={props.value}
        onChange={handleChange}
        invalid={invalid}
        valid={valid}
        style={shakeStyle}
        onAnimationEnd={onAnimationEnd}
      />
      <span style={{ color: "red" }}>{props.errorText}</span>
    </div>
  );
}
function ImagePreview(props) {
  var { image, onDelete } = props;
  if (image !== undefined && image !== null && image.length > 0) {
    return (
      <div>
        <img
          src={image}
          width="350px"
          style={{ marginTop: "10px", marginRight: "10px" }}
        />
        <Button
          id={"delete image"}
          theme="danger"
          size="sm"
          onClick={(e) => {
            onDelete();
          }}
          style={{ marginTop: "1rem", marginBottom: "1.5rem" }}
        >
          Remove Image
        </Button>
      </div>
    );
  } else {
    return <div />;
  }
}
function ErrorCard(props) {
  if (props.isVisible) {
    return (
      <Al onClose={props.onClose} severity="error">
        <AlertTitle>Error</AlertTitle>
        {props.messages.map((message) => {
          return <div>{message}</div>;
        })}
      </Al>
    );
  } else {
    return <div></div>;
  }
}
function scrollToTop() {
  var position = document.body.scrollTop || document.documentElement.scrollTop;
  if (position) {
    window.scrollBy(0, -Math.max(1, Math.floor(position / 10)));
    var scrollAnimation = setTimeout(() => {
      scrollToTop();
    }, 10);
  } else clearTimeout(scrollAnimation);
}

export default EditDeckGroupInfo;
