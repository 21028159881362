import React from "react";
import styles from "./styles.module.css";

export default function NotAuthorizedPage() {
  return (
    <div className={styles.Container}>
      <h1>Not Authorized</h1>
      <div>
        You do not have permissions to view this page.
        <br /> Please contact your supervisor if you believe this is an error.
      </div>
    </div>
  );
}
