/**
 @module FileNameOrCustomModuleName
*/
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Button, CardBody, CardHeader } from "shards-react";
import { backendUrl } from "../../Constants/appConstants";
import AWS from "aws-sdk";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {getCommissionDetailsApi} from "../../APIs/CouponAPI";
import { getISOStringWithoutSecsAndMillisecs1 } from "./CouponEditor";

const spacesEndpoint = new AWS.Endpoint("sgp1.digitaloceanspaces.com");

const s3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: "2YSVU3TQ2F6QUS3DQRYQ",
  secretAccessKey: "pgcOj5mqzb/caF5+MSoF+44ffSjb778RIr9bnv0TUu8",
});

export default function ReimbursementScreen() {
  const [modalOpen, SetModalOpen] = useState(false);
  const [payments, setPayments] = useState([])
  const { userId: representativeId } = useParams();
  const [repUserId, setRepUserId] = useState("");
  const [rows, setRows] = useState([]);
  const [commissionInfo, setCommissionInfo] = useState({});
  const [link, setLink] = useState("");
  const [proofOfPayment, SetProofOfPayment] = useState("");

  const searchParams = new URLSearchParams(useLocation().search);
  const designation = searchParams.get("designation");
  useEffect(() => {
    async function func() {
      let CADetails = await getCommissionDetailsApi({representativeId, designation});

      setRepUserId(CADetails.data.userId)
      console.log(
        "🚀 ~ file: RepresentativeDashboard.jsx:60 ~ func ~ searchParams:",
        searchParams
      );
      setCommissionInfo(CADetails.data);
      setRows(prepareDataForTable(CADetails));
    }
    func();
  }, []);

  useEffect(() => {
    async function func() {
      setPayments((await axios.get(`${backendUrl}/api/commission/${repUserId}`)).data)
    }
    if(repUserId) func()
  }, [repUserId]);

  return (
    <>
      <Box padding={10}>
        <Grid container direction="column" spacing={10}>
          <Grid container item spacing={10} xs>
            <Grid item xs>
              <Card>
                <CardContent>
                  <Typography
                    align="center"
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Total Coupon Commission
                  </Typography>
                  <Typography color="text.secondary" align="center">
                    {commissionInfo.TotalCommission ?? 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card>
                <CardContent>
                  <Typography
                    align="center"
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Pending Balance
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                    align="center"
                  >
                    {commissionInfo.Total ?? 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card>
                <CardContent>
                  <Typography
                    align="center"
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Total Payments Amount
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                    align="center"
                  >
                    {commissionInfo.TotalPaid ?? 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card sx={{ width: 275 }}>
                <CardContent>
                  <Typography
                    align="center"
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Total Coupons Redeemed
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                    align="center"
                  >
                    {rows.length}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container justify="center">
            {/* <Button href={link}>Upload Payment</Button> */}
            {/* <UploadImage id={120912} /> */}
            {/* <ImageUploadButton SetProofOfPayment={SetProofOfPayment} /> */}
            <Button onClick={() => SetModalOpen(true)}>Make Payment</Button>
            <PaymentModal
              key={`PaymentModal ${modalOpen}`}
              modalOpen={modalOpen}
              SetModalOpen={SetModalOpen}
              commissionInfo={commissionInfo}
              onSubmit={async () => {
                SetModalOpen(false);
                setPayments((await axios.get(`${backendUrl}/api/commission/${repUserId}`)).data)
                // RetreivePayments();
              }}
            />
            
          </Grid>
          <br/>
          <br/>

          <Grid container direction="column" justify="center" alignContent="center">
              {payments.map((p) => {
                return (
                  <div style={{ width: "50%", marginBottom: "1em" }}>
                    <Card>
                      <CardHeader>
                        Amount: <strong>Rs. {p.Amount}</strong>
                      </CardHeader>
                      <CardBody>
                        <div> Done By: {p.DoneBy}</div>
                        <div>
                          {" "}
                          Date/Time: {new Date(p.DatePak).toUTCString()}
                        </div>
                        Notes:
                        <div
                          style={{
                            whiteSpace: "pre-line",
                            fontSize: "0.8em",
                            fontStyle: "italic",
                          }}
                        >
                          {p.Notes}
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                );
              })}
            </Grid>
        </Grid>
      </Box>
    </>
  );
}

function ImageUploadButton({ SetProofOfPayment }) {
  const fileInputRef = useRef();

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      let reader = new FileReader();
      reader.addEventListener("load", function (e) {
        let text = e.target.result;
        SetProofOfPayment(text);
        console.log(text);
      });
      reader.readAsDataURL(file);
      console.log(file);
    }
  };

  return (
    <>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <Button onClick={handleClick}>Upload Image</Button>
    </>
  );
}

const PaymentModal = ({ modalOpen, SetModalOpen, onSubmit, commissionInfo }) => {
  let { couponCode } = useParams();

  var FileInput;

  const [amount, SetAmount] = useState(1);
  const [date, SetDate] = useState(
    new Date(new Date().getTime() + 0 * 3600 * 1000)
  );
  const [author, SetAuthor] = useState("");
  const [notes, SetNotes] = useState("");

  const [proofOfPayment, SetProofOfPayment] = useState("");

  const [amountValid, SetAmountValid] = useState(true);
  const [authorValid, SetAuthorValid] = useState(false);
  const [proofOfPaymentValid, SetProofOfPaymentValid] = useState(false);

  const [amountErrorText, SetAmountErrorText] = useState("");
  const [authorErrorText, SetAuthorErrorText] = useState("");
  const [proofOfPaymentErrorText, SetProofOfPaymentErrorText] = useState("");

  function OnAmountChange(e) {
    var newValue = Math.floor(parseInt(e.target.value)).toFixed(0);
    SetAmount(newValue);
    ValidateAmount(newValue);
  }
  function ValidateAmount(newAmount) {
    if (isNaN(newAmount)) {
      SetAmountErrorText("Amount can't be empty");
      SetAmountValid(false);
      return false;
    }
    if (newAmount > 100000) {
      SetAmountErrorText("Payment cant be more than Rs 100,000");
      SetAmountValid(false);
      return false;
    }
    if (newAmount < 1) {
      SetAmountErrorText("Payment cant be less than Rs 1");
      SetAmountValid(false);
      return false;
    } else {
      SetAmountErrorText("");
      SetAmountValid(true);
    }
  }
  function OnAuthorChange(e) {
    var newValue = e.target.value.toLowerCase();
    SetAuthor(newValue);
    ValidateAuthor(newValue);
  }
  function ValidateAuthor(newAuthor) {
    if (newAuthor.length === 0) {
      SetAuthorErrorText("Author can't be empty");
      SetAuthorValid(false);
      return false;
    } else {
      SetAuthorValid(true);
      SetAuthorErrorText("");
      return true;
    }
  }

  function OnNotesChange(e) {
    var newValue = e.target.value;
    SetNotes(newValue);
  }

  return (
    <Dialog
      scroll="body"
      open={modalOpen}
      // TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        SetModalOpen(false);
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"sm"}
      fullWidth={"sm"}
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Create new payment"}
      </DialogTitle>
      <DialogContent>
        <TextField
          value={amount}
          variant="outlined"
          onChange={OnAmountChange}
          error={!amountValid}
          autoFocus
          helperText={!amountValid ? amountErrorText : ""}
          margin="dense"
          id="Complaint"
          type="number"
          fullWidth
          label="Discount Amount"
          inputProps={{ maxLength: 400 }}
        />
        <TextField
          value={author}
          variant="outlined"
          onChange={OnAuthorChange}
          error={!authorValid}
          autoFocus
          helperText={!authorValid ? authorErrorText : ""}
          margin="dense"
          id="Complaint"
          type="text"
          fullWidth
          label="Who is making this payment"
          inputProps={{ maxLength: 400 }}
        />
        <br />
        <TextField
          value={notes}
          variant="outlined"
          onChange={OnNotesChange}
          autoFocus
          margin="dense"
          id="Complaint"
          type="text"
          fullWidth
          label="Notes"
          inputProps={{ maxLength: 400 }}
          multiline
        />
        <br />
        <br />
        <TextField
          id="date"
          variant="outlined"
          label="Date of payment"
          type="datetime-local"
          onChange={(e) => {
            console.log(e.target.value);
            SetDate(new Date(e.target.value));
          }}
          value={getISOStringWithoutSecsAndMillisecs1(date)}
          defaultValue={getISOStringWithoutSecsAndMillisecs1(date)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <div>{date.toLocaleString()}</div>
        <br />
        <br />
        <ImageInput
          name="Payment Proof"
          setImage={SetProofOfPayment}
        />
        <br />
        <Button
          disabled={!(amountValid & authorValid & (proofOfPayment !== ""))}
          style={{ margin: "1em" }}
          onClick={() => {
            var utcDate = date.toISOString();
            var tempDate = new Date(date);
            tempDate.setHours(tempDate.getHours() + 5);
            var pakDate = tempDate.toISOString();
            axios
              .post(`${backendUrl}/api/commission/recordCommissionPayment`, {
                CouponCode: couponCode,
                Amount: amount,
                Date: utcDate,
                DatePak: pakDate,
                DoneBy: author,
                Notes: notes,
                ProofOfPayment: proofOfPayment,
                username: commissionInfo.username,
              })
              .then((res) => {
                console.log("wohoohoh");
                // console.log("🚀 ~ file: ReimbursementScreen.jsx:423 ~ )).then ~ res:", res)
                onSubmit();
              });
          }}
          variant="contained"
          color="primary"
        >
          Record Payment
        </Button>
      </DialogContent>
    </Dialog>
  );
};

/**
 *A function that maps the data attribute _id to id so that it can be used by the Table
 *
 * @param {string} data the data from api returned json
 * @return {*} data that can be consumed by the Table
 */
function prepareDataForTable(data) {
  return data.data.Orders.map((val) => ({ ...val, id: val._id }));
}

/**
 *A function that finds the total commission for a given user
 *
 * @param {*} orders an array containing objects of order
 * @return {*} the total commission amount as a number
 */
function getSumOfAcceptedOrders(orders) {
  let orderArray = orders.map((order) => order);
  return orderArray.reduce(
    (total, order) =>
      order.OrderStatus === "Accepted"
        ? total + Number(order.DiscountAmount)
        : total,
    0
  );
}



export function ImageInput({ name, setImage}) {
  return (
    <input
          type="file"
          name={name}
          accept=".png,.jpeg,.jpg"
          onChange={(e) => {
            console.log(e.target.files);
            if (e.target.files[0] !== undefined) {
              let file = e.target.files[0];
              let reader = new FileReader();
              reader.addEventListener("load", function (e) {
                let text = e.target.result;
                setImage(text);
                console.log(text);
              });
              reader.readAsDataURL(file);
            } else {
              setImage("");
            }
          }}
        />
  )
}
