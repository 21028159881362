import React, { Component, useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { GetTags, AddNewTag } from "../../APIManager";
import "./TagsEditor.css";
import _ from "lodash";

import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  FormTextarea,
  FormCheckbox,
  Fade,
  FormRadio,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
  FieldInput,
  Button,
} from "shards-react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
  { field: "id", headerName: "ID", flex: 0.5, type: "number" },
  { field: "name", headerName: "Tag Name", flex: 4 },
  { field: "visible", headerName: "visible", flex: 1 },
];

export default function DataTable() {
  const [rows, SetRows] = useState([]);

  useEffect(() => {
    GetTags().then((res) => {
      SetRows(res.data);
    });
  }, []);

  var data = [];

  const [open, setOpen] = React.useState(true);
  const SubmitTag = (tagName) => {
    AddNewTag(tagName).then((res) => {
      GetTags().then((res) => {
        SetRows(res.data);
      });
    });
    setOpen(false);
  };

  return (
    <Card>
      <AddNewTagPopup
        key={"AddNewTagPopup" + open}
        open={open}
        data={rows}
        onSubmit={SubmitTag}
        onClose={() => {
          console.log("close");
          setOpen(false);
        }}
      />
      <CardHeader>
        <h3
          style={{ textAlign: "center", fontfamily: "Roboto" }}
          onClick={() => {}}
        >
          Tags Manager
        </h3>
      </CardHeader>
      <CardBody>
        <div
          style={{
            minHeight: "400px",
            height: "40vh",
            width: "100%",
            marginBottom: "",
          }}
        >
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={6}
                density="compact"
                checkboxSelection
                disableClickEventBubbling={false}
                onSelectionChange={(newSelection) => {
                  console.log(newSelection.rowIds);
                }}
                showToolbar
                autoHeight
              />
            </div>
          </div>
        </div>
        <div className="ButtonContainer ">
          <Button
            onClick={(e) => {
              setOpen(true);
            }}
            className="Button"
          >
            Add a new Tag
          </Button>
          <Button disabled className="Button">
            Delete Selected Tags
          </Button>
        </div>
      </CardBody>
      <CardFooter>Card footer</CardFooter>
    </Card>
  );
}

const AddNewTagPopup = (props) => {
  const { data, open, onClose, onSubmit } = props;

  const [error, SetError] = React.useState(true);
  const [errorText, SetErrorText] = React.useState("");
  const [tagName, SetTagName] = React.useState("");

  function ValidateComplaintInput(name) {
    if (name.length < 1) {
      SetError(true);
      SetErrorText("Tag Name cannot be emtpy");
    } else {
      // console.log(rows);
      var match = _.find(data, ["name", name.trim()]);
      if (match) {
        SetError(true);
        SetErrorText("Tag already exists");
      } else {
        SetError(false);
        SetErrorText("");
      }
    }
  }

  return (
    <Dialog
      scroll="body"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"Add a new Tag"}</DialogTitle>
      <DialogContent>
        <DialogActions>
          <TextField
            value={tagName}
            variant="outlined"
            onChange={(e) => {
              ValidateComplaintInput(e.target.value);
              SetTagName(e.target.value);
            }}
            error={error}
            autoFocus
            helperText={error ? errorText : ""}
            margin="dense"
            id="Complaint"
            type="text"
            fullWidth
            label="Tag Name"
            inputProps={{ maxLength: 400 }}
          />
          <br />
          <br />
          <br />
        </DialogActions>
        <Button
          disabled={error}
          style={{ margin: "1em" }}
          onClick={() => {
            SetError(true);
            SetErrorText("Tag Name cannot be emtpy");
            SetTagName("");
            onSubmit(tagName);
          }}
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </DialogContent>
    </Dialog>
  );
};
