import React from "react";
import { Redirect, Route } from "react-router-dom";
import { UserContext } from "./userContext";
import { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { isEqual } from "lodash";
import { getLoggedInUser } from "./APIManager";
import NotAuthorizedPage from "./Pages/NotAuthorizedPage/NotAuthorizedPage";

export const ProtectedRoute = ({ Component: Component, ...rest }) => {
  const Location = useLocation();
  const [user, setUser] = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        ReCheckAuth();
        if (user.isloggedin) {
          console.log("Logged in");
          if (IsAllowedModule()) {
            return <Component {...props} />;
          } else {
            return <NotAuthorizedPage />;
          }
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );

  function ReCheckAuth() {
    getLoggedInUser().then((res) => {
      if (!isEqual(user, res.data)) {
        setUser(res.data);
      }
    });
  }

  function IsAllowedModule() {
    if (user.admin.pagepermissions?.includes("All")) {
      return true;
    }

    var currentPath = Location.pathname.toLocaleLowerCase();

    if (currentPath == "/aq") {
      return HasPerm("Add Question");
    }
    if (currentPath.includes("/eq/")) {
      return HasPerm("Edit Question");
    }
    if (currentPath == "/addnewdeck") {
      return HasPerm("Add Deck");
    }
    if (currentPath == "/tags") {
      return HasPerm("Tags");
    }
    if (currentPath == "/deckmanager") {
      return HasPerm("Deck Manager");
    }
    if (currentPath == "/couponmanager") {
      return HasPerm("Coupon Manager");
    }
    if (currentPath.includes("/deckeditor/")) {
      return HasPerm("Deck Editor");
    }
    if (currentPath.includes("/editdeckinfo/")) {
      return HasPerm("Edit Deck Info");
    }
    if (currentPath.includes("/editgroupinfo")) {
      return HasPerm("Edit Group Info");
    }
    if (currentPath == "/addnewgroup") {
      return HasPerm("Add new group");
    }
    if (currentPath == "/groupmanager") {
      return HasPerm("Group Manager");
    }
    if (currentPath == "/verifyorder/") {
      return HasPerm("Verify Order");
    }
    if (currentPath == "/ordermanager") {
      return HasPerm("Order Manager");
    }
    if (currentPath == "/ratingsmanager") {
      return HasPerm("Ratings Manager");
    }
    if (currentPath == "/couponeditor") {
      return HasPerm("CouponEditor");
    }
    if (currentPath == "/adminusers") {
      return HasPerm("Admin Users");
    }

    return false;
  }
  function HasPerm(perm) {
    return user.admin.pagepermissions?.includes(perm);
  }
};
