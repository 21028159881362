import React, { Component, useState, useEffect, useContext } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Switch } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import LinkIcon from "@material-ui/icons/Link";
import { GetDecksInfo, SetDeckIsPublished } from "../../APIManager";
import "./DeckManager.css";
import mapKeys from "lodash/mapKeys";
import { UserContext } from "../../userContext";
import {
  BrowserRouter as Router,
  useParams,
  useHistory,
} from "react-router-dom";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  FormTextarea,
  FormCheckbox,
  Fade,
  FormRadio,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
} from "shards-react";

function OpenDeckInNewTab(name, type) {
  if (type === "Topical") {
    window.open("https://premed.pk/deck/topical/attempt?deck=" + name);
  } else {
    window.open("https://premed.pk/deck/attempt?deck=" + name);
  }
}

export default function DeckManager() {
  let history = useHistory();
  const [user, setUser] = useContext(UserContext);

  function GetDeckEditAllowed(deckName) {
    if (user?.admin?.pagepermissions?.includes("All")) {
      return true;
    } else if (
      user?.admin?.deckeditpermissions?.includes("All") ||
      user?.admin?.deckeditpermissions?.includes(deckName)
    ) {
      return true;
    } else {
      return false;
    }
  }

  const [rows, SetRows] = useState([]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
      flex: 0.5,
      minWidth: "24ch",
      type: "number",
      headerAlign: "left",
    },
    { field: "Name", headerName: "Name", flex: 0.7, headerAlign: "left" },
    { field: "Group", headerName: "Group", flex: 0.4, headerAlign: "left" },
    { field: "Type", headerName: "Type", flex: 0.4, headerAlign: "left" },
    {
      field: "",
      type: "text",
      headerName: " ",
      flex: 0.3,
      headerAlign: "left",
      renderCell: (params) => (
        <div>
          <Tooltip title="Edit Deck">
            <IconButton
              disabled={!GetDeckEditAllowed(params.row.Name)}
              onClick={() => {
                history.push("/deckeditor/" + params.row.Name);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Open Deck in new Tab">
            <IconButton
              onClick={() => {
                OpenDeckInNewTab(
                  String(params.row.Name),
                  String(params.row.Type)
                );
              }}
            >
              <LinkIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "Published",
      headerName: "Published",
      flex: 0.5,
      headerAlign: "left",
      renderCell: (params) => (
        <div>
          <Switch
            disabled={!GetDeckEditAllowed(params.row.Name)}
            checked={params.value}
            onClick={(e) => {
              SetDeckIsPublished(params.row.Name, !e.target.checked).then(
                (res) => {
                  FetchDecksInfo();
                }
              );
            }}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          {String(params.value)}
        </div>
      ),
    },
    {
      field: "NumberOfQuestions",
      type: "number",
      headerName: "Questions",
      flex: 0.5,
      headerAlign: "right",
    },
    {
      field: "PublishedQuestions",
      type: "number",
      headerName: "Published Questions",
      flex: 0.5,
      headerAlign: "right",
    },
    {
      field: "UnPublishedQuestions",
      type: "number",
      headerName: "Un-Published Questions",
      flex: 0.5,
      headerAlign: "right",
    },
  ];
  function FetchDecksInfo() {
    GetDecksInfo().then((res) => {
      const raw = res.data;
      var decks = [];
      raw.map((item) => {
        decks.push(
          mapKeys(item, (value, key) => {
            let newKey = key;
            if (key === "_id") {
              newKey = "id";
            }
            return newKey;
          })
        );
      });

      SetRows(decks);
    });
  }

  useEffect(() => {
    FetchDecksInfo();
  }, []);

  return (
    <Card style={{ minWidth: "1350px" }}>
      <CardHeader>
        <h3
          style={{ textAlign: "center", fontfamily: "Roboto" }}
          onClick={() => {}}
        >
          Deck Manager
        </h3>
      </CardHeader>
      <CardBody style={{ minWidth: "1300px" }}>
        <div
          style={{
            minHeight: "400px",
            height: "4000px",
            width: "100%",
            minWidth: "1300px",
            marginBottom: "",
          }}
        >
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                onRowHover={(e) => {}}
                rows={rows}
                columns={columns}
                pageSize={100}
                density="compact"
                //checkboxSelection
                // selectionMode
                disableClickEventBubbling={false}
                onSelectionChange={(newSelection) => {}}
                showToolbar
                autoHeight
              />
            </div>
          </div>
        </div>
        <div className="ButtonContainer "></div>
      </CardBody>
    </Card>
  );
}
