import {Snackbar } from "@material-ui/core";

export function SimpleSnackbar({ open, handleClose, action, message }) {
    return (
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={action}
      />
    );
  }