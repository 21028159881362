import "shards-ui/dist/css/shards.min.css";
import "./AddQuestion.css";
import axios from "axios";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import Al from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import React, { Component, useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  FormTextarea,
  FormCheckbox,
  Fade,
  FormRadio,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
} from "shards-react";
import { Form, FormInput, FormGroup } from "shards-react";
import {
  SubmitEditDeckInfo,
  GetGroupNames,
  GetDeckInfo,
} from "../../APIManager";

const EditDeckInfo = () => {
  let history = useHistory();
  let { oldDeckName } = useParams();

  const [groups, SetGroups] = useState([]);

  const [deckName, SetDeckName] = useState("");
  const [deckGroup, SetDeckGroup] = useState();
  const [deckType, SetDeckType] = useState("Yearly");
  const [deckDescription, SetDeckDescription] = useState("");
  const [image, SetImage] = useState("");
  const [publish, setPublish] = useState(false);

  const [showModal, SetShowModal] = useState(false);
  const [showErrorModal, SetShowErrorModal] = useState(false);
  const [isValidationError, setisValidationError] = useState(false);
  const [validationErrorMessages, setValidationErrorMessages] = useState([]);

  const [enableSubmit, SetEnableSubmit] = useState(true);

  var fileInput;

  useEffect(() => {
    if (groups.length < 1) {
      GetGroupNames()
        .then((res) => {
          var rawgroups = res.data;
          var groupNames = [];
          rawgroups.forEach((group) => {
            groupNames.push(group.Name);
          });
          SetGroups([...groupNames]);
        })
        .catch((error) => {
          HandleSubmitError(error);
        });
    }
    GetDeckInfo(oldDeckName).then((res) => {
      console.log(res.data);
      if (res.data.Error) {
        SetShowErrorModal(true);
      } else {
        var deck = res.data[0];
        console.log(deck);
        SetDeckName(deck.Name);
        SetDeckType(deck.Type ? deck.Type : "Yearly");
        SetDeckGroup(deck.Group);
        SetDeckDescription(deck.Description);
        SetImage(deck.Image);
        setPublish(deck.Published);
      }
    });
  }, []);

  function ValidateInputs() {
    var messages = [];
    // Validate Deck Name
    if (showErrorModal === true) {
      messages.push("Unauthorized Action Detected and Reported");
    }
    if (deckName === "" || deckName.length === 0) {
      messages.push("Deck Name cannot be left empty");
    }
    // Validate Deck Description
    if (deckDescription === "" || deckDescription.length === 0) {
      messages.push("Deck Description cannot be left empty");
    }
    /*  if (
      image === undefined ||
      image === null ||
      image === "" ||
      image.length === 0
    ) {
      messages.push("An image for the deck preview must be uploaded");
    } */
    //Return Logic for Function
    if (messages.length === 0) {
      setValidationErrorMessages([]);
      setisValidationError(false);
      return true;
    } else {
      setValidationErrorMessages([...messages]);
      setisValidationError(true);
      scrollToTop();
      return false;
    }
  }

  function OnSubmitClicked() {
    if (ValidateInputs() === true) {
      SetEnableSubmit(false);
      SubmitEditDeckInfo({
        oldName: oldDeckName,
        deckName: deckName,
        deckGroup: deckGroup,
        deckType: deckType,
        deckDescription: deckDescription,
        deckImage: image,
      })
        .then((res) => ResolveResponse(res))
        .catch((error) => {
          HandleSubmitError(error);
        });
    } else {
      scrollToTop();
    }
  }
  function ResolveResponse(res) {
    SetEnableSubmit(true);
    console.log("hello");
    var data = res.data;
    console.log(res);
    if (data.Error) {
      setValidationErrorMessages([...[data.ErrorText]]);
      setisValidationError(true);
      scrollToTop();
    }
    if (data.Status === "Success") {
      console.log("Saved Successfully");
      setisValidationError(false);
      SetShowModal(true);
    }
  }
  function HandleSubmitError(error) {
    if (error.response) {
      var messages = [];
      messages.push("Could not save as server returned an error");
      setisValidationError(true);
      setValidationErrorMessages([...messages]);
      scrollToTop();
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      var messages = [];
      messages.push("Network Error / Cannot reach server");
      setisValidationError(true);
      setValidationErrorMessages([...messages]);
      scrollToTop();
    } else {
      // Something happened in setting up the request that triggered an Error
      var messages = [];
      messages.push("Could not save due to unknown error");
      setisValidationError(true);
      setValidationErrorMessages([...messages]);
      scrollToTop();
    }
  }
  return (
    <div className="flexMe">
      <Modal open={showErrorModal} toggle={() => {}}>
        <ModalHeader>Error</ModalHeader>
        <ModalBody>
          <h4> Could not find Deck to edit</h4>
          It is possible that the deck info was changed such that this link is
          no longer valid. Please go back to the Deck Manager to generate a new
          link
          <br />
          <br />
          <Button>Return to Deck Manager</Button>
        </ModalBody>
      </Modal>

      <Modal open={showModal} toggle={() => {}}>
        <ModalHeader>Success</ModalHeader>
        <ModalBody>
          <h4> Deck Info Successfully Saved</h4> <br />
          <Button
            onClick={() => {
              history.push("/deckeditor/" + deckName);
            }}
          >
            Return to Deck Editor
          </Button>
          <Button
            onClick={() => {
              history.push("/deckmanager/");
            }}
          >
            Return to Deck Manager
          </Button>
        </ModalBody>
      </Modal>

      <Card>
        <CardHeader className="Header">
          <h3 onClick = {()=>{console.log(deckGroup)}} style={{ textAlign: "center", fontfamily: "Roboto" }}>
            Edit Deck Info
          </h3>
        </CardHeader>

        <CardBody className="CardBody">
          <ErrorCard
            isVisible={isValidationError}
            messages={validationErrorMessages}
          />
          <label htmlFor="Deck Name">
            <h5 style={{ marginTop: "1.5rem" }}>Deck Name / Title</h5>
          </label>
          <FieldInput
            id="#Deck Name"
            placeholder="Deck Name"
            onChange={() => {}}
            type="text"
            multiline={false}
            size="sm"
            value={deckName}
            onChange={(e) => {
              SetDeckName(e.target.value);
            }}
            /*  invalid={!this.state.TextFields.FullNameIsValid}
                errorText={this.state.TextFields.FullNameErrorText} */
          />
          <hr />
          <label htmlFor="Deck Description">
            <h5 style={{ marginTop: "0rem" }}>Deck Group</h5>
          </label>
          <FormSelect
            onChange={(e) => {
              SetDeckGroup(e.target.value);
            }}
            value={deckGroup}
          >
            {groups.map((name) => (
              <option value={name}>{name}</option>
            ))}
          </FormSelect>
          <hr />
          <label htmlFor="Deck Type">
            <h5 style={{ marginTop: "0rem" }}>Deck Type</h5>
          </label>

          <FormSelect
            onChange={(e) => {
              SetDeckType(e.target.value);
            }}
            value={deckType}
          >
            <option value={"Yearly"}>Yearly</option>
            <option value={"Topical"}>Topical</option>
          </FormSelect>
          <hr />
          <label htmlFor="Deck Description">
            <h5 style={{ marginTop: "0rem" }}>Deck Description</h5>
          </label>
          <FieldInput
            style={{ height: "20px" }}
            id="#Deck Description"
            placeholder="Deck Description"
            onChange={() => {}}
            type="text"
            multiline={true}
            value={deckDescription}
            onChange={(e) => {
              SetDeckDescription(e.target.value);
            }}
            /*  invalid={!this.state.TextFields.FullNameIsValid}
                errorText={this.state.TextFields.FullNameErrorText} */
          />
          <hr />
          <h5 style={{ marginTop: "0rem" }}>Add image for Deck Preview</h5>
          <input
            style={{ marginTop: ".5rem" }}
            type="file"
            name="Question Image"
            accept=".png,.jpeg,.jpg"
            ref={(ref) => (fileInput = ref)}
            onChange={(e) => {
              console.log(e.target.files[0]);
              if (e.target.files[0] !== undefined) {
                let file = e.target.files[0];
                let reader = new FileReader();
                reader.addEventListener("load", function (e) {
                  let text = e.target.result;
                  console.log(text);
                  SetImage(text);
                });
                reader.readAsDataURL(file);
              } else {
                SetImage("");
              }
            }}
          />
          <ImagePreview
            image={image}
            onDelete={() => {
              fileInput.value = "";
              SetImage("");
            }}
          />
          <hr />
          <div class="form-row text-center">
            <div class="col-12">
              <div className="centerMe">
                <Button
                  disabled={!enableSubmit}
                  style={{ marginTop: "2rem" }}
                  pill
                  theme="success"
                  onClick={() => {
                    OnSubmitClicked();
                  }}
                >
                  Save Edits
                </Button>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

function FieldInput(props) {
  const id = props.id;
  const placeholder = props.placeholder;
  const type = props.type;
  const errorText = props.errorText;
  const invalid = props.invalid;
  const valid = props.valid;
  const onChange = props.onChange;
  const shake = props.shake;
  const onAnimationEnd = props.onAnimationEnd;

  function handleChange(e) {
    onChange(e);
  }

  var shakeStyle;
  if (shake == true) {
    shakeStyle = {
      animation: "shake .5s",
    };
  } else {
    shakeStyle = {
      animation: "",
    };
  }
  if (props.multiline === true) {
    return (
      <div>
        <FormTextarea
          id={props.id}
          placeholder={props.placeholder}
          type={props.type}
          value={props.value}
          onChange={handleChange}
          invalid={invalid}
          valid={valid}
          style={shakeStyle}
          onAnimationEnd={onAnimationEnd}
          style={{ height: "100px" }}
        />
        <span style={{ color: "red" }}>{props.errorText}</span>
      </div>
    );
  }
  return (
    <div>
      <FormInput
        id={props.id}
        placeholder={props.placeholder}
        type={props.type}
        value={props.value}
        onChange={handleChange}
        invalid={invalid}
        valid={valid}
        style={shakeStyle}
        onAnimationEnd={onAnimationEnd}
      />
      <span style={{ color: "red" }}>{props.errorText}</span>
    </div>
  );
}
function ImagePreview(props) {
  var { image, onDelete } = props;
  if (image !== undefined && image !== null && image.length > 0) {
    return (
      <div>
        <img
          src={image}
          width="350px"
          style={{ marginTop: "10px", marginRight: "10px" }}
        />
        <Button
          id={"delete image"}
          theme="danger"
          size="sm"
          onClick={(e) => {
            onDelete();
          }}
          style={{ marginTop: "1rem", marginBottom: "1.5rem" }}
        >
          Remove Image
        </Button>
      </div>
    );
  } else {
    return <div />;
  }
}
function ErrorCard(props) {
  if (props.isVisible) {
    return (
      <Al onClose={props.onClose} severity="error">
        <AlertTitle>Error</AlertTitle>
        {props.messages.map((message) => {
          return <div>{message}</div>;
        })}
      </Al>
    );
  } else {
    return <div></div>;
  }
}
function scrollToTop() {
  var position = document.body.scrollTop || document.documentElement.scrollTop;
  if (position) {
    window.scrollBy(0, -Math.max(1, Math.floor(position / 10)));
    var scrollAnimation = setTimeout(() => {
      scrollToTop();
    }, 10);
  } else clearTimeout(scrollAnimation);
}

export default EditDeckInfo;
