import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import { Autocomplete, Stack } from "@mui/material";
import {
  addHeadApi,
  getRepresentativesApi,
} from "../../APIs/CouponAPI";
import { IconButton, MenuItem, Select, Snackbar } from "@material-ui/core";
import { CloseIcon } from "@material-ui/data-grid";
import MyTextField from "./MyTextField";
import MyNumberField from "./MyNumberField";
import { SimpleSnackbar } from "./SimpleSnackBarComponent";

export default function AddHeadPopup() {
  const [managers, setManagers] = useState([]);

  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    status: "success",
  })

  async function fetchManagers() {
    const response = await getRepresentativesApi("Campus Ambassador Manager");
    const managersList = response.data;
    console.log(
      "🚀 ~ file: AddHeadPopup.jsx:24 ~ fetchManagers ~ response:",
      managersList
    );
    setManagers(managersList ?? []);
  }

  useEffect(() => {
    fetchManagers();
  }, []);

  const [open, setOpen] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      headName: "",
      headEmail: "",
      commissionRate: 10,
      managerEmail: "",
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
    validate: (values) => {
      const errors = {};

      if (!values.headName) {
        errors.headName = "Required";
      } else if (isValidName(values.headName)) {
        errors.headName = "Invalid name";
      }

      if (!values.headEmail) {
        errors.headEmail = "Required";
      } else if (isValidEmail(values.headEmail)) {
        errors.headEmail = "Invalid email address";
      }

      if (!values.commissionRate) {
        errors.commissionRate = "Required";
      }

      return errors;
    },
  });

  const handleClickOpen = async () =>  {
    await fetchManagers();
    setOpen(true);
  };

  function isValidEmail(email) {
    return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }

  async function handleSubmit() {
    console.log(
      "🚀 ~ file: AddHeadPopup.jsx:74 ~ handleSubmit ~ formik.values.managerEmail:",
      formik.values.managerEmail
    );
    try{
    await addHeadApi({
      headName: formik.values.headName,
      headEmail: formik.values.headEmail,
      managerEmail: formik.values.managerEmail,
      commissionRate: formik.values.commissionRate / 100,
    });
    setSnackbar(prev => ({
      ...prev,
      status: "success",
    }))
  } catch (error) {
    setSnackbar(prev => ({
      ...prev,
      status: "error",
    }))
  }
  setSnackbar((prev) => ({
    ...prev,
    isOpen: true,
    }))

    formik.resetForm();

    setOpen(false);
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>

<SimpleSnackbar
        open={snackbar.isOpen}
        handleClose={() => setSnackbar(prev => ({ ...prev, isOpen: false }))}
        message={snackbar.status === "success" ? "Head Added Successfully" : "An Error Occurred"}
      />
      <Button onClick={handleClickOpen}>Add Head</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Head</DialogTitle>
        <DialogContent>
          <br />
          <Stack spacing={3} width="30em">
            <MyTextField formik={formik} name="headName" label={"Head Name"} />
            <MyTextField
              formik={formik}
              name="headEmail"
              label={"Head Email"}
            />
            <MyNumberField
              formik={formik}
              name="commissionRate"
              label={"Commission Rate (in Percentage)"}
            />
            <Autocomplete
              disablePortal
              onChange={(event, value) => {
                formik.setFieldValue("managerEmail", value?.split(" - ")[1].trim());
              }}
              // name
              options={
                managers?.map(
                  (manager) => `${manager.fullname} -  ${manager.username}`
                ) ?? []
              }
              renderInput={(params) => (
                <TextField
                  name="managerEmail"
                  {...params}
                  label="Managers"
                  variant="standard"
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={!formik.isValid} onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function isValidName(values) {
  return !/^[a-zA-Z ]{2,30}$/i.test(values.managerName);
}
