import React, { Component, useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { GetRatings, ClearRatings } from "../../APIManager";
//import "./TagsEditor.css";
import _ from "lodash";

import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  FormTextarea,
  FormCheckbox,
  Fade,
  FormRadio,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
  FieldInput,
  Button,
} from "shards-react";
import Snackbar from "@material-ui/core/Snackbar";
import mapKeys from "lodash/mapKeys";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import LinkIcon from "@material-ui/icons/Link";
import ClearIcon from "@material-ui/icons/Clear";

export default function RatingsManager() {
  const [rows, SetRows] = useState([]);
  const [selectedID, SetSelectedID] = useState("");

  const columns = [
    { field: "id", headerName: "QuestionID", flex: 1 },
    { field: "DeckName", headerName: "Deck Name", flex: 1 },
    { field: "AverageRating", headerName: "Average", flex: 1, type: "number" },
    {
      field: "NumberOfRatings",
      headerName: "# of Ratings",
      flex: 1,
      type: "number",
    },
    {
      field: "",
      headerName: "Tools",
      flex: 0.5,
      headerAlign: "left",
      renderCell: (params) => (
        <div>
          <Tooltip title="Edit Question">
            <IconButton
              size="small"
              onClick={() => {
                window.open(
                  "/eq/" + params.row.DeckName + "/" + params.row.id,
                  "_blank"
                );
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Clear Ratings">
            <IconButton
              size="small"
              onClick={() => {
                ClearRatings(params.row.id).then((res) => {
                  RetrieveRatings();
                });
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  function RetrieveRatings() {
    GetRatings().then((res) => {
      var raw = res.data;
      var coupons = [];
      raw.map((item) => {
        coupons.push(
          mapKeys(item, (value, key) => {
            let newKey = key;
            if (key === "QuestionID") {
              newKey = "id";
            }
            return newKey;
          })
        );
      });
      SetRows(coupons.filter((r) => r.NumberOfRatings > 4).sort((a,b)=> b.NumberOfRatings - a.NumberOfRatings));
    });
  }

  useEffect(() => {
    RetrieveRatings();
  }, []);

  var data = [];

  return (
    <Card>
      <CardHeader>
        <h3
          style={{ textAlign: "center", fontfamily: "Roboto" }}
          onClick={() => {}}
        >
          Ratings Manager
        </h3>
      </CardHeader>
      <CardBody>
        <div
          style={{
            minHeight: "400px",
            height: "4000px",
            width: "100%",
            marginBottom: "",
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={200}
            density="compact"
            // checkboxSelection
            disableClickEventBubbling={false}
            onSelectionChange={(newSelection) => {
              //console.log(newSelection)
              console.log(newSelection.rowIds[0]);
              SetSelectedID(newSelection.rowIds[0]);
            }}
            showToolbar
            autoHeight
          />
        </div>
      </CardBody>
    </Card>
  );
}
