import { QuestionAnswerTwoTone } from "@material-ui/icons";
import axios from "axios";
import { backendUrl } from "./Constants/appConstants";
const DEVLOPMENT_MODE = true;

var rootPath = "";
if (DEVLOPMENT_MODE === true) {
  rootPath = backendUrl;
} else {
  rootPath = "https://adminapi.premed.pk";
}

export const AddNewGroupURL = rootPath + "/AddNewGroup";

export function getLoggedInUser() {
  console.log("Hello");
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/LoggedInUser",
  });
  return data;
}

export function Login(props) {
  console.log("Hello");
  var data = axios({
    method: "POST",
    data: {
      username: props.username,
      password: props.password,
    },
    withCredentials: true,
    url: rootPath + "/login",
  });
  return data;
}
export function SubmitQuestion(deck, question) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/AddNewQuestion",
    data: { deckName: deck, question: question },
  });
  return data;
}

export function SubmitEditQuestion(deck, questionID, question) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/EditQuestion",
    data: { deckName: deck, questionID: questionID, question: question },
  });
  return data;
}

export function SubmitNewDeck(deck) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/AddNewDeck",
    data: {
      deckName: deck.deckName,
      deckGroup: deck.deckGroup,
      deckType: deck.deckType,
      deckDescription: deck.deckDescription,
      deckImage: deck.deckImage,
    },
  });
  return data;
}

export function SubmitEditDeckInfo(deck) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/EditDeckInfo",
    data: {
      oldName: deck.oldName,
      deckName: deck.deckName,
      deckType: deck.deckType,
      deckGroup: deck.deckGroup,
      deckDescription: deck.deckDescription,
      deckImage: deck.deckImage,
    },
  });
  return data;
}

export function SubmitEditDeckGroupInfo(group) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/EditGroupInfo",
    data: {
      group: {
        Name: group.groupName,
        Description: group.groupDescription,
        Image: group.groupImage,
      },
    },
  });
  return data;
}

export function SubmitNewDeckGroup(group) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/AddNewDeckGroup",
    data: {
      group: {
        Name: group.groupName,
        Description: group.groupDescription,
        Image: group.groupImage,
      },
    },
  });
  return data;
}

export function GetDeckNames() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/DeckNames",
  });
  return data;
}

export function GetGroupNames() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/GroupNames",
  });
  return data;
}

export function GetTags() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/Tags",
  });
  return data;
}

export function GetDeckGroups() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/DeckGroups",
  });
  return data;
}

export function GetDecksInfo() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/getdecksinfo",
  });
  return data;
}

export function GetDeckInfo(deckName) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/getdeckinfo/" + deckName,
  });
  return data;
}

export function GetGroupInfo(groupName) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/getgroupinfo/" + groupName,
  });
  return data;
}

export function GetQuestionsInfo(deckName) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/getquestionsinfo/" + deckName,
  });
  return data;
}

export function SetDeckIsPublished(deckName, isPublished) {
  console.log(deckName, isPublished);
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url:
      rootPath +
      "/SetDeckIsPublished?" +
      "deckName=" +
      deckName +
      "&isPublished=" +
      isPublished,
  });
  return data;
}

export function SetQuestionIsPublished(deckName, questionID, isPublished) {
  console.log(deckName, questionID, isPublished);
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url:
      rootPath +
      "/SetQuestionIsPublished?" +
      "deckName=" +
      deckName +
      "&questionID=" +
      questionID +
      "&isPublished=" +
      isPublished,
  });
  return data;
}

export function GetQuestion(deckName, questionID) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/GetQuestion?deck=" + deckName + "&question=" + questionID,
  });
  return data;
}

export function AddNewTag(tagName) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/AddNewTag",
    data: {
      tagName: tagName,
    },
  });
  return data;
}

export function DeleteQuestion(deckName, qid) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/DeleteQuestion",
    data: {
      DeckName: deckName,
      QuestionID: qid,
    },
  });
  return data;
}

export function AddNewCoupon(
  CouponCode,
  Amount,
  SingleUse,
  UserSpecific,
  Username,
  Author,
  ForName,
  Category,
  ContactEmail,
  CACommissionPercentage,
  CAHeadCommissionPercentage,
  CAHeadEmail,
  CAManagerCommissionPercentage,
  CAManagerEmail,
  
) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/AddNewCoupon",
    data: {
      CouponCode: CouponCode,
      Amount: Amount,
      SingleUse: SingleUse,
      UserSpecific: UserSpecific,
      Username: Username,
      Author: Author,
      ForName: ForName,
      Category: Category,
      ContactEmail: ContactEmail,
      CACommissionPercentage: CACommissionPercentage,
      CAHeadCommissionPercentage: CAHeadCommissionPercentage,
      CAHeadEmail: CAHeadEmail,
      CAManagerCommissionPercentage: CAManagerCommissionPercentage,
      CAManagerEmail: CAManagerEmail,
    },
  });
  return data;
}

export function UpdateCoupon(
  CouponCode,
  Username,
  ForName,
  Category,
  ContactEmail,
  PaymentDetails
) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/UpdateCoupon",
    data: {
      CouponCode: CouponCode,
      AssignedUserName: Username,
      ForName: ForName,
      Category: Category,
      ContactEmail: ContactEmail,
      PaymentDetails: PaymentDetails
    },
  });
  return data;
}

export function GetCoupons() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/Coupons",
  });
  return data;
}

export function GetSpecificCoupons(CouponCode) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/Coupons/" + CouponCode,
  });
  return data;
}

export function DeleteCoupon(CouponCode) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/DeleteCoupon",
    data: {
      CouponCode: CouponCode,
    },
  });
  return data;
}

export function GetQuestionNo(DeckName, QID) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/GetQuestionNo/${DeckName}/${QID}`,
  });
  return data;
}
export function GetQuestionIDs(deckName) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/GetQuestionIDs?deck=" + deckName,
  });
  return data;
}

export function SubmitNewMock(MockName, MockIDs) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/AddNewMock",
    data: {
      MockName: MockName,
      MockIDs: MockIDs,
    },
  });
  return data;
}

export function GetUsers() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/GetUsers/564748hfg75bf77w636gdgsj",
  });
  return data;
}

export function GetOrders() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/GetOrders/qurhcnch4860938df",
  });
  return data;
}

export function GetOrder(orderid) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/GetOrder/" + orderid,
  });
  return data;
}

export function ChangeOrderStatus(orderID, newStatus) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/ChangeOrderStatus",
    data: {
      orderID: orderID,
      newStatus: newStatus,
    },
  });
  return data;
}

export function GetRatings() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/GetRatings",
    headers: { LolAZ1BAuth: "ghru49fndm9w2948568qopasmxnbvbvkzdh39" },
  });
  return data;
}

export function ClearRatings(QuestionID) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/ClearRatings",
    headers: { LolAZ1BAuth: "ghru49fndm9w2948568qopasmxnbvbvkzdh39" },
    data: {
      QuestionID: QuestionID,
    },
  });
  return data;
}

export function ChangeQuestionDeck(QuestionID, oldDeckName, newDeckName) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/ChangeQuestionDeck",
    headers: { LolAZ1BAuth: "ghru49fndm9w2948568qopasmxnbvbvkzdh39" },
    data: {
      QuestionID: QuestionID,
      oldDeckName: oldDeckName,
      newDeckName: newDeckName,
    },
  });
  return data;
}

export function AddScholarshipCoupons(
  Author,
  ForName,
  ScholarshipCouponCode,
  PromoCouponCode,
  ContactEmail,
  Amount
) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/AddNewScholarshipCoupons",
    headers: { LolAZ1BAuth: "ghru49fndm9w2948568qopasmxnbvbvkzdh39" },
    data: {
      Author,
      ForName,
      ScholarshipCouponCode,
      PromoCouponCode,
      ContactEmail,
      Amount,
    },
  });
  return data;
}

export function GetQuestionReport(reportid) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/GetReport/${reportid}`,
    headers: { LolAZ1BAuth: "ghru49fndm9w2948568qopasmxnbvbvkzdh39" },
  });
  return data;
}

export function ChangeReportStatus(
  ReportID,
  NewStatus,
  DeckName,
  QuestionNo,
  DeckType,
  CustomNotification
) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/ChangeReportStatus",
    headers: { LolAZ1BAuth: "ghru49fndm9w2948568qopasmxnbvbvkzdh39" },
    data: {
      ReportID: ReportID,
      NewStatus: NewStatus,
      DeckName: DeckName,
      QuestionNo: QuestionNo,
      DeckType: DeckType,
      CustomNotification: CustomNotification,
    },
  });
  return data;
}

export function AddPayment(
  CouponCode,
  Amount,
  DateUTC,
  DatePak,
  DoneBy,
  Notes,
  ProofOfPayment
) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/AddPayment",
    headers: { LolAZ1BAuth: "ghru49fndm9w2948568qopasmxnbvbvkzdh39" },
    data: {
      CouponCode,
      Amount,
      DateUTC,
      DatePak,
      DoneBy,
      Notes,
      ProofOfPayment,
    },
  });
  return data;
}

export function GetPayments(couponCode) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/GetPayments/${couponCode}`,
    headers: { LolAZ1BAuth: "ghru49fndm9w2948568qopasmxnbvbvkzdh39" },
  });
  return data;
}

export function PostPaymentRequest(couponCode, couponID) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/GetPayments/${couponCode}`,
    headers: { LolAZ1BAuth: "ghru49fndm9w2948568qopasmxnbvbvkzdh39" },
  });
  return data;
}
