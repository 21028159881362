import React, { Component, useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import {
  GetTags,
  AddNewTag,
  AddNewCoupon,
  GetCoupons,
  DeleteCoupon,
  GetUsers,
} from "../../APIManager";
//import "./TagsEditor.css";
import _ from "lodash";

import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  FormTextarea,
  FormCheckbox,
  Fade,
  FormRadio,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
  FieldInput,
  Button,
} from "shards-react";
import Snackbar from "@material-ui/core/Snackbar";
import mapKeys from "lodash/mapKeys";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
  { field: "username", headerName: "User Name", flex: 1 },
  { field: "fullname", headerName: "Name", flex: 1 },
  { field: "phonenumber", headerName: "Phone Number", flex: 1 },
  { field: "city", headerName: "City", flex: 1 },
  { field: "school", headerName: "School", flex: 1 },
];

export default function UserManager() {
  const [rows, SetRows] = useState([]);
  const [selectedID, SetSelectedID] = useState("");

  function RetrieveCoupons() {
    GetUsers().then((res) => {
      var raw = res.data;
      var coupons = [];
      raw.map((item) => {
        coupons.push(
          mapKeys(item, (value, key) => {
            let newKey = key;
            if (key === "_id") {
              newKey = "id";
            }
            return newKey;
          })
        );
      });
      SetRows(coupons);
    });
  }

  useEffect(() => {
    RetrieveCoupons();
  }, []);

  var data = [];

  const [open, setOpen] = React.useState(false);
  const [deleteOpen, SetDeleteOpen] = React.useState(false);
  const SubmitTag = (tagName) => {
    AddNewTag(tagName).then((res) => {
      GetTags().then((res) => {
        SetRows(res.data);
      });
    });
    setOpen(false);
  };

  return (
    <Card>
      <AddNewCouponPopup
        key={"AddNewCouponPopup" + open}
        open={open}
        data={rows}
        onSubmit={(couponCode, amount, singleUse, userSpecific, username) => {
          setOpen(false);
          AddNewCoupon(
            couponCode.trim(),
            amount,
            singleUse,
            userSpecific,
            username.trim()
          ).then((res) => {
            RetrieveCoupons();
          });
        }}
        onClose={() => {
          console.log("close");
          setOpen(false);
        }}
      />
      <DeleteCouponPopup
        key={"DeleteCouponPopup" + deleteOpen}
        open={deleteOpen}
        onClose={() => {
          SetDeleteOpen(false);
        }}
        selectedID={selectedID}
        rows={rows}
        onSubmit={(couponCode) => {
          SetDeleteOpen(false);
          DeleteCoupon(couponCode).then((res) => {
            RetrieveCoupons();
          });
        }}
      ></DeleteCouponPopup>
      <CardHeader>
        <h3
          style={{ textAlign: "center", fontfamily: "Roboto" }}
          onClick={() => {}}
        >
          User Viewer
        </h3>
      </CardHeader>
      <CardBody>
        <div
          style={{
            minHeight: "400px",
            height: "4000px",
            width: "100%",
            marginBottom: "",
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={200}
            density="compact"
            // checkboxSelection
            disableClickEventBubbling={false}
            onSelectionChange={(newSelection) => {
              //console.log(newSelection)
              console.log(newSelection.rowIds[0]);
              SetSelectedID(newSelection.rowIds[0]);
            }}
            showToolbar
            autoHeight
          />
        </div>
      </CardBody>
    </Card>
  );
}

const AddNewCouponPopup = (props) => {
  const { data, open, onClose, onSubmit } = props;

  const [couponCode, SetCouponCode] = useState("");
  const [username, SetUsername] = useState("");
  const [amount, SetAmount] = useState(0);
  const [singleUse, SetSingleUse] = useState(false);
  const [userSpecific, SetUserSpecific] = useState(false);

  const [couponCodeValid, SetCouponCodeValid] = useState(false);
  const [usernameValid, SetUsernameValid] = useState(true);
  const [amountValid, SetAmountValid] = useState(false);

  const [couponCodeErrorText, SetCouponCodeErrorText] = useState("");
  const [amountErrorText, SetAmountErrorText] = useState("");
  const [usernameErrorText, SetUsernameErrorText] = useState("");

  function OnCouponCodeChange(e) {
    var newValue = e.target.value.toUpperCase();
    SetCouponCode(newValue);
    ValidateCouponCode(newValue.trim());
  }
  function ValidateCouponCode(newCouponCode) {
    if (newCouponCode.length === 0) {
      SetCouponCodeErrorText("Coupon Code can't be empty");
      SetCouponCodeValid(false);
      return false;
    }
    var match = _.find(data, ["CouponCode", newCouponCode]);
    if (match) {
      SetCouponCodeErrorText("Coupon Code already exists");
      SetCouponCodeValid(false);
      return false;
    } else {
      SetCouponCodeErrorText("");
      SetCouponCodeValid(true);
      return true;
    }
  }

  function OnAmountChange(e) {
    var newValue = Math.floor(parseInt(e.target.value)).toFixed(0);
    SetAmount(newValue);
    ValidateAmount(newValue);
  }
  function ValidateAmount(newAmount) {
    if (isNaN(newAmount)) {
      SetAmountErrorText("Amount can't be empty");
      SetAmountValid(false);
      return false;
    }
    if (newAmount > 500) {
      SetAmountErrorText("Discount cant be more than Rs 500");
      SetAmountValid(false);
      return false;
    }
    if (newAmount < 1) {
      SetAmountErrorText("Discount cant be less than Rs 1");
      SetAmountValid(false);
      return false;
    } else {
      SetAmountErrorText("");
      SetAmountValid(true);
    }
  }

  function OnUsernameChange(e) {
    var newValue = e.target.value.toLowerCase();
    SetUsername(newValue);
    ValidateUsername(newValue);
  }
  function ValidateUsername(newUsername) {
    if (newUsername.length === 0) {
      SetUsernameErrorText("Email address can't be empty");
      SetUsernameValid(false);
      return false;
    }
    var regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(newUsername)) {
      SetUsernameErrorText("Enter a valid email address");
      SetUsernameValid(false);
      return false;
    } else {
      SetUsernameErrorText("");
      SetUsernameValid(true);
      return true;
    }
  }

  return (
    <Dialog
      scroll="body"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"sm"}
      fullWidth={"sm"}
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Create new Coupon"}
      </DialogTitle>
      <DialogContent>
        <TextField
          value={couponCode}
          variant="outlined"
          onChange={OnCouponCodeChange}
          error={!couponCodeValid}
          autoFocus
          helperText={!couponCodeValid ? couponCodeErrorText : ""}
          margin="dense"
          id="Complaint"
          type="text"
          fullWidth
          label="Coupon Code"
          inputProps={{ maxLength: 400 }}
        />
        <Button
          onClick={() => {
            var code = Math.floor(Math.random() * 899999999 + 100000000);
            SetCouponCode(code);
            ValidateCouponCode(code);
          }}
        >
          Generate Random Mock Code
        </Button>
        <TextField
          value={amount}
          variant="outlined"
          onChange={OnAmountChange}
          error={!amountValid}
          autoFocus
          helperText={!amountValid ? amountErrorText : ""}
          margin="dense"
          id="Complaint"
          type="number"
          fullWidth
          label="Discount Amount"
          inputProps={{ maxLength: 400 }}
        />
        <TextField
          value={username}
          variant="outlined"
          onChange={OnUsernameChange}
          error={!usernameValid}
          autoFocus
          helperText={!usernameValid ? usernameErrorText : ""}
          margin="dense"
          id="Complaint"
          type="text"
          fullWidth
          label="User Email Address"
          disabled={!userSpecific}
          inputProps={{ maxLength: 400 }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={userSpecific}
              onChange={() => {
                SetUserSpecific(!userSpecific);
                if (!!userSpecific) {
                  SetUsername("");
                  SetUsernameErrorText("");
                  SetUsernameValid(true);
                } else {
                  SetUsernameValid(false);
                  SetSingleUse(true);
                }
              }}
              name="checkedB"
              color="primary"
            />
          }
          label="User Specific"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={userSpecific ? true : singleUse}
              onChange={() => {
                SetSingleUse(!singleUse);
              }}
              name="checkedB"
              color="primary"
            />
          }
          label="Single Use"
        />
        <br />
        <Button
          disabled={!(couponCodeValid & amountValid & usernameValid)}
          style={{ margin: "1em" }}
          onClick={() => {
            onSubmit(couponCode, amount, singleUse, userSpecific, username);
          }}
          variant="contained"
          color="primary"
        >
          Create new coupon code
        </Button>
      </DialogContent>
    </Dialog>
  );
};

const DeleteCouponPopup = (props) => {
  const { selectedID, open, rows, onClose, onSubmit } = props;
  var couponCode = "";
  if (open) {
    couponCode = _.find(rows, ["id", selectedID]).CouponCode;
  }

  return (
    <Dialog
      scroll="body"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"xs"}
      fullWidth={"xs"}
    >
      <DialogTitle id="alert-dialog-slide-title">{"Delete Coupon"}</DialogTitle>
      <DialogContent>
        Do you really want to delete coupon: {couponCode}
        <br />
        <Button
          //disabled={!(couponCodeValid & amountValid & usernameValid)}
          style={{ margin: "1em" }}
          onClick={() => {
            onSubmit(couponCode);
          }}
          variant="contained"
          theme="danger"
        >
          Delete
        </Button>
        <Button
          //disabled={!(couponCodeValid & amountValid & usernameValid)}
          style={{ margin: "1em" }}
          onClick={onClose}
          variant="contained"
          theme="primary"
        >
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
};
