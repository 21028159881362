import { Stack } from '@mui/material';
import React from 'react'
import MyTextField from './MyTextField';
import { Button } from 'shards-react';

export default function CouponInputComponent({formik}) {
  return (
      <Stack direction="row">
        <MyTextField
          formik={formik}
          name="couponCode"
          label={"Coupon Code"}
          onChange={(e) => {
            formik.setFieldValue("couponCode", e.target.value.toUpperCase());
          }}
        />
        <Button
          sx={{ height: "3em", marginLeft: "1em", paddingX: "2em" }}
          variant="contained"
          onClick={() => {
            const code = String(
              Math.floor(Math.random() * 899999999 + 100000000)
            );
            formik.setFieldValue("couponCode", code);
          }}
        >
          AutoGenerate
        </Button>
      </Stack>
    );
  }



