import React, { Component, useState, useEffect } from "react";
import { useHistory, useParams, withRouter } from "react-router";
import { DataGrid } from "@material-ui/data-grid";
import { GetDeckGroups, AddNewGroupURL } from "../../APIManager";
import "./TagsEditor.css";
import mapKeys from "lodash/mapKeys";

import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  FormTextarea,
  FormCheckbox,
  Fade,
  FormRadio,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
} from "shards-react";
const columns = [
  { field: "id", headerName: "ID", flex: 1, type: "text" },
  { field: "Name", headerName: "Name", flex: 1, type: "text" },
  { field: "Description", headerName: "Description", flex: 2 },
];

export default function GroupManager() {
  const [rows, SetRows] = useState([]);
  const history = useHistory();
  useEffect(() => {
    GetDeckGroups().then((res) => {
      var raw = res.data;
      var groups = [];
      raw.map((item) => {
        groups.push(
          mapKeys(item, (value, key) => {
            let newKey = key;
            if (key === "_id") {
              newKey = "id";
            }
            return newKey;
          })
        );
      });
      SetRows(groups);
    });
  }, []);

  return (
    <Card>
      <CardHeader>
        <h3
          style={{ textAlign: "center", fontfamily: "Roboto" }}
          onClick={() => {}}
        >
          Group Manager
        </h3>
      </CardHeader>
      <CardBody>
        <div
          style={{
            minHeight: "400px",
            height: "40vh",
            width: "100%",
            marginBottom: "",
          }}
        >
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={6}
                density="compact"
                checkboxSelection
                disableClickEventBubbling={false}
                onSelectionChange={(newSelection) => {
                  console.log(newSelection.rowIds);
                }}
                showToolbar
                autoHeight
              />
            </div>
          </div>
        </div>
        <div className="ButtonContainer ">
          <Button
            className="Button"
            onClick={() => {
              history.push("/AddNewGroup");
            }}
          >
            Add a new Group
          </Button>
          <Button disabled className="Button">
            Delete Selected Groups
          </Button>
        </div>
      </CardBody>
      <CardFooter>Card footer</CardFooter>
    </Card>
  );
}
