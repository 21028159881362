import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import { Stack } from "@mui/material";
import { addCommissionApi } from "../../APIs/CouponAPI";
import MyTextField from "./MyTextField";
import { SimpleSnackbar } from "./SimpleSnackBarComponent";

export default function EditCommissionRatePopup() {
  const [open, setOpen] = React.useState(false);

  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    status: "success",
  });

  const formik = useFormik({
    initialValues: {
      representativeEmail: "",
      commissionRate: "",
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
    validate: (values) => {
      const errors = {};
      if (!values.representativeEmail) {
        errors.representativeEmail = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          values.representativeEmail
        )
      ) {
        errors.representativeEmail = "Invalid email address";
      }

      if (!values.commissionRate) {
        errors.commissionRate = "Required";
      } else if (values.commissionRate < 0 || values.commissionRate > 100) {
        errors.commissionRate = "Invalid commission rate";
      }

      return errors;
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  function isValidEmail(email) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }

  async function handleSubmit() {
    if (!formik.isValid) {
      return;
    }
    try {
      await addCommissionApi({
        username: formik.values.representativeEmail,
        Commission: formik.values.commissionRate / 100,
      });
      setSnackbar((prev) => ({
        ...prev,
        status: "success",
      }));
    } catch (error) {
      setSnackbar((prev) => ({
        ...prev,
        status: "error",
      }));
    }
    setSnackbar((prev) => ({
      ...prev,
      isOpen: true,
    }));

    formik.resetForm();

    setOpen(false);
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <SimpleSnackbar
        open={snackbar.isOpen}
        handleClose={() => setSnackbar((prev) => ({ ...prev, isOpen: false }))}
        message={
          snackbar.status === "success"
            ? "Commission Updated Successfully"
            : "An Error Occurred"
        }
      />
      <Button onClick={handleClickOpen}>Edit Commission Rate</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Commission Rate</DialogTitle>
        <DialogContent>
          <br />
          <Stack spacing={3} width="30em">
            <MyTextField
              formik={formik}
              name="representativeEmail"
              label={"Representative Email"}
            />
            <MyTextField
              formik={formik}
              name="commissionRate"
              label={"Commission Rate in Percentage"}
              type="number"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={!formik.isValid} onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
