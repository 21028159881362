import "shards-ui/dist/css/shards.min.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useHistory,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

const EditorScreen = (props) => {
  const {
    onValueChange,
    height,
    initialValue,
    editorValue,
    SetEditorValue,
  } = props;

  return (
    <div height={height} class="border border-primary">
      <Editor
        // toolbarHidden
        editorState={editorValue}
        onEditorStateChange={(e) => {
          SetEditorValue(e);

          if (editorValue.getCurrentContent) {
            onValueChange(
              draftToHtml(convertToRaw(e.getCurrentContent())).replace(
                /style="[a-zA-Z0-9:;\.\s\(\)\-\,]*"/gi,
                ""
              )
            );
            /*  console.log(
              draftToHtml(convertToRaw(e.getCurrentContent())).replace(
                /style="[a-zA-Z0-9:;\.\s\(\)\-\,]*"/gi,
                ""
              )
            ); */
          }
        }}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        stripPastedStyles={false}
        toolbar={{
          options: ["inline", "list", "textAlign", "history"],
          inline: { inDropdown: false },
          list: { inDropdown: false },
          link: { inDropdown: false },
        }}
      />
    </div>
  );
};

export default EditorScreen;
