import React, { Component, useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  useParams,
  useHistory,
} from "react-router-dom";
import _ from "lodash";
import { DataGrid } from "@material-ui/data-grid";
import { Switch } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import LinkIcon from "@material-ui/icons/Link";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { UserContext } from "../../userContext";

import NotAuthorizedPage from "../NotAuthorizedPage/NotAuthorizedPage";

import { Badge } from "shards-react";
import {
  SetQuestionIsPublished,
  GetQuestionsInfo,
  GetDeckInfo,
  GetDecksInfo,
  DeleteQuestion,
} from "../../APIManager";
import Parse from "html-react-parser";
import "./DeckEditor.css";
import mapKeys from "lodash/mapKeys";

import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  FormTextarea,
  FormCheckbox,
  Fade,
  FormRadio,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
} from "shards-react";
import { QueueTwoTone } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FetchQuestionsInfo(DeckName, returnCB) {
  GetQuestionsInfo(DeckName).then((res) => {
    var raw = res.data;
    var rawquestions = [];
    var questions = [];
    raw.forEach((n) => {
      rawquestions.push(n.Questions);
    });
    raw = rawquestions;
    rawquestions = [];
    var cn = 0;
    raw.forEach((n) => {
      n.subjects = n.Tags;
      n.QuestionText = n.QuestionText.replaceAll("<p>", "");
      n.QuestionText = n.QuestionText.replaceAll("</p>", "");

      if (n.Published) {
        n.Number = cn + 1;
        cn++;
      }

      rawquestions.push(n);
    });
    rawquestions.map((item) => {
      questions.push(
        mapKeys(item, (value, key) => {
          let newKey = key;
          if (key === "_id") {
            newKey = "id";
          }
          return newKey;
        })
      );
    });
    returnCB(questions);
  });
}

function FetchDeckInfo(DeckName, returnCB) {
  GetDeckInfo(DeckName).then((res) => {
    console.log(res.data);
    returnCB(res.data[0]);
  });
}

function GetSubject(tags) {
  var subject = "";
  tags.forEach((tag) => {
    if (tag.name === "English") {
      subject = "English";
    }
    if (tag.name === "Biology") {
      subject = "Biology";
    }
    if (tag.name === "Chemistry") {
      subject = "Chemistry";
    }
    if (tag.name === "Physics") {
      subject = "Physics";
    }
  });
  return subject;
}

function TagsToString(tags) {
  if (!tags) {
    return "hello";
  }
  var newTags = [];
  tags.forEach((tag) => {
    newTags.push(tag.name);
  });
  newTags = newTags.join(", ");

  return newTags;
}

function RenderPublishedBadge(Published) {
  if (Published === true) {
    return <Badge theme="success">Published</Badge>;
  } else {
    return <Badge theme="danger">Not Published</Badge>;
  }
}

export default function DeckEditor() {
  let { DeckName } = useParams();
  let history = useHistory();

  const [user, setUser] = useContext(UserContext);

  function GetDeckEditAllowed(deckName) {
    if (user?.admin?.pagepermissions?.includes("All")) {
      return true;
    } else if (
      user?.admin?.deckeditpermissions?.includes("All") ||
      user?.admin?.deckeditpermissions?.includes(deckName)
    ) {
      return true;
    } else {
      return false;
    }
  }

  const [deleteOpen, SetDeleteOpen] = useState(false);

  const [rows, SetRows] = useState([]);
  const [Deck, SetDeck] = useState({});

  const [selectedID, SetSeletedID] = useState("");

  function OpenQuestionInNewTab(name, no) {
    if (Deck.Type === "Topical") {
      window.open(
        "https://premed.pk/deck/topical/attempt?deck=" +
          name +
          "&question=" +
          no,
        "_blank"
      );
    } else {
      window.open(
        "https://premed.pk/deck/attempt?deck=" + name + "&question=" + no,
        "_blank"
      );
    }
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
      minWidth: "24ch",
      type: "number",
      headerAlign: "left",
    },
    {
      field: "Number",
      headerName: "Q #",
      flex: 0.3,
      minWidth: "24ch",
      type: "number",
      headerAlign: "right",
    },
    {
      field: "subjects",
      headerName: "Subject",
      flex: 0.5,
      headerAlign: "left",
      renderCell: (params) => <div>{GetSubject(params.value)}</div>,
    },

    {
      field: "Published",
      headerName: "Published",
      flex: 0.5,
      headerAlign: "left",
      renderCell: (params) => (
        <div>
          <Switch
            checked={params.value}
            onClick={(e) => {
              return;
              SetQuestionIsPublished(
                DeckName,
                params.row.id,
                !e.target.checked
              ).then((res) => {
                console.log(res);
                FetchQuestionsInfo(DeckName, (questions) => {
                  SetRows(questions);
                });
              });
            }}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          {String(params.value)}
        </div>
      ),
    },
    {
      field: "QuestionText",
      type: "text",
      headerName: "Question Text",
      flex: 3,
      headerAlign: "left",
      renderCell: (params) => Parse(params.value),
    },
    {
      field: "Tags",
      headerName: "Tags",
      flex: 1,
      headerAlign: "left",
      renderCell: (params) => <div>{TagsToString(params.value)}</div>,
    },
    {
      field: "",
      headerName: "Tools",
      flex: 0.5,
      headerAlign: "left",
      renderCell: (params) => (
        <div>
          <Tooltip title="Edit Deck">
            <IconButton
              size="small"
              onClick={() => {
                window.open("/eq/" + DeckName + "/" + params.row.id, "_blank");
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Open Question in new Tab"
            disabled={!params.row.Published}
          >
            <IconButton
              onClick={() => {
                OpenQuestionInNewTab(DeckName, String(params.row.Number));
              }}
            >
              <LinkIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];
  useEffect(() => {
    FetchQuestionsInfo(DeckName, (questions) => {
      SetRows(questions);
    });
    FetchDeckInfo(DeckName, (Deck) => {
      console.log(Deck);
      SetDeck(Deck);
    });
  }, []);

  if (!GetDeckEditAllowed(DeckName)) {
    return <NotAuthorizedPage />;
  }

  return (
    <div>
      <AddNewTagPopup
        id={selectedID}
        data={rows}
        open={deleteOpen}
        onClose={() => {
          SetDeleteOpen(false);
        }}
        onSubmit={() => {
          DeleteQuestion(Deck.Name, selectedID).then(() => {
            FetchQuestionsInfo(DeckName, (questions) => {
              SetRows(questions);
            });
            FetchDeckInfo(DeckName, (Deck) => {
              SetDeck(Deck);
            });
          });
          SetDeleteOpen(false);
        }}
      />
      <CardHeader>
        <h3
          style={{ textAlign: "center", fontfamily: "Roboto" }}
          onClick={() => {}}
        >
          Deck Editor
        </h3>
      </CardHeader>
      <CardBody>
        <h3>Deck: {DeckName}</h3>
        <h5>Deck Group: {Deck.Group}</h5>
        <h5>Deck Type: {Deck.Type} </h5>
        <h5>Description: {Deck.Description} </h5>
        <h5>{RenderPublishedBadge(Deck.Published)}</h5>
        <Button
          disabled={!Deck.Image}
          style={{ marginBottom: ".5em" }}
          size="sm"
        >
          Show Image
        </Button>
        <br />
        <Button
          style={{ marginBottom: ".5em" }}
          size="sm"
          onClick={() => {
            history.push("/editdeckinfo/" + DeckName);
          }}
        >
          Edit Deck Info
        </Button>
        <br />
        <Button
          disabled={!selectedID}
          style={{ marginBottom: ".5em" }}
          size="sm"
          onClick={() => {
            SetDeleteOpen(true);
          }}
        >
          Delete Selected Question
        </Button>
        {"  ID: "} {selectedID}
      </CardBody>
      <div
        style={{
          minHeight: "400px",
          height: "80vh",
          width: "100%",
          marginBottom: "4em",
          paddingLeft: "2em",
          paddingRight: "2em",
        }}
      >
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              onRowHover={(e) => {
                // console.log(e.row);
              }}
              rows={rows}
              columns={columns}
              pageSize={100}
              rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
              pagination
              density="compact"
              //checkboxSelection
              // selectionMode
              disableClickEventBubbling={false}
              onSelectionChange={(newSelection) => {
                console.log(newSelection.rowIds[0]);
                SetSeletedID(newSelection.rowIds[0]);
              }}
              showToolbar
              autoHeight
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const AddNewTagPopup = (props) => {
  const { id, data, open, onClose, onSubmit } = props;
  var questionText = "";
  if (open) {
    questionText = _.find(data, ["id", id]).QuestionText;
  }

  console.log(props);
  function ValidateComplaintInput(name) {}

  return (
    <Dialog
      scroll="body"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Delete Question"}
      </DialogTitle>
      <DialogContent>
        Are you sure you want to delete this question
        <br />
        {Parse(questionText)}
        <br />
        <Button
          style={{ margin: "1em" }}
          onClick={() => {
            onSubmit();
          }}
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </DialogContent>
    </Dialog>
  );
};
