import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import axios from "axios";

import Page404 from "./Pages/Page404/404";
import LoginForm from "./Pages/LoginPage/LoginForm";
import QuizDeck from "./QuizDeck";
import SignUpForm from "./Pages/SignUpPage/SignUpForm";
import NewSignUpForm from "./Pages/SignUpPage/NewSignUpForm";
import Dashboard from "./Pages/Dashboard/dashboard";
import AddQuestionScreen from "./Pages/Admin/AddQuestionScreen";
import EditQuestionScreen from "./Pages/Admin/EditQuestionScreen";
import AddNewDeck from "./Pages/Admin/AddNewDeck";
import AddNewMock from "./Pages/Admin/AddNewMock";
import AddNewDeckGroup from "./Pages/Groups/AddNewDeckGroup";
import TagEditor from "./Pages/Tags/TagsEditor";
import DeckManager from "./Pages/DeckManager/DeckManager";
import DeckEditor from "./Pages/DeckEditor/DeckEditor";
import EditDeckInfo from "./Pages/DeckEditor/EditDeckInfo";
import Groups from "./Pages/Groups/GroupManager";
import EditGroupInfo from "./Pages/Groups/EditGroupInfo";
import CouponManager from "./Pages/Coupons/CouponManager";
import UserManager from "./Pages/UserManager/UserManager";
import OrderManager from "./Pages/OrderManager/OrderManager";
import VerifyOrder from "./Pages/VerifyOrder/VerifyOrder";
import RatingsManager from "./Pages/RatingsManager/RatingsManager";
import CouponEditor from "./Pages/Coupons/CouponEditor";

import { UserContext } from "./userContext";
import { ProtectedRoute } from "./ProtectedRoute";
import { getLoggedInUser } from "./APIManager";
import Editor from "./EditorTest";
import GroupManager from "./Pages/Groups/GroupManager";
import RepresentativeDashboard from "./Pages/Coupons/RepresentativeDashboard";
import ReimbursementScreen from "./Pages/Coupons/ReimbursementScreen";
import AdminRepresentativeDashboard from "./Pages/Coupons/AdminRepresentativeDashboard";
const App = () => {
  const [user, setUser] = useContext(UserContext);
  const [isResolved, setResolved] = useState(false);
  useEffect(() => {
    getLoggedInUser().then((res) => {
      console.log(res.data);
      setUser(res.data);
      setResolved(true);
    });
  }, []);

  if (isResolved) {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <h1>Something big coming soon!!</h1>
          </Route>
          <Route path="/Login">
            <LoginForm />
          </Route>
          <Route path="/RepresentativeDashboard/:userId">
            <RepresentativeDashboard />{" "}
          </Route>
          <ProtectedRoute path="/AQ" Component={AddQuestionScreen} />
          <ProtectedRoute
            exact
            path="/EQ/:deckName/:id"
            Component={EditQuestionScreen}
          />
          <ProtectedRoute path="/AddNewDeck" Component={AddNewDeck} />
          <ProtectedRoute path="/AddNewMock" Component={AddNewMock} />
          <ProtectedRoute path="/tags" Component={TagEditor} />
          <ProtectedRoute path="/DeckManager" Component={DeckManager} />
          <ProtectedRoute path="/CouponManager" Component={CouponManager} />
          <ProtectedRoute
            path="/AdminRepresentativeDashboard"
            Component={AdminRepresentativeDashboard}
          />
          <ProtectedRoute
            path="/Reimbursement/:userId"
            Component={ReimbursementScreen}
          />
          {/* <ProtectedRoute path="/CouponManager" Component={CouponManager} /> */}
          <ProtectedRoute
            exact
            path="/Deckeditor/:DeckName"
            Component={DeckEditor}
          />
          <ProtectedRoute
            exact
            path="/EditDeckInfo/:oldDeckName"
            Component={EditDeckInfo}
          />
          <ProtectedRoute
            exact
            path="/EditGroupInfo/:name"
            Component={EditGroupInfo}
          />
          <ProtectedRoute
            exact
            path="/AddNewGroup"
            Component={AddNewDeckGroup}
          />
          <ProtectedRoute exact path="/GroupManager" Component={GroupManager} />
          <ProtectedRoute exact path="/UserManager" Component={UserManager} />
          <ProtectedRoute exact path="/OrderManager" Component={OrderManager} />
          <ProtectedRoute
            exact
            path="/VerifyOrder/:orderid"
            Component={VerifyOrder}
          />
          <ProtectedRoute
            exact
            path="/RatingsManager"
            Component={RatingsManager}
          />
          <ProtectedRoute
            path="/CouponEditor/:couponCode"
            Component={CouponEditor}
          />
          <ProtectedRoute path="*">
            <Page404 />
          </ProtectedRoute>
        </Switch>
      </Router>
    );
  } else {
    return <div>loading</div>;
  }
};
export default App;
