import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import { Autocomplete, Stack } from "@mui/material";
import { addCAApi, getRepresentativesApi } from "../../APIs/CouponAPI";
import { CloseIcon } from "@material-ui/data-grid";
import MyTextField from "./MyTextField";
import MyNumberField from "./MyNumberField";
import { SimpleSnackbar } from "./SimpleSnackBarComponent";
import CouponInputComponent from "./CouponInputComponent";

export default function AddCAPopup() {
  const [heads, setHeads] = useState([]);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    status: "success",
  });

  async function fetchHeads() {
    const response = await getRepresentativesApi("Campus Ambassador Head");
    const headsList = response.data;
    setHeads(headsList ?? []);
  }
  useEffect(() => {
    fetchHeads();
  }, []);

  const [open, setOpen] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      couponCode: "",
      discountPercentage: 15,
      CAName: "",
      CAEmail: "",
      commissionRate: 20,
      headEmail: "",
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
    validate: (values) => {
      const errors = {};

      if (!values.couponCode) {
        errors.couponCode = "Required";
      } else if (
        values.couponCode.length > 15 ||
        values.couponCode.length < 5
      ) {
        errors.couponCode = "length must be between 15 and 5";
      }

      if (!values.discountPercentage) {
        errors.discountPercentage = "Required";
      } else if (
        values.discountPercentage < 0 ||
        values.discountPercentage > 100
      ) {
        errors.discountPercentage = "Invalid Discount Percentage";
      }

      if (!values.CAName) {
        errors.CAName = "Required";
      } else if (isValidName(values.CAName)) {
        errors.CAName = "Invalid name";
      }

      if (!values.CAEmail) {
        errors.CAEmail = "Required";
      } else if (isValidEmail(values.CAEmail)) {
        errors.CAEmail = "Invalid email address";
      }

      if (!values.commissionRate) {
        errors.commissionRate = "Required";
      } else if (values.commissionRate < 0 || values.commissionRate > 100) {
        errors.commissionRate = "Invalid Commission Rate";
      }

      return errors;
    },
  });

  const handleClickOpen = async () => {
    await fetchHeads();
    setOpen(true);
  };

  function isValidEmail(email) {
    return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }

  async function handleSubmit() {
    let result;
    try {
      result = await addCAApi({
        couponCode: formik.values.couponCode,
        discountPercentage: formik.values.discountPercentage / 100,
        CAName: formik.values.CAName,
        CAEmail: formik.values.CAEmail,
        commissionRate: formik.values.commissionRate / 100,
        headEmail: formik.values.headEmail,
      });
      setSnackbar((prev) => ({
        ...prev,
        status: "success",
      }));
    } catch (error) {
      setSnackbar((prev) => ({
        ...prev,
        status: "error",
      }));
    }
    setSnackbar((prev) => ({
      ...prev,
      isOpen: true,
    }));

    formik.resetForm();

    setOpen(false);
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <SimpleSnackbar
        open={snackbar.isOpen}
        handleClose={() => setSnackbar((prev) => ({ ...prev, isOpen: false }))}
        message={
          snackbar.status === "success"
            ? "CA Added Successfully"
            : "An Error Occurred"
        }
      />
      <Button onClick={handleClickOpen}>Add New CA</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New CA</DialogTitle>
        <DialogContent>
          <br />
          <Stack spacing={3} width="30em">
            <CouponInputComponent formik={formik} />
            <MyNumberField
              formik={formik}
              name="discountPercentage"
              label={"Discount Percentage(in Percentage)"}
            />
            <MyTextField formik={formik} name="CAName" label={"CA Name"} />
            <MyTextField formik={formik} name="CAEmail" label={"CA Email"} />
            <MyNumberField
              formik={formik}
              name="commissionRate"
              label={"Commission Rate (in Percentage)"}
            />
            <Autocomplete
              disablePortal
              onChange={(event, value) => {
                formik.setFieldValue(
                  "headEmail",
                  value?.split(" - ")[1].trim()
                );
              }}
              options={
                heads?.map((head) => `${head.fullname} -  ${head.username}`) ??
                []
              }
              renderInput={(params) => (
                <TextField
                  name="headEmail"
                  {...params}
                  label="Heads"
                  variant="standard"
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={!formik.isValid} onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function isValidName(values) {
  return !/^[a-zA-Z ]{2,30}$/i.test(values.managerName);
}
