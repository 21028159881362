import React from "react";
import ReactDOM from "react-dom";
import ReactTags from "react-tag-autocomplete";
import "./TagsStyles.css";

export default class Tags extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      suggestions: [
        { id: 1, name: "Biology" },
        { id: 2, name: "Nums" },
        { id: 3, name: "Osmosis" },
        { id: 4, name: "Plants" },
        { id: 5, name: "Animals" },
        { id: 6, name: "Fungi" },
        { id: 7, name: "Biological Molecules" },
      ],
    };

    this.reactTags = React.createRef();
  }

  onDelete(i) {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
  }

  onAddition(tag) {
    const tags = [].concat(this.state.tags, tag);
    this.setState({ tags });
  }

  render() {
    return (
      <ReactTags
        ref={this.reactTags}
        tags={this.props.tags}
        suggestions={this.props.suggestions}
        onDelete={this.props.onDelete}
        onAddition={this.props.onAddition}
      />
    );
  }
}
