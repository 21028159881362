import React, { useState, useContext, useEffect } from "react";
import styles from "./LoginPage.module.css";
import { Button } from "shards-react";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import { UserContext } from "../../userContext";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import loginIcon from "../../Static/PreMedCircleLogo.png";
import { Login, getLoggedInUser } from "../../APIManager";

const LoginPage = () => {
  let [userContext, SetUserContext] = useContext(UserContext);
  //console.log(userContext);
  let history = useHistory();

  useEffect(() => {
    if (userContext.isLoggedIn) {
      history.push("./dashboard");
    }
  }, []);

  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);

  const [showPassword, SetShowPassword] = useState(false);
  const [shakeUsername, SetShakeUsername] = useState(false);
  const [shakePassword, SetShakePassword] = useState(false);

  const [username, SetUsername] = useState("");
  const [password, SetPassword] = useState("");
  const [usernameValid, SetUsernameValid] = useState(true);
  const [passwordValid, SetPasswordValid] = useState(true);
  const [usernameErrorText, SetUsernameErrorText] = useState("");
  const [passwordErrorText, SetPasswordErrorText] = useState("");
  const [serverError, SetServerError] = useState(false);
  const [serverErrorText, SetServerErrorText] = useState("");

  function OnUsernameChange(event) {
    var newUsername = event.target.value.trim();
    SetUsername(newUsername);
    ValidateUsername(newUsername);
  }

  function ValidateUsername(newUsername) {
    if (newUsername.length === 0) {
      SetUsernameErrorText("Username cant be empty");
      SetUsernameValid(false);
      return false;
    } else {
      SetUsernameErrorText("");
      SetUsernameValid(true);
      return true;
    }
  }

  function OnPasswordChange(event) {
    var newPassword = event.target.value.trim();
    SetPassword(newPassword);
    ValidatePassword(newPassword);
  }
  function ValidatePassword(newPassword) {
    if (newPassword.length === 0) {
      SetPasswordErrorText("Password cant be empty");
      SetPasswordValid(false);
      return false;
    } else {
      SetPasswordErrorText("");
      SetPasswordValid(true);
      return true;
    }
  }

  function OnSubmitClicked() {
    var usernamevalid = ValidateUsername(username);
    var passwordvalid = ValidatePassword(password);
    SetShakeUsername(!usernamevalid);
    SetShakePassword(!passwordvalid);
    if (usernamevalid && passwordvalid) {
      SetWaitingOnResponse(true);
      SetServerError(false);
      SetServerErrorText(false);
      console.log("Is is here lol");
      Login({
        username: username.toLowerCase().trim(),
        password: password.trim(),
      })
        .then((res) => {
          SetWaitingOnResponse(false);
          ResolveServerResponse(res);
        })
        .catch(() => {
          SetWaitingOnResponse(false);
          SetServerError(true);
          SetServerErrorText("Network Error. Try Again");
        });
    }
  }

  function ResolveServerResponse(res) {
    var data = res.data;
    if (data.success === true) {
      getLoggedInUser().then((res) => {
        SetUserContext(res.data);
        history.push("/");
      });
    } else {
      SetServerError(true);
      SetServerErrorText(data.status);
    }
  }

  return (
    <div className={styles.MainDiv} id="LoginPageMainDiv">
      <div className={styles.Container}>
        <div className={styles.LoginBox}>
          <img className={styles.IconImage} src={loginIcon} />
          <br />
          <div className={styles.LoginHeading}>Login</div>
          <div
            className={styles.ErrorAlert}
            style={serverError ? { display: "" } : { display: "none" }}
          >
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {serverErrorText}
            </Alert>
          </div>
          <div
            className={styles.InputField}
            style={
              shakeUsername ? { animation: "shake .5s" } : { animation: "" }
            }
            onAnimationEnd={() => {
              SetShakeUsername(false);
            }}
          >
            <TextField
              fullWidth
              id="outlined-basic"
              label="Email"
              error={!usernameValid}
              variant="outlined"
              helperText={usernameErrorText}
              inputProps={{ style: { fontSize: "1.2em" } }} // font size of input text
              InputLabelProps={{
                style: { fontSize: "1.2em", backgroundColor: "white" },
              }} // font size of input label
              onChange={OnUsernameChange}
            />
          </div>
          <div
            className={styles.InputField}
            style={
              shakePassword ? { animation: "shake .5s" } : { animation: "" }
            }
            onAnimationEnd={() => {
              SetShakePassword(false);
            }}
          >
            <TextField
              className={styles.InputField}
              fullWidth
              id="outlined-basic"
              label="Password"
              error={!passwordValid}
              variant="outlined"
              type={showPassword ? "text" : "password"}
              helperText={passwordErrorText}
              onChange={OnPasswordChange}
              inputProps={{
                style: { fontSize: "1.2em" },
              }} // font size of input text
              InputLabelProps={{
                style: { fontSize: "1.2em", backgroundColor: "white" },
              }} // font size of input label
              //FormHelperTextProps={{ style: { fontSize: "1.2em" } }} // font size of input label
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        SetShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={styles.LoginButton}>
            <Button
              block
              size="lg"
              theme="primary"
              disabled={waitingOnResponse}
              onClick={OnSubmitClicked}
            >
              Login
            </Button>
          </div>
          <div disabled style={{ "text-align": "center" }}>
            Forgot password? <Link to="/forgot-password"> Reset Here</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
