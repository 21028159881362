import React, { Component, useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import {
  GetTags,
  AddNewTag,
  AddNewCoupon,
  GetCoupons,
  DeleteCoupon,
  AddScholarshipCoupons,
} from "../../APIManager";

import { useHistory } from "react-router-dom";

//import "./TagsEditor.css";
import _ from "lodash";

import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  FormTextarea,
  FormCheckbox,
  Fade,
  FormRadio,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
  FieldInput,
  Button,
} from "shards-react";
import Snackbar from "@material-ui/core/Snackbar";
import mapKeys from "lodash/mapKeys";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { Category } from "@material-ui/icons";
import CouponToCAPopup from "./CouponToCAPopup";
import AddRepresentativePopup from "./AddRepresentativePopup";
import EditCommissionRatePopup from "./AddCommissionRatePopup";
import AssignReportsToPopup from "./AssignReportsToPopup";
import AddManagerPopup from "./AddManagerPopup";
import AddHeadPopup from "./AddHeadPopup";
import AddCAPopup from "./AddCAPopup";
import AddPromotionalCouponPopup from "./AddPromotionalCouponPopup";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
  { field: "CouponCode", headerName: "Coupon Code", flex: 1.5 },
  { field: "DiscountAmount", headerName: "Amount", flex: 0.7, type: "number" },
  { field: "VerifiedUses", headerName: "Verified", flex: 0.5, type: "number" },
  { field: "Commision", headerName: "Commision", flex: 1, type: "number" },
  { field: "PendingPayment", headerName: "Pending $$", flex: 1 },
  { field: "SingleUse", headerName: "Single Use", flex: 0.5 },
  { field: "Author", headerName: "Author", flex: 1 },
  { field: "ForName", headerName: "For", flex: 1 },
  { field: "Category", headerName: "Category", flex: 1 },
  { field: "ContactEmail", headerName: "Contact", flex: 1 },
];

export default function DataTable() {
  const [rows, SetRows] = useState([]);
  const [selectedID, SetSelectedID] = useState("");

  const history = useHistory();

  function RetrieveCoupons() {
    GetCoupons().then((res) => {
      var raw = res.data;
      var coupons = [];
      raw.map((item) => {
        coupons.push(
          mapKeys(item, (value, key) => {
            let newKey = key;
            if (key === "_id") {
              newKey = "id";
            }
            return newKey;
          })
        );
      });
      SetRows(coupons);
    });
  }

  useEffect(() => {
    RetrieveCoupons();
  }, []);

  var data = [];

  const [ScholarshipPopupOpen, SetScholarshipPopupOpen] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [deleteOpen, SetDeleteOpen] = React.useState(false);
  const SubmitTag = (tagName) => {
    AddNewTag(tagName).then((res) => {
      GetTags().then((res) => {
        SetRows(res.data);
      });
    });
    setOpen(false);
  };

  return (
    <Card>
      <ScholarshipPopup
        key={"AddNewScholarshipCouponPopup" + ScholarshipPopupOpen}
        data={rows}
        open={ScholarshipPopupOpen}
        onClose={() => {
          SetScholarshipPopupOpen(false);
        }}
        onSubmit={({
          Author,
          ForName,
          ScholarshipCouponCode,
          PromoCouponCode,
          ContactEmail,
          Amount,
        }) => {
          SetScholarshipPopupOpen(false);
          AddScholarshipCoupons(
            Author,
            ForName,
            ScholarshipCouponCode,
            PromoCouponCode,
            ContactEmail,
            Amount
          ).then((res) => {
            RetrieveCoupons();
          });
        }}
      />
      <AddNewCouponPopup
        key={"AddNewCouponPopup" + open}
        open={open}
        data={rows}
        onSubmit={(
          couponCode,
          amount,
          singleUse,
          userSpecific,
          username,
          authorname,
          forname,
          category,
          contactEmail
        ) => {
          setOpen(false);
          AddNewCoupon(
            couponCode.trim(),
            amount,
            singleUse,
            userSpecific,
            username.trim(),
            authorname.trim(),
            forname.trim(),
            category,
            contactEmail
          ).then((res) => {
            RetrieveCoupons();
          });
        }}
        onClose={() => {
          console.log("close");
          setOpen(false);
        }}
      />
      <DeleteCouponPopup
        key={"DeleteCouponPopup" + deleteOpen}
        open={deleteOpen}
        onClose={() => {
          SetDeleteOpen(false);
        }}
        selectedID={selectedID}
        rows={rows}
        onSubmit={(couponCode) => {
          SetDeleteOpen(false);
          DeleteCoupon(couponCode).then((res) => {
            RetrieveCoupons();
          });
        }}
      ></DeleteCouponPopup>
      <CardHeader>
        <h3
          style={{ textAlign: "center", fontfamily: "Roboto" }}
          onClick={() => {}}
        >
          Coupons Manager
        </h3>
      </CardHeader>
      <CardBody>
        <div
          style={{
            minHeight: "4000px",
            height: "40vh",
            width: "100%",
            marginBottom: "",
          }}
        >
          {" "}
          <div
            style={{
              position: "sticky",
              top: "0px",
              backgroundColor: "white",
              width: "100%",
              zIndex: 2,
            }}
            className="ButtonContainer "
          >
            {/* <Button
              onClick={(e) => {
                setOpen(true);
              }}
              className="Button"
            >
              Add a new Coupon
            </Button>
            <Button
              onClick={() => {
                SetDeleteOpen(true);
              }}
              disabled={!selectedID}
              className="Button"
            >
              Delete Selected Coupon
            </Button>
            <Button
              onClick={() => {
                history.push(
                  `/CouponEditor/${_.find(rows, ["id", selectedID]).CouponCode}`
                );
              }}
              disabled={!selectedID}
              className="Button"
            >
              Edit Selected Coupon
            </Button>
            <Button
              onClick={() => {
                SetScholarshipPopupOpen(true);
              }}
              className="Button"
            >
              Add Scholarship Coupon
            </Button> */}
            <AddPromotionalCouponPopup/>
            <AddCAPopup/>
            <AddHeadPopup/>
            <AddManagerPopup/>
            <CouponToCAPopup/>
            <EditCommissionRatePopup/>
            {/* <AssignReportsToPopup/> */}
          </div>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={200}
            density="compact"
            // checkboxSelection
            disableClickEventBubbling={false}
            onSelectionChange={(newSelection) => {
              //console.log(newSelection)
              console.log(newSelection.rowIds[0]);
              SetSelectedID(newSelection.rowIds[0]);
            }}
            showToolbar
            autoHeight
            onCellDoubleClick={(params, event) => {
              console.log(params);
              console.log(event);
            }}
          />
        </div>
      </CardBody>
    </Card>
  );
}

const AddNewCouponPopup = (props) => {
  const { data, open, onClose, onSubmit } = props;

  const [couponCode, SetCouponCode] = useState("");
  const [username, SetUsername] = useState("");
  const [amount, SetAmount] = useState(0);
  const [singleUse, SetSingleUse] = useState(false);
  const [userSpecific, SetUserSpecific] = useState(false);
  const [author, SetAuthor] = useState("");
  const [forName, SetForName] = useState("");
  const [category, SetCategory] = useState("");
  const [contactEmail, SetContactEmail] = useState("");

  const [couponCodeValid, SetCouponCodeValid] = useState(false);
  const [usernameValid, SetUsernameValid] = useState(true);
  const [amountValid, SetAmountValid] = useState(false);
  const [authorValid, SetAuthorValid] = useState(false);
  const [forNameValid, SetForNameValid] = useState(false);
  const [categoryValid, SetCategoryValid] = useState(false);
  const [contactEmailValid, SetContactEmailValid] = useState(true);

  const [couponCodeErrorText, SetCouponCodeErrorText] = useState("");
  const [amountErrorText, SetAmountErrorText] = useState("");
  const [usernameErrorText, SetUsernameErrorText] = useState("");
  const [authorErrorText, SetAuthorErrorText] = useState("");
  const [forNameErrorText, SetForNameErrorText] = useState("");
  const [categoryErrorText, SetCategoryErrorText] = useState("");
  const [contactEmailErrorText, SetContactEmailErrorText] = useState("");

  const [contactEmailEnabled, SetContactEmailEnabled] = useState(false);

  useEffect(() => {
    var tSet = new Set([
      "Associate",
      "Influencer",
      "Campus Ambassador",
      "Brand Ambassador",
    ]);
    var enabled = tSet.has(category);

    SetContactEmailEnabled(enabled);
    if (enabled && !contactEmail) {
      SetContactEmailValid(false);
    }
    if (!enabled) {
      SetContactEmail("");
      SetContactEmailErrorText("");
      SetContactEmailValid(true);
    }
  }, [category]);

  function OnCouponCodeChange(e) {
    var newValue = e.target.value.toUpperCase();
    SetCouponCode(newValue);
    ValidateCouponCode(newValue.trim());
  }
  function ValidateCouponCode(newCouponCode) {
    if (newCouponCode.length === 0) {
      SetCouponCodeErrorText("Coupon Code can't be empty");
      SetCouponCodeValid(false);
      return false;
    }
    var match = _.find(data, ["CouponCode", newCouponCode]);
    if (match) {
      SetCouponCodeErrorText("Coupon Code already exists");
      SetCouponCodeValid(false);
      return false;
    } else {
      SetCouponCodeErrorText("");
      SetCouponCodeValid(true);
      return true;
    }
  }

  function OnAmountChange(e) {
    var newValue = Math.floor(parseInt(e.target.value)).toFixed(0);
    SetAmount(newValue);
    ValidateAmount(newValue);
  }
  function ValidateAmount(newAmount) {
    if (isNaN(newAmount)) {
      SetAmountErrorText("Amount can't be empty");
      SetAmountValid(false);
      return false;
    }
    if (newAmount > 2000) {
      SetAmountErrorText("Discount cant be more than Rs 2000");
      SetAmountValid(false);
      return false;
    }
    if (newAmount < 1) {
      SetAmountErrorText("Discount cant be less than Rs 1");
      SetAmountValid(false);
      return false;
    } else {
      SetAmountErrorText("");
      SetAmountValid(true);
    }
  }

  function OnUsernameChange(e) {
    var newValue = e.target.value.toLowerCase();
    SetUsername(newValue);
    ValidateUsername(newValue);
  }
  function ValidateUsername(newUsername) {
    if (newUsername.length === 0) {
      SetUsernameErrorText("Email address can't be empty");
      SetUsernameValid(false);
      return false;
    }
    var regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(newUsername)) {
      SetUsernameErrorText("Enter a valid email address");
      SetUsernameValid(false);
      return false;
    } else {
      SetUsernameErrorText("");
      SetUsernameValid(true);
      return true;
    }
  }
  function OnAuthorChange(e) {
    var newValue = e.target.value.toLowerCase();
    SetAuthor(newValue);
    ValidateAuthor(newValue);
  }
  function ValidateAuthor(newAuthor) {
    if (newAuthor.length === 0) {
      SetAuthorErrorText("Author can't be empty");
      SetAuthorValid(false);
      return false;
    } else {
      SetAuthorValid(true);
      SetAuthorErrorText("");
      return true;
    }
  }

  function OnForNameChange(e) {
    var newValue = e.target.value;
    SetForName(newValue);
    ValidateForName(newValue);
  }
  function ValidateForName(newForName) {
    if (newForName.length === 0) {
      SetForNameErrorText("For Name can't be empty");
      SetForNameValid(false);
      return false;
    } else {
      SetForNameValid(true);
      SetForNameErrorText("");
      return true;
    }
  }

  function OnCategoryChange(e) {
    var newValue = e.target.value;
    SetCategory(newValue);
    ValidateCategory(newValue);
  }
  function ValidateCategory(newCategory) {
    if (newCategory.length === 0) {
      SetCategoryErrorText("Category can't be empty");
      SetCategoryValid(false);
      return false;
    } else {
      SetCategoryValid(true);
      SetCategoryErrorText("");
      return true;
    }
  }

  function OnContactEmailChange(e) {
    var newValue = e.target.value.toLowerCase().trim();
    SetContactEmail(newValue);
    ValidateContactEmail(newValue);
  }
  function ValidateContactEmail(newContactEmail) {
    if (newContactEmail.length === 0) {
      SetContactEmailErrorText("Contact Email address can't be empty");
      SetContactEmailValid(false);
      return false;
    }
    var regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(newContactEmail)) {
      SetContactEmailErrorText("Enter a valid email address");
      SetContactEmailValid(false);
      return false;
    } else {
      SetContactEmailErrorText("");
      SetContactEmailValid(true);
      return true;
    }
  }
  return (
    <>
      <Dialog
        scroll="body"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth={"sm"}
        fullWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Create new Coupon"}
        </DialogTitle>
        <DialogContent>
          <TextField
            value={couponCode}
            variant="outlined"
            onChange={OnCouponCodeChange}
            error={!couponCodeValid}
            autoFocus
            helperText={!couponCodeValid ? couponCodeErrorText : ""}
            margin="dense"
            id="Complaint"
            type="text"
            fullWidth
            label="Coupon Code"
            inputProps={{ maxLength: 400 }}
          />
          <Button
            onClick={() => {
              var code = String(
                Math.floor(Math.random() * 899999999 + 100000000)
              );
              SetCouponCode(code);
              ValidateCouponCode(code);
            }}
          >
            Generate Random Code
          </Button>
          <TextField
            value={amount}
            variant="outlined"
            onChange={OnAmountChange}
            error={!amountValid}
            autoFocus
            helperText={!amountValid ? amountErrorText : ""}
            margin="dense"
            id="Complaint"
            type="number"
            fullWidth
            label="Discount Amount"
            inputProps={{ maxLength: 400 }}
          />
          {/* <TextField
            value={username}
            variant="outlined"
            onChange={OnUsernameChange}
            error={!usernameValid}
            autoFocus
            helperText={!usernameValid ? usernameErrorText : ""}
            margin="dense"
            id="Complaint"
            type="text"
            fullWidth
            label="User Email Address"
            disabled={!userSpecific}
            inputProps={{ maxLength: 400 }}
          /> */}
          {/* <TextField
            value={author}
            variant="outlined"
            onChange={OnAuthorChange}
            error={!authorValid}
            autoFocus
            helperText={!authorValid ? authorErrorText : ""}
            margin="dense"
            id="Complaint"
            type="text"
            fullWidth
            label="Who is making this coupon"
            inputProps={{ maxLength: 400 }}
          /> */}
          {/* <TextField
            value={forName}
            variant="outlined"
            onChange={OnForNameChange}
            error={!forNameValid}
            autoFocus
            helperText={!forNameValid ? forNameErrorText : ""}
            margin="dense"
            id="Complaint"
            type="text"
            fullWidth
            label="Who are you making this coupon for"
            inputProps={{ maxLength: 400 }}
          /> */}
          {/* <br />
          Coupon Category */}
          {/* <Select
            variant="outlined"
            onChange={OnCategoryChange}
            value={category}
            error={!categoryValid}
            autoFocus
            helperText={!categoryValid ? categoryErrorText : ""}
            id="Complaint"
            fullWidth
            label="Who are you making this coupon for"
          > */}
            {/* <MenuItem value={"Associate"}>{"Associate"}</MenuItem>
            <MenuItem value={"Scholarship"}>{"Scholarship"}</MenuItem>
            <MenuItem value={"Influencer"}>{"Influencer"}</MenuItem>
            <MenuItem value={"Partnership"}>{"Partnership"}</MenuItem>
            <MenuItem value={"Campus Ambassador"}>
              {"Campus Ambassador"}
            </MenuItem>
            <MenuItem value={"Brand Ambassador"}>{"Brand Ambassador"}</MenuItem>
            <MenuItem value={"PreMed Team"}>{"PreMed Team"}</MenuItem>
            <MenuItem value={"Demo"}>{"Demo"}</MenuItem>
            <MenuItem value={"2022 Subscription"}>
              {"2022 Subscription"}
            </MenuItem>
            <MenuItem value={"Payment Issue"}>{"Payment Issue"}</MenuItem>
            <MenuItem value={"Testing (Umar)"}>{"Testing (Umar)"}</MenuItem>
          </Select> */}
          {/* <TextField
            value={contactEmail}
            variant="outlined"
            onChange={OnContactEmailChange}
            error={!contactEmailValid}
            autoFocus
            helperText={!contactEmailValid ? contactEmailErrorText : ""}
            margin="dense"
            id="Complaint"
            type="text"
            fullWidth
            label="Contact Email Address"
            disabled={!contactEmailEnabled}
            inputProps={{ maxLength: 400 }}
          /> */}
          <FormControlLabel
            control={
              <Checkbox
                checked={userSpecific}
                onChange={() => {
                  SetUserSpecific(!userSpecific);
                  if (!!userSpecific) {
                    SetUsername("");
                    SetUsernameErrorText("");
                    SetUsernameValid(true);
                  } else {
                    SetUsernameValid(false);
                    SetSingleUse(true);
                  }
                }}
                name="checkedB"
                color="primary"
              />
            }
            label="User Specific"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={userSpecific ? true : singleUse}
                onChange={() => {
                  SetSingleUse(!singleUse);
                }}
                name="checkedB"
                color="primary"
              />
            }
            label="Single Use"
          />
          <br />
          <Button
            disabled={
              !(
                couponCodeValid &
                amountValid &
                usernameValid &
                authorValid &
                forNameValid &
                categoryValid &
                contactEmailValid
              )
            }
            style={{ margin: "1em" }}
            onClick={() => {
              console.log({
                couponCode,
                amount,
                singleUse,
                userSpecific,
                username,
              });
              onSubmit(
                couponCode,
                amount,
                singleUse,
                userSpecific,
                username,
                author,
                forName,
                category,
                contactEmail
              );
            }}
            variant="contained"
            color="primary"
          >
            Create new coupon code
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

const DeleteCouponPopup = (props) => {
  const { selectedID, open, rows, onClose, onSubmit } = props;
  var couponCode = "";
  if (open) {
    couponCode = _.find(rows, ["id", selectedID]).CouponCode;
  }

  return (
    <Dialog
      scroll="body"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"xs"}
      fullWidth={"xs"}
    >
      <DialogTitle id="alert-dialog-slide-title">{"Delete Coupon"}</DialogTitle>
      <DialogContent>
        Do you really want to delete coupon: {couponCode}
        <br />
        <Button
          //disabled={!(couponCodeValid & amountValid & usernameValid)}
          style={{ margin: "1em" }}
          onClick={() => {
            onSubmit(couponCode);
          }}
          variant="contained"
          theme="danger"
        >
          Delete
        </Button>
        <Button
          //disabled={!(couponCodeValid & amountValid & usernameValid)}
          style={{ margin: "1em" }}
          onClick={onClose}
          variant="contained"
          theme="primary"
        >
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
};

const ScholarshipPopup = ({ couponList, onClose, onSubmit, open, data }) => {
  const [couponCodeName, SetCouponCodeName] = useState("");
  const [scholarshipCoupon, SetScholarshipCoupon] = useState("");
  const [promoCoupon, SetPromoCoupon] = useState("");
  const [amount, SetAmount] = useState(0);
  const [author, SetAuthor] = useState("");
  const [forName, SetForName] = useState("");
  const [contactEmail, SetContactEmail] = useState("");

  const [couponCodeNameValid, SetCouponCodeNameValid] = useState(false);
  const [amountValid, SetAmountValid] = useState(false);
  const [authorValid, SetAuthorValid] = useState(false);
  const [forNameValid, SetForNameValid] = useState(false);
  const [contactEmailValid, SetContactEmailValid] = useState(false);

  const [couponCodeNameErrorText, SetCouponCodeNameErrorText] = useState("");
  const [amountErrorText, SetAmountErrorText] = useState("");
  const [authorErrorText, SetAuthorErrorText] = useState("");
  const [forNameErrorText, SetForNameErrorText] = useState("");
  const [contactEmailErrorText, SetContactEmailErrorText] = useState("");

  const [contactEmailEnabled, SetContactEmailEnabled] = useState(false);

  useEffect(() => {
    console.log(couponCodeName);
    var Pcoupon = `${couponCodeName}200`.trim();
    SetPromoCoupon(Pcoupon);
    var code = String(Math.floor(Math.random() * 899999999 + 100000000));
    SetScholarshipCoupon(code);
    ValidateCouponCodeName(code, Pcoupon, couponCodeName);
  }, [couponCodeName, amount]);

  function OnCouponCodeNameChange(e) {
    var newValue = e.target.value.toUpperCase();
    SetCouponCodeName(newValue);
    //ValidateCouponCodeName(scholarshipCoupon, promoCoupon, couponCodeName);
  }
  function ValidateCouponCodeName(c1, c2, couponCodeName) {
    if (c1.length === 0 || c2.length === 0 || couponCodeName.length === 0) {
      SetCouponCodeNameErrorText("Coupon Code can't be empty");
      SetCouponCodeNameValid(false);
      return false;
    }

    console.log({ c1, c2, data });
    var match1 = _.find(data, ["CouponCode", c1]);
    var match2 = _.find(data, ["CouponCode", c2]);
    if (match1 || match2) {
      SetCouponCodeNameErrorText("Coupon Code already exists");
      SetCouponCodeNameValid(false);
      return false;
    } else {
      SetCouponCodeNameErrorText("");
      SetCouponCodeNameValid(true);
      return true;
    }
  }

  function OnAmountChange(e) {
    var newValue = Math.floor(parseInt(e.target.value)).toFixed(0);
    SetAmount(newValue);
    ValidateAmount(newValue);
  }
  function ValidateAmount(newAmount) {
    if (isNaN(newAmount)) {
      SetAmountErrorText("Amount can't be empty");
      SetAmountValid(false);
      return false;
    }
    if (newAmount > 2000) {
      SetAmountErrorText("Discount cant be more than Rs 2000");
      SetAmountValid(false);
      return false;
    }
    if (newAmount < 1) {
      SetAmountErrorText("Discount cant be less than Rs 1");
      SetAmountValid(false);
      return false;
    } else {
      SetAmountErrorText("");
      SetAmountValid(true);
    }
  }

  function OnAuthorChange(e) {
    var newValue = e.target.value.toLowerCase();
    SetAuthor(newValue);
    ValidateAuthor(newValue);
  }
  function ValidateAuthor(newAuthor) {
    if (newAuthor.length === 0) {
      SetAuthorErrorText("Author can't be empty");
      SetAuthorValid(false);
      return false;
    } else {
      SetAuthorValid(true);
      SetAuthorErrorText("");
      return true;
    }
  }

  function OnForNameChange(e) {
    var newValue = e.target.value;
    SetForName(newValue);
    ValidateForName(newValue);
  }
  function ValidateForName(newForName) {
    if (newForName.length === 0) {
      SetForNameErrorText("For Name can't be empty");
      SetForNameValid(false);
      return false;
    } else {
      SetForNameValid(true);
      SetForNameErrorText("");
      return true;
    }
  }

  function OnContactEmailChange(e) {
    var newValue = e.target.value.toLowerCase().trim();
    SetContactEmail(newValue);
    ValidateContactEmail(newValue);
  }
  function ValidateContactEmail(newContactEmail) {
    if (newContactEmail.length === 0) {
      SetContactEmailErrorText("Contact Email address can't be empty");
      SetContactEmailValid(false);
      return false;
    }
    var regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(newContactEmail)) {
      SetContactEmailErrorText("Enter a valid email address");
      SetContactEmailValid(false);
      return false;
    } else {
      SetContactEmailErrorText("");
      SetContactEmailValid(true);
      return true;
    }
  }
  return (
    <Dialog
      scroll="body"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"sm"}
      fullWidth={"sm"}
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Create Scholarship Coupons"}
      </DialogTitle>
      <DialogContent>
        <TextField
          value={amount}
          variant="outlined"
          onChange={OnAmountChange}
          error={!amountValid}
          autoFocus
          helperText={!amountValid ? amountErrorText : ""}
          margin="dense"
          id="Complaint"
          type="number"
          fullWidth
          label="Scholarship Amount"
          inputProps={{ maxLength: 400 }}
        />
        <TextField
          value={couponCodeName}
          variant="outlined"
          onChange={OnCouponCodeNameChange}
          error={!couponCodeNameValid}
          autoFocus
          helperText={
            !couponCodeNameValid
              ? couponCodeNameErrorText
              : "Enter without discount amount"
          }
          margin="dense"
          id="Complaint"
          type="text"
          fullWidth
          label="Coupon Code Name"
          inputProps={{ maxLength: 400 }}
        />
        <br />
        <div>
          Scholarship Code:{" "}
          <strong hidden={!couponCodeName}>{scholarshipCoupon}</strong>
          <br />
          Commision Code:{" "}
          <strong hidden={!couponCodeName}>{promoCoupon}</strong>
        </div>
        <br />
        <TextField
          value={author}
          variant="outlined"
          onChange={OnAuthorChange}
          error={!authorValid}
          autoFocus
          helperText={!authorValid ? authorErrorText : ""}
          margin="dense"
          id="Complaint"
          type="text"
          fullWidth
          label="Who is making this coupon"
          inputProps={{ maxLength: 400 }}
        />
        <TextField
          value={forName}
          variant="outlined"
          onChange={OnForNameChange}
          error={!forNameValid}
          autoFocus
          helperText={!forNameValid ? forNameErrorText : ""}
          margin="dense"
          id="Complaint"
          type="text"
          fullWidth
          label="Student Full Name"
          inputProps={{ maxLength: 400 }}
        />
        <TextField
          value={contactEmail}
          variant="outlined"
          onChange={OnContactEmailChange}
          error={!contactEmailValid}
          autoFocus
          helperText={!contactEmailValid ? contactEmailErrorText : ""}
          margin="dense"
          id="Complaint"
          type="text"
          fullWidth
          label="Contact Email Address"
          inputProps={{ maxLength: 400 }}
        />
        <br />
        <Button
          disabled={
            !(
              amountValid &
              authorValid &
              forNameValid &
              contactEmailValid &
              couponCodeNameValid
            )
          }
          style={{ margin: "1em" }}
          onClick={() => {
            onSubmit({
              Author: author,
              ForName: forName,
              ScholarshipCouponCode: scholarshipCoupon,
              PromoCouponCode: promoCoupon,
              ContactEmail: contactEmail,
              Amount: amount,
            });
          }}
          variant="contained"
          color="primary"
        >
          Create new coupon code
        </Button>
      </DialogContent>
    </Dialog>
  );
};
