import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import "./SignUpForm.css";
import axios from "axios";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Al from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import React, { Component, useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
} from "shards-react";

import { Form, FormInput, FormGroup } from "shards-react";

export default class NewSignUpForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Credentials: {
        FullName: "",
        UserName: " ",
        Password: "",
        ConfirmPassword: "",
        PhoneNumber: "",
      },
      TextFields: {
        FullNameIsValid: true,
        UserNameIsValid: true,
        PasswordIsValid: true,
        ConfirmPasswordIsValid: true,
        PhoneNumberIsValid: true,
        FullNameErrorText: "",
        UserNameErrorText: "",
        PasswordErrorText: "",
        ConfirmPasswordErrorText: "",
        PhoneNumberErrorText: "",
      },
      ShakeFullName: false,
      ShakeEmail: false,
      ShakePassword: false,
      ShakeConfirmPassword: false,
      ShakePhoneNumber: false,
      IsServerError: false,
      ServerErrorText: "",
    };

    this.FullNameTextChanged = this.FullNameTextChanged.bind(this);
    this.EmailAddressTextChanged = this.EmailAddressTextChanged.bind(this);
    this.PasswordTextChanged = this.PasswordTextChanged.bind(this);
    this.ConfirmPasswordTextChanged = this.ConfirmPasswordTextChanged.bind(
      this
    );
    this.PhoneNumberTextChanged = this.PhoneNumberTextChanged.bind(this);

    this.SubmitClicked = this.SubmitClicked.bind(this);
    this.ValidateAllInputs = this.ValidateAllInputs.bind(this);
    this.ShakeInValidInputs = this.ShakeInValidInputs.bind(this);
  }

  FullNameTextChanged(e) {
    this.setState(
      {
        Credentials: {
          ...this.state.Credentials,
          FullName: e.target.value.trim(),
        },
      },
      () => {
        const { IsValid, ErrorText } = this.ValidateFullName(
          this.state.Credentials.FullName.trim()
        );
        this.setState((state) => {
          return {
            TextFields: {
              ...state.TextFields,
              FullNameIsValid: IsValid,
              FullNameErrorText: ErrorText,
            },
          };
        });
      }
    );
  }
  EmailAddressTextChanged(e) {
    this.setState(
      {
        Credentials: {
          ...this.state.Credentials,
          UserName: e.target.value.trim(),
        },
      },
      () => {
        const { IsValid, ErrorText } = this.ValidateEmailAddress(
          this.state.Credentials.UserName.trim()
        );
        this.setState((state) => {
          return {
            TextFields: {
              ...state.TextFields,
              UserNameIsValid: IsValid,
              UserNameErrorText: ErrorText,
            },
          };
        });
      }
    );
  }

  PasswordTextChanged(e) {
    this.setState(
      {
        Credentials: {
          ...this.state.Credentials,
          Password: e.target.value.trim(),
        },
      },
      () => {
        const { IsValid, ErrorText } = this.ValidatePassword(
          this.state.Credentials.Password.trim()
        );
        this.setState(
          (state) => {
            return {
              TextFields: {
                ...state.TextFields,
                PasswordIsValid: IsValid,
                PasswordErrorText: ErrorText,
              },
            };
          },
          () => {
            const { IsValid, ErrorText } = this.ValidateConfirmPassword(
              this.state.Credentials.Password,
              this.state.Credentials.ConfirmPassword
            );
            this.setState((state) => {
              return {
                TextFields: {
                  ...state.TextFields,
                  ConfirmPasswordIsValid: IsValid,
                  ConfirmPasswordErrorText: ErrorText,
                },
              };
            });
          }
        );
      }
    );
  }

  ConfirmPasswordTextChanged(e) {
    this.setState(
      {
        Credentials: {
          ...this.state.Credentials,
          ConfirmPassword: e.target.value.trim(),
        },
      },
      () => {
        const { IsValid, ErrorText } = this.ValidateConfirmPassword(
          this.state.Credentials.Password.trim(),
          this.state.Credentials.ConfirmPassword.trim()
        );
        this.setState((state) => {
          return {
            TextFields: {
              ...state.TextFields,
              ConfirmPasswordIsValid: IsValid,
              ConfirmPasswordErrorText: ErrorText,
            },
          };
        });
      }
    );
  }
  PhoneNumberTextChanged(e) {
    this.setState(
      {
        Credentials: {
          ...this.state.Credentials,
          PhoneNumber: e.target.value,
        },
      },
      () => {
        const { IsValid, ErrorText } = this.ValidatePhoneNumber(
          this.state.Credentials.PhoneNumber
        );
        this.setState((state) => {
          return {
            TextFields: {
              ...state.TextFields,
              PhoneNumberIsValid: IsValid,
              PhoneNumberErrorText: ErrorText,
            },
          };
        });
      }
    );
  }

  ValidateFullName(FullName) {
    if ((FullName === null, FullName.length === 0, FullName === "")) {
      return { IsValid: false, ErrorText: "Full Name can not be emtpy" };
    }

    if (FullName.length > 50) {
      return {
        IsValid: false,
        ErrorText: "Full Name can not be longer than 50 characters",
      };
    }
    var letters = /^[a-zA-Z ]+$/;
    if (!letters.test(FullName)) {
      return {
        IsValid: false,
        ErrorText: "Full Name can only have alphabets",
      };
    }

    return { IsValid: true, ErrorText: "" };
  }

  ValidateEmailAddress(email) {
    if ((email === null, email.length === 0, email === "")) {
      return { IsValid: false, ErrorText: "Email can not be emtpy" };
    }

    if (email.length > 50) {
      return {
        IsValid: false,
        ErrorText: "Email can not be longer than 50 characters",
      };
    }
    var regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    if (!regex.test(email)) {
      return {
        IsValid: false,
        ErrorText: "Enter a valid email",
      };
    }
    return { IsValid: true, ErrorText: "" };
  }
  ValidatePassword(Password) {
    if ((Password === null, Password.length === 0, Password === "")) {
      return { IsValid: false, ErrorText: "Password can not be emtpy" };
    }

    if (Password.length < 8) {
      return {
        IsValid: false,
        ErrorText: "Password can not be less than 8 characters",
      };
    }
    if (Password.length > 50) {
      return {
        IsValid: false,
        ErrorText: "Password can not be longer than 50 characters",
      };
    }
    return { IsValid: true, ErrorText: "" };
  }

  ValidateConfirmPassword(Password, ConfirmPassword) {
    if (Password !== ConfirmPassword) {
      return {
        IsValid: false,
        ErrorText: "Password dont match",
      };
    }
    return { IsValid: true, ErrorText: "" };
  }
  ValidatePhoneNumber(PhoneNumber) {
    if ((PhoneNumber === null, PhoneNumber.length === 0, PhoneNumber === "")) {
      return { IsValid: false, ErrorText: "Phonenumber can not be emtpy" };
    }

    var letters = /^[0][3][0-9]{9}$/;
    if (!letters.test(PhoneNumber)) {
      return {
        IsValid: false,
        ErrorText: "Enter a valid number eg: 03001234567",
      };
    }

    return { IsValid: true, ErrorText: "" };
  }
  SubmitClicked() {
    if (this.ValidateAllInputs()) {
      const data = {
        username: this.state.Credentials.username,
        password: this.state.Credentials.Password,
      };

      axios({
        method: "POST",
        data: {
          username: this.state.Credentials.UserName,
          password: this.state.Credentials.Password,
          fullname: this.state.Credentials.FullName,
          phonenumber: this.state.Credentials.PhoneNumber,
        },
        withCredentials: true,
        url: "http://localhost:4000/signup",
      }).then((res) => this.ResolveServerResponse(res));
    } else {
      this.ShakeInValidInputs();
    }
  }

  ResolveServerResponse(res) {
    console.log(res);
    var data = res.data;
    if (data.Error === true) {
      this.setState({ IsServerError: true, ServerErrorText: data.ErrorText });
    } else {
      this.setState({ IsServerError: false, ServerErrorText: "" });
      this.LogInUser();
    }
  }
  LogInUser() {
    axios({
      method: "POST",
      data: {
        username: this.state.Credentials.UserName,
        password: this.state.Credentials.Password,
      },
      withCredentials: true,
      url: "http://localhost:4000/login",
    }).then((res) => console.log(res));
  }
  ShakeInValidInputs() {
    this.setState((state) => {
      return { ShakeFullName: !state.TextFields.FullNameIsValid };
    });
    this.setState((state) => {
      return { ShakeEmail: !state.TextFields.UserNameIsValid };
    });
    this.setState((state) => {
      return { ShakePassword: !state.TextFields.PasswordIsValid };
    });
    this.setState((state) => {
      return { ShakeConfirmPassword: !state.TextFields.ConfirmPasswordIsValid };
    });
    this.setState((state) => {
      return { ShakePhoneNumber: !state.TextFields.PhoneNumberIsValid };
    });
  }
  ValidateAllInputs() {
    var {
      IsValid: FullNameIsValid,
      ErrorText: FullNameErrorText,
    } = this.ValidateFullName(this.state.Credentials.FullName);
    var {
      IsValid: UserNameIsValid,
      ErrorText: UserNameErrorText,
    } = this.ValidateEmailAddress(this.state.Credentials.UserName);
    var {
      IsValid: PasswordIsValid,
      ErrorText: PasswordErrorText,
    } = this.ValidatePassword(this.state.Credentials.Password);
    var {
      IsValid: ConfirmPasswordIsValid,
      ErrorText: ConfirmPasswordErrorText,
    } = this.ValidateConfirmPassword(
      this.state.Credentials.Password,
      this.state.Credentials.ConfirmPassword
    );
    var {
      IsValid: PhoneNumberIsValid,
      ErrorText: PhoneNumberErrorText,
    } = this.ValidatePhoneNumber(this.state.Credentials.PhoneNumber);
    this.setState((state) => {
      return {
        TextFields: {
          ...state.TextFields,
          FullNameIsValid: FullNameIsValid,
          FullNameErrorText: FullNameErrorText,
          UserNameIsValid: UserNameIsValid,
          UserNameErrorText: UserNameErrorText,
          PasswordIsValid: PasswordIsValid,
          PasswordErrorText: PasswordErrorText,
          ConfirmPasswordIsValid: ConfirmPasswordIsValid,
          ConfirmPasswordErrorText: ConfirmPasswordErrorText,
          PhoneNumberIsValid: PhoneNumberIsValid,
          PhoneNumberErrorText: PhoneNumberErrorText,
        },
      };
    });

    if (
      FullNameIsValid &&
      UserNameIsValid &&
      PasswordIsValid &&
      ConfirmPasswordIsValid &&
      PhoneNumberIsValid
    ) {
      return true;
    } else {
      return false;
    }
  }
  FieldInput(props) {
    const id = props.id;
    const placeholder = props.placeholder;
    const type = props.type;
    const errorText = props.errorText;
    const invalid = props.invalid;
    const valid = props.valid;
    const onChange = props.onChange;
    const shake = props.shake;
    const onAnimationEnd = props.onAnimationEnd;

    var shakeStyle;
    if (shake == true) {
      shakeStyle = {
        animation: "shake .5s",
      };
    } else {
      shakeStyle = {
        animation: "",
      };
    }
    return (
      <div>
        <FormInput
          id={props.id}
          placeholder={props.placeholder}
          type={props.type}
          onChange={onChange}
          invalid={invalid}
          valid={valid}
          style={shakeStyle}
          onAnimationEnd={onAnimationEnd}
        />
        <span style={{ color: "red" }}>{props.errorText}</span>
      </div>
    );
  }

  ErrorCard(props) {
    if (props.isVisible) {
      return (
        <Al onClose={props.onClose} severity="error">
          <AlertTitle>Error</AlertTitle>

          {props.ErrorText}
        </Al>
      );
    } else {
      return <div></div>;
    }
  }

  render() {
    return (
      <div className="LoginBG">
        <div className="flexMe">
          <Card className="LoginCard">
            <CardHeader className="Header">
              <h3 style={{ textAlign: "center", fontfamily: "Roboto" }}>
                Sign up for PreMed.pk
              </h3>
            </CardHeader>
            <CardBody className="CardBody">
              <CardTitle>Please fill out the following form:</CardTitle>
              <this.ErrorCard
                isVisible={this.state.IsServerError}
                ErrorText={this.state.ServerErrorText}
                onClose={() => {
                  this.setState({ IsServerError: false });
                }}
              />
              <Form>
                <FormGroup>
                  <label htmlFor="#Full Name">Full Name</label>
                  <this.FieldInput
                    id="#FfullName"
                    placeholder="Full Name"
                    type="text"
                    onChange={this.FullNameTextChanged}
                    invalid={!this.state.TextFields.FullNameIsValid}
                    errorText={this.state.TextFields.FullNameErrorText}
                    shake={this.state.ShakeFullName}
                    onAnimationEnd={() => {
                      this.setState({ ShakeFullName: false });
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="#emailaddress">Email Address</label>
                  <this.FieldInput
                    id="#emailAddress"
                    placeholder="Email Address"
                    type="text"
                    onChange={this.EmailAddressTextChanged}
                    invalid={!this.state.TextFields.UserNameIsValid}
                    errorText={this.state.TextFields.UserNameErrorText}
                    shake={this.state.ShakeEmail}
                    onAnimationEnd={() => {
                      this.setState({ ShakeEmail: false });
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="#password">Password</label>
                  <this.FieldInput
                    id="#password"
                    placeholder="Password"
                    type="password"
                    onChange={this.PasswordTextChanged}
                    invalid={!this.state.TextFields.PasswordIsValid}
                    errorText={this.state.TextFields.PasswordErrorText}
                    shake={this.state.ShakePassword}
                    onAnimationEnd={() => {
                      this.setState({ ShakePassword: false });
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="#confirmpassword">ConfirmPassword</label>
                  <this.FieldInput
                    id="#confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                    onChange={this.ConfirmPasswordTextChanged}
                    invalid={!this.state.TextFields.ConfirmPasswordIsValid}
                    errorText={this.state.TextFields.ConfirmPasswordErrorText}
                    valid={
                      this.state.Credentials.Password.length !== 0 &&
                      this.state.Credentials.Password ===
                        this.state.Credentials.ConfirmPassword
                    }
                    shake={this.state.ShakeConfirmPassword}
                    onAnimationEnd={() => {
                      this.setState({ ShakeConfirmPassword: false });
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="#phonenumber">Phone Number</label>
                  <this.FieldInput
                    id="#phoneNumber"
                    placeholder="Phone Number"
                    type="text"
                    onChange={this.PhoneNumberTextChanged}
                    invalid={!this.state.TextFields.PhoneNumberIsValid}
                    errorText={this.state.TextFields.PhoneNumberErrorText}
                    shake={this.state.ShakePhoneNumber}
                    onAnimationEnd={() => {
                      this.setState({ ShakePhoneNumber: false });
                    }}
                  />
                </FormGroup>
              </Form>
              <div class="text-center">
                <Button className="centerMe" onClick={this.SubmitClicked}>
                  Register&rarr;
                </Button>
              </div>
            </CardBody>
            <CardFooter>
              <div style={{ "text-align": "center" }}>
                Already have an account? <Link to="/Login"> Login Here</Link>
              </div>
            </CardFooter>
          </Card>
        </div>
      </div>
    );
  }
}
