import "shards-ui/dist/css/shards.min.css";
import "./AddQuestion.css";
import axios from "axios";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useHistory,
} from "react-router-dom";
import Al from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import React, { Component, useState, useEffect, useContext } from "react";
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";
import { UserContext } from "../../userContext";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  FormTextarea,
  FormCheckbox,
  Fade,
  FormRadio,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
} from "shards-react";

import { SubTopicTags } from "../../Constants/topicConstants";

import { Form, FormInput, FormGroup } from "shards-react";
import Tags from "./Tags";
import { GetDeckNames, GetTags, SubmitQuestion } from "../../APIManager";
import Editor from "../../EditorTest";
import { RestorePage } from "@material-ui/icons";
import { AddNewQuestion } from "../../APIs/questionsAPI";
import { ImageInput } from "../Coupons/ReimbursementScreen";

const AddQuestionScreen = () => {
  var questionFileInput;
  var explanationFileInput;
  //State Vairables for Data
  const [deck, SetDeck] = useState("TEST DECK");
  const [currentTags, SetCurrentTags] = useState([]);
  const [questionText, SetQuestionText] = useState("");
  const [questionMathParsing, SetQuestionMathParsing] = useState(false);
  const [questionImage, SetQuestionImage] = useState("");
  const [options, Setoptions] = useState([]);
  const [explainationText, SetExplanationText] = useState("");
  const [answerMathParsing, SetAnswerMathParsing] = useState(false);
  const [explanationImage, SetExplanationImage] = useState("");
  const [referencesText, SetReferencesText] = useState("");
  const [publish, setPublish] = useState(true);

  //State Variables for Freeze Checkboxes

  const [deckNameFreezed, SetDeckNameFreezed] = useState(false);
  const [tagsFreezed, SetTagsFreezed] = useState(false);
  const [questionTextFreezed, SetQuestionTextFreezed] = useState(false);
  const [questionImageFreezed, SetQuestionImageFreezed] = useState(false);
  const [optionsFreezed, SetOptionsFreezed] = useState(false);
  const [explanationTextFreezed, SetExplanationTextFreezed] = useState(false);
  const [explanationImageFreezed, SetExplanationImageFreezed] = useState(false);
  const [publishFreezed, SetPublishFreezed] = useState(false);

  //State variables for Controls
  const [questionEditorValue, SetQuestionEditorValue] = useState("");

  const [optionEditorValues, SetOptionEditorValues] = useState([]);

  const [explanationEditorValue, SetExplanationEditorValue] = useState("");
  const [referencesTextEditorValue, SetReferencesTextEditorValue] =
    useState("");
  const [showModal, SetShowModal] = useState(false);
  const [isControlDisabled, SetIsContolDisabled] = useState(false);
  const [CorrectOption, setCorrectOption] = useState("");
  const [isValidationError, setisValidationError] = useState(false);
  const [validationErrorMessages, setValidationErrorMessages] = useState([]);
  const [suggestions, SetSuggestions] = useState([]);
  const [originalSuggestions, SetOriginalSuggestions] = useState([]);
  const [deckNames, SetDeckNames] = useState([]);
  const [user, setUser] = useContext(UserContext);

  const [optionAImage, SetOptionAImage] = useState("");
  const [optionBImage, SetOptionBImage] = useState("");
  const [optionCImage, SetOptionCImage] = useState("");
  const [optionDImage, SetOptionDImage] = useState("");
  const [optionEImage, SetOptionEImage] = useState("");

  const optionToImage = {
    set: {
      A: SetOptionAImage,
      B: SetOptionBImage,
      C: SetOptionCImage,
      D: SetOptionDImage,
      E: SetOptionEImage,
    },
    get: {
      A: optionAImage,
      B: optionBImage,
      C: optionCImage,
      D: optionDImage,
      E: optionEImage,
    },
  };

  useEffect(() => {
    if (deckNames.length < 1) {
      GetDeckNames()
        .then((res) => {
          var rawdecknames = res.data;
          var deckNames = [];
          rawdecknames.forEach((deck) => {
            deckNames.push(deck.Name);
          });
          SetDeckNames([...deckNames]);
        })
        .catch((error) => {
          HandleSubmitError(error);
        });
    }

    GetTags().then((res) => {
      var newTags = [...res.data];

      var allSubtopicTags = [];
      for (var key in SubTopicTags) {
        allSubtopicTags = [...allSubtopicTags, ...SubTopicTags[key]];
      }
      newTags = newTags.filter((t) => !allSubtopicTags.includes(t.name));
      //SetSuggestions(newTags);
      SetOriginalSuggestions(res.data);
    });
  }, []);

  useEffect(() => {
    if (currentTags.length > 0) {
      var cTags = currentTags.map((t) => t.name);

      var allSubtopicTags = [];
      for (var key in SubTopicTags) {
        allSubtopicTags = [...allSubtopicTags, ...SubTopicTags[key]];
      }

      console.log(allSubtopicTags);
      var nSuggestions = originalSuggestions.filter(
        (t) => !allSubtopicTags.includes(t.name)
      );

      cTags.forEach((tag) => {
        if (SubTopicTags[tag]) {
          nSuggestions = [
            ...nSuggestions,
            ...originalSuggestions.filter((t) =>
              SubTopicTags[tag].includes(t.name)
            ),
          ];
        }
      });
      SetSuggestions(nSuggestions);
      return;
    }
    var newTags = [...originalSuggestions];

    var allSubtopicTags = [];
    for (var key in SubTopicTags) {
      allSubtopicTags = [...allSubtopicTags, ...SubTopicTags[key]];
    }
    newTags = newTags.filter((t) => !allSubtopicTags.includes(t.name));
    SetSuggestions(newTags);
  }, [currentTags, originalSuggestions]);

  function onTagDelete(i) {
    const tags = currentTags.slice(0);
    tags.splice(i, 1);
    SetCurrentTags([...tags]);
  }

  function onTagAddition(tag) {
    const tags = [].concat(currentTags, tag);
    SetCurrentTags([...tags]);
  }

  const getNextLetter = function (key) {
    return String.fromCharCode(key.charCodeAt(0) + 1);
  };

  function DeleteOption(props) {
    const letter = props.id;
    var newOptions = options.filter((option) => {
      if (option.optionLetter !== letter) {
        return true;
      } else {
        return false;
      }
    });

    newOptions = ReCount(newOptions);
    setCorrectOption("");
    Setoptions([...newOptions]);

    var editorValues = optionEditorValues;
    //console.log("delete letter : " + letter + "   ");
    editorValues = editorValues.filter((v) => {
      if (v.optionLetter !== letter) {
        console.log(
          "delete letter : " + letter + "  optionletter " + v.optionLetter
        );
        return true;
      } else {
        console.log(
          "delete letter : " + letter + "  optionletter " + v.optionLetter
        );
        return false;
      }
    });
    console.log(editorValues);
    editorValues = ReCount(editorValues);
    SetOptionEditorValues([...editorValues]);
  }

  function ReCount(options) {
    var prev = null;
    options.forEach((option) => {
      if (prev === null) {
        option.optionLetter = "A";
      } else {
        option.optionLetter = getNextLetter(prev);
      }
      prev = option.optionLetter;
    });
    return options;
  }

  function OptionAnswerTextChanged(optionLetter, e) {
    var letter = optionLetter;
    var value = e;
    var optionList = options;
    optionList.map((option) => {
      if (option.optionLetter === letter) {
        option.optionContent = value;
      }
    });
    Setoptions([...optionList]);
  }
  console.log(optionEditorValues);

  function AnswerEditorValueChanged(optionLetter, e) {
    console.log(optionEditorValues);
    var editorValues = optionEditorValues;
    if (editorValues.find((v) => v.optionLetter === optionLetter)) {
      editorValues.find((v) => v.optionLetter === optionLetter).EditorValue = e;
    } else {
      editorValues.push({
        optionLetter: optionLetter,
        EditorValue: e,
        ExplanationEditorValue: e,
      });
    }
    SetOptionEditorValues([...editorValues]);
  }

  function GetAnswerEditorValue(optionLetter) {
    if (optionEditorValues.find((v) => v.optionLetter === optionLetter)) {
      return optionEditorValues.find((v) => v.optionLetter === optionLetter)
        .EditorValue;
    } else {
      return EditorState.createEmpty();
    }
  }

  function OptionExplanationTextChanged(optionLetter, e) {
    var letter = optionLetter;
    var value = e;
    var optionList = options;
    optionList.map((option) => {
      if (option.optionLetter === letter) {
        option.ExplanationContent = value;
      }
    });
    Setoptions([...optionList]);
  }
  console.log(optionEditorValues);

  function ExplanationEditorValueChanged(optionLetter, e) {
    console.log(optionEditorValues);
    var editorValues = optionEditorValues;
    if (editorValues.find((v) => v.optionLetter === optionLetter)) {
      editorValues.find(
        (v) => v.optionLetter === optionLetter
      ).ExplanationEditorValue = e;
    } else {
      editorValues.push({
        optionLetter: optionLetter,
        ExplanationEditorValue: e,
        EditorValue: e,
      });
    }
    SetOptionEditorValues([...editorValues]);
  }

  function GetExplanationEditorValue(optionLetter) {
    if (optionEditorValues.find((v) => v.optionLetter === optionLetter)) {
      return optionEditorValues.find((v) => v.optionLetter === optionLetter)
        .ExplanationEditorValue;
    } else {
      return EditorState.createEmpty();
    }
  }

  function CorrectOptionChanged(letter) {
    var optionList = options;
    optionList.map((option) => {
      if (option.optionLetter === letter) {
        option.isCorrect = true;
      } else {
        option.isCorrect = false;
      }
    });
    Setoptions([...optionList]);
    setCorrectOption(letter);
  }
  function RenderOptions(props) {
    let RadioStyle = {
      display: "flex",
      flexDirection: "row",
      marginTop: "1rem",
    };
    const optionList = props.options;
    return optionList.map((option) => (
      <div key={"Option " + option.optionLetter} style={{ marginTop: "2rem" }}>
        <Fade>
          <h5>
            <strong>
              Choice: {option.optionLetter} <br />{" "}
            </strong>
          </h5>
          <Editor
            height="20vh"
            onValueChange={(e) => {
              OptionAnswerTextChanged(option.optionLetter, e);
            }}
            editorValue={GetAnswerEditorValue(option.optionLetter)}
            SetEditorValue={(e) => {
              AnswerEditorValueChanged(option.optionLetter, e);
            }}
          />
          <br />

          <h5>
            <strong>
              Explanation: {option.optionLetter} <br />{" "}
            </strong>
          </h5>

          <Editor
            height="20vh"
            onValueChange={(e) => {
              OptionExplanationTextChanged(option.optionLetter, e);
            }}
            editorValue={GetExplanationEditorValue(option.optionLetter)}
            SetEditorValue={(e) => {
              ExplanationEditorValueChanged(option.optionLetter, e);
            }}
          />
          <br />

          {
            <FieldInput
              type="text"
              placeholder={"Enter answer for choice " + option.optionLetter}
              value={option.optionContent}
              id={option.optionLetter}
              onChange={(e) => {
                OptionAnswerTextChanged(e);
              }}
            />
          }
          <br />
          <h5>Add image to Option</h5>
          <ImageInput
            name={"OptionImage"}
            setImage={optionToImage.set[option.optionLetter]}
          ></ImageInput>

          <div style={RadioStyle}>
            Is Correct Option
            <div style={{ marginTop: ".1rem", marginLeft: ".5rem" }}>
              <FormRadio
                name="Correct Option"
                checked={CorrectOption === option.optionLetter}
                onChange={() => {
                  CorrectOptionChanged(option.optionLetter);
                }}
              ></FormRadio>
            </div>
          </div>
          <Button
            id={option.optionLetter}
            theme="danger"
            size="sm"
            onClick={(e) => {
              DeleteOption({ id: e.target.id });
            }}
            style={{ marginTop: "1rem", marginBottom: "1.5rem" }}
          >
            Delete option letter
          </Button>
          <hr />
        </Fade>
      </div>
    ));
  }

  function AddNewOption(props) {
    console.log(options);
    var currentOptions = options;
    var optionLetter = "A";

    if (options.length !== 0) {
      optionLetter = getNextLetter(
        currentOptions[options.length - 1].optionLetter
      );
    }

    currentOptions.push({
      optionLetter: optionLetter,
      optionContent: "",
      isCorrect: false,
    });
    Setoptions([...currentOptions]);
  }

  function ValidateInputs() {
    var messages = [];

    //Validate Permissions

    // if (user?.admin?.pagepermissions?.includes("All")) {
    //   //Validated
    // } else if (
    //   user?.admin?.deckaddpermissions?.includes("All") ||
    //   user?.admin?.deckaddpermissions?.includes(deck)
    // ) {
    //   //Validated
    // } else if (
    //   user?.admin?.deckeditpermissions?.includes("All") ||
    //   user?.admin?.deckeditpermissions?.includes(deck)
    // ) {
    //   //validated
    // } else {
    //   messages.push("You are not allowed to add questions to this deck");
    // }

    //Validate Deck
    if (deck === undefined || deck === null || deck === "") {
      messages.push("You must select a deck to add the question to");
    }

    //Validate Tags
    if (currentTags.length === 0) {
      messages.push("Tags cannot be left empty");
    } else if (!TagsContainSubject(currentTags)) {
      messages.push("Subject must be included as a tag");
    }
    if (HasDuplicates(currentTags)) {
      messages.push("Duplicate tags are not allowed");
    }
    //Validate Question Text
    if (
      questionText === undefined ||
      questionText === null ||
      questionText === "" ||
      questionText.length === 0
    ) {
      messages.push("Question text cannot be left empty");
      //this validation can be imporved by going through the entire array
    } else {
      if (/<p>(.*?)<\/p>/g.exec(questionText)) {
        if (/<p>(.*?)<\/p>/g.exec(questionText)[1].length === 0) {
          messages.push("Question text cannot be left empty");
        }
      } else if (questionText.length < 8) {
        messages.push("Question text cannot be left empty");
      }
    }

    //Validate Options
    if (options.length < 2) {
      messages.push("Question must have atleast 2 options");
    }
    if (options.length > 6) {
      messages.push("Question cannot have more than 6 options");
    }
    if (
      CorrectOption === undefined ||
      CorrectOption === null ||
      CorrectOption === "" ||
      CorrectOption.length === 0
    ) {
      messages.push("Question must have a correct answer");
    }
    options.forEach((op) => {
      if (
        op.optionContent === undefined ||
        op.optionContent === null ||
        op.optionContent === "" ||
        op.optionContent.lenght === 0
      ) {
        messages.push(
          "Answer for Choice " + op.optionLetter + " is left empty"
        );
      }
    });
    //Validate Explanation Text
    if (
      explainationText === undefined ||
      explainationText === null ||
      explainationText === "" ||
      explainationText.length === 0
    ) {
      messages.push("Explaination text cannot be left empty");
    } else {
      if (/<p>(.*?)<\/p>/g.exec(explainationText)) {
        if (/<p>(.*?)<\/p>/g.exec(explainationText)[1].length === 0) {
          messages.push("Explanation text cannot be left empty");
        }
      } else if (explainationText.length < 8) {
        messages.push("Explanation text cannot be left empty");
      }
    }
    //Return Logic for Function
    if (messages.length === 0) {
      setValidationErrorMessages([]);
      setisValidationError(false);
      return true;
    } else {
      setValidationErrorMessages([...messages]);
      setisValidationError(true);
      return false;
    }
  }
  function TagsContainSubject(tags) {
    var contain = false;
    tags.forEach((tag) => {
      if (
        tag.name === "Biology" ||
        tag.name === "Chemistry" ||
        tag.name === "Physics" ||
        tag.name === "English" ||
        tag.name === "Logical Reasoning" ||
        tag.name === "Science and Math Reasoning" ||
        tag.name === "Science Reasoning" ||
        tag.name === "Mathematics Reasoning" ||
        tag.name === "Maths and Scientific Reasoning" ||
        tag.name === "Psychological Test"
      ) {
        contain = true;
      }
    });
    return contain;
  }
  function HasDuplicates(arr) {
    return new Set(arr).size !== arr.length;
  }

  function OnSubmitClicked(props) {
    if (ValidateInputs() === true) {
      var Options = [];
      options.forEach((op) => {
        console.log(
          "🚀 ~ file: AddQuestionScreen.jsx:563 ~ OnSubmitClicked ~ op.optionLetter:",
          op.optionLetter
        );
        console.log(
          "🚀 ~ file: AddQuestionScreen.jsx:571 ~ options.forEach ~ optionToImage[op.optionLetter]:",
          optionToImage.get[op.optionLetter]
        );

        Options.push({
          OptionLetter: op.optionLetter,
          OptionText: op.optionContent,
          ExplanationText: op.ExplanationContent,
          IsCorrect: op.isCorrect,
          optionImage: optionToImage.get[op.optionLetter],
        });
      });

      var Question = {
        Author: "Umar Mahar",
        Tags: currentTags,
        QuestionText: questionText,
        QuestionMathParsing: questionMathParsing,
        QuestionImage: questionImage,
        Options: Options,
        ExplanationText: explainationText,
        ExplanationImage: explanationImage,
        Published: publish,
        References: referencesText,
      };

      SetIsContolDisabled(true);
      // SubmitQuestion(deck, Question)
      AddNewQuestion(deck, Question)
        .then((res) => {
          SetIsContolDisabled(false);
          ResolveResponse(res);
        })
        .catch((error) => {
          console.log(
            "🚀 ~ file: AddQuestion.jsx:567 ~ OnSubmitClicked ~ error:",
            error
          );

          SetIsContolDisabled(false);
          HandleSubmitError(error);
        });
    } else {
      scrollToTop();
    }
  }
  function ResolveResponse(res) {
    var data = res.data;
    if (res.data.success) {
      console.log("Saved Successfully");
      SetShowModal(true);
    }
  }
  function HandleSubmitError(error) {
    if (error.response) {
      var messages = [];
      messages.push("Could not save as server returned an error");
      setisValidationError(true);
      setValidationErrorMessages([...messages]);
      scrollToTop();
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      var messages = [];
      messages.push("Network Error / Cannot reach server");
      setisValidationError(true);
      setValidationErrorMessages([...messages]);
      scrollToTop();
    } else {
      // Something happened in setting up the request that triggered an Error
      var messages = [];
      messages.push("Could not save due to unknown error");
      setisValidationError(true);
      setValidationErrorMessages([...messages]);
      scrollToTop();
    }
  }

  function ResetForm() {
    if (!deckNameFreezed) {
      SetDeck("TEST DECK");
    }
    if (!tagsFreezed) {
      SetCurrentTags([]);
    }
    if (!questionTextFreezed) {
      SetQuestionText("");
      const editorState = EditorState.push(
        questionEditorValue,
        ContentState.createFromText("")
      );
      SetQuestionEditorValue(editorState);
    }
    if (!questionImageFreezed) {
      questionFileInput.value = "";
      SetQuestionImage("");
    }
    if (!optionsFreezed) {
      var newoptions = [];
      options.forEach((o) => {
        o.optionContent = "";
        o.isCorrect = false;
        newoptions.push(o);
      });
      Setoptions([...newoptions]);
      setCorrectOption("");
    }

    var optionsEditorValues = [];
    optionEditorValues.forEach((o) => {
      var option = {
        optionLetter: o.OptionLetter,
        EditorValue: EditorStateFromHTML("<p></p>"),
      };
      optionsEditorValues.push(option);
    });

    SetOptionEditorValues(optionsEditorValues);

    if (!explanationTextFreezed) {
      SetExplanationText("");
      const editorState = EditorState.push(
        explanationEditorValue,
        ContentState.createFromText("")
      );
      SetExplanationEditorValue(editorState);
    }
    if (!explanationImageFreezed) {
      explanationFileInput.value = "";
      SetExplanationImage("");
    }

    SetReferencesText("");
    const ReditorState = EditorStateFromHTML("");
    SetReferencesTextEditorValue(ReditorState);
  }

  return (
    <div>
      <div className="flexMe">
        <Modal open={showModal} key={`addmodal${showModal}`} toggle={() => {}}>
          <ModalHeader>Success</ModalHeader>
          <ModalBody>
            <h4> Question Successfully Saved</h4> <br />
            <br />
            <Button
              onClick={() => {
                SetShowModal(false);
                ResetForm();
              }}
            >
              Create another question
            </Button>
          </ModalBody>
        </Modal>

        <Card>
          <CardHeader className="Header">
            <h3
              style={{ textAlign: "center", fontfamily: "Roboto" }}
              onClick={() => {}}
            >
              Add a new Question
            </h3>
          </CardHeader>
          <CardBody className="CardBody">
            <div>
              <ErrorCard
                isVisible={isValidationError}
                messages={validationErrorMessages}
              />
              <label htmlFor="Question Tags">
                <h5>Select Deck to add question to</h5>
              </label>
              <FormSelect
                onChange={(e) => {
                  SetDeck(e.target.value);
                }}
                value={deck}
              >
                {deckNames.map((name) => (
                  <option value={name}>{name}</option>
                ))}
              </FormSelect>
              <label htmlFor="Question Tags">
                <h5 style={{ marginTop: "1.5rem" }}>Question Tags</h5>
                Select as many tags as applicable <br /> Make sure to include
                the subject as a tag
              </label>
              <div style={{ "z-index": 10 }}>
                <Tags
                  suggestions={suggestions}
                  onDelete={onTagDelete}
                  onAddition={onTagAddition}
                  tags={currentTags}
                />
              </div>
              <label htmlFor="Question Text">
                <h5 style={{ marginTop: "1.5rem" }}>Question Text</h5>
              </label>
              <div style={{ "z-index": -1 }}>
                <Editor
                  height="20vh"
                  onValueChange={(e) => {
                    SetQuestionText(e);
                    console.log(e);
                  }}
                  editorValue={questionEditorValue}
                  SetEditorValue={SetQuestionEditorValue}
                />
              </div>
              {/*  <FieldInput
                  id="#Question Text"
                  value={questionText}
                  placeholder="Question Text"
                  onChange={() => {}}
                  type="text"
                  multiline={true}
                  size="sm"
                  onChange={(e) => {
                    SetQuestionText(e.target.value);
                  }}
                  /*  invalid={!this.state.TextFields.FullNameIsValid}
                errorText={this.state.TextFields.FullNameErrorText}/> */}
              <FormCheckbox
                checked={questionMathParsing}
                onChange={(e) => SetQuestionMathParsing(!questionMathParsing)}
              >
                Enable Math symbols (LATEX) parsing {options.length}
              </FormCheckbox>
              <hr />
              <h5>Add image to Question</h5>

              <input
                type="file"
                name="Question Image"
                accept=".png,.jpeg,.jpg"
                ref={(ref) => (questionFileInput = ref)}
                onChange={(e) => {
                  if (e.target.files[0] !== undefined) {
                    let file = e.target.files[0];
                    let reader = new FileReader();
                    reader.addEventListener("load", function (e) {
                      let text = e.target.result;

                      SetQuestionImage(text);
                    });
                    reader.readAsDataURL(file);
                  } else {
                    SetQuestionImage("");
                  }
                }}
              />
              <ImagePreview
                image={questionImage}
                onDelete={() => {
                  questionFileInput.value = "";
                  SetQuestionImage("");
                }}
              />
              <hr />
              {RenderOptions({ options: options })}
              <Button
                style={{ marginTop: "1.5rem" }}
                onClick={() => {
                  AddNewOption();
                }}
              >
                Add Option
              </Button>
              <div style={{ marginTop: "1.5rem" }}>
                <label htmlFor="Explanation Text">
                  <h5>Explanation Text</h5>
                </label>
                <Editor
                  height="20vh"
                  onValueChange={(e) => {
                    SetExplanationText(e);
                    console.log(e);
                  }}
                  editorValue={explanationEditorValue}
                  SetEditorValue={SetExplanationEditorValue}
                />
                <FormCheckbox
                  checked={answerMathParsing}
                  onChange={(e) => SetAnswerMathParsing(!answerMathParsing)}
                >
                  Enable Math symbols (LATEX) parsing {options.length}
                </FormCheckbox>
                <hr />
                <h5>Add image to Explanation</h5>
                <input
                  type="file"
                  name="Question Image"
                  accept=".png,.jpeg,.jpg"
                  ref={(ref) => (explanationFileInput = ref)}
                  onChange={(e) => {
                    if (e.target.files[0] !== undefined) {
                      let file = e.target.files[0];
                      let reader = new FileReader();
                      reader.addEventListener("load", function (e) {
                        let text = e.target.result;

                        SetExplanationImage(text);
                      });
                      reader.readAsDataURL(file);
                    } else {
                      SetExplanationImage("");
                    }
                  }}
                />
                <ImagePreview
                  image={explanationImage}
                  onDelete={() => {
                    explanationFileInput.value = "";
                    SetExplanationImage("");
                  }}
                />
                <hr />
              </div>
              <div>
                <label htmlFor="References Text">
                  <h5>References</h5>
                </label>
                <Editor
                  height="20vh"
                  onValueChange={(e) => {
                    SetReferencesText(e);
                    console.log(e);
                  }}
                  editorValue={referencesTextEditorValue}
                  SetEditorValue={SetReferencesTextEditorValue}
                />
              </div>
              <div style={{ marginTop: "1.5rem" }}>
                <FormCheckbox
                  checked={publish}
                  onChange={(e) => {
                    setPublish(!publish);
                  }}
                  toggle={true}
                >
                  <strong style={{ fontSize: "1.3rem" }}>
                    Publish to Website
                  </strong>
                </FormCheckbox>
              </div>
              <div>
                <h5>Select inputs to freeze:</h5>

                <FormCheckbox
                  inline
                  checked={deckNameFreezed}
                  onChange={(e) => SetDeckNameFreezed(!deckNameFreezed)}
                >
                  <strong>Deck Name</strong>
                </FormCheckbox>

                <FormCheckbox
                  inline
                  checked={tagsFreezed}
                  onChange={(e) => SetTagsFreezed(!tagsFreezed)}
                >
                  <strong>Tags</strong>
                </FormCheckbox>
                <FormCheckbox
                  inline
                  checked={questionTextFreezed}
                  onChange={(e) => SetQuestionTextFreezed(!questionTextFreezed)}
                >
                  <strong>Question Text</strong>
                </FormCheckbox>
                <FormCheckbox
                  inline
                  checked={questionImageFreezed}
                  onChange={(e) =>
                    SetQuestionImageFreezed(!questionImageFreezed)
                  }
                >
                  <strong>Question Image</strong>
                </FormCheckbox>
                <FormCheckbox
                  inline
                  checked={optionsFreezed}
                  onChange={(e) => SetOptionsFreezed(!optionsFreezed)}
                >
                  <strong>Options</strong>
                </FormCheckbox>
                <FormCheckbox
                  inline
                  checked={explanationTextFreezed}
                  onChange={(e) =>
                    SetExplanationTextFreezed(!explanationTextFreezed)
                  }
                >
                  <strong>Explanation Text</strong>
                </FormCheckbox>
                <FormCheckbox
                  inline
                  checked={explanationImageFreezed}
                  onChange={(e) =>
                    SetExplanationImageFreezed(!explanationImageFreezed)
                  }
                >
                  <strong>Explanation Image</strong>
                </FormCheckbox>
              </div>
              <div class="form-row text-center">
                <div class="col-12">
                  <div className="centerMe">
                    <Button
                      disabled={isControlDisabled}
                      style={{ marginTop: "2rem" }}
                      pill
                      theme="success"
                      onClick={() => {
                        OnSubmitClicked();
                      }}
                    >
                      Submit Question
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
export default AddQuestionScreen;
function FieldInput(props) {
  const id = props.id;
  const placeholder = props.placeholder;
  const type = props.type;
  const errorText = props.errorText;
  const invalid = props.invalid;
  const valid = props.valid;
  const onChange = props.onChange;
  const shake = props.shake;
  const onAnimationEnd = props.onAnimationEnd;

  function handleChange(e) {
    onChange(e);
  }

  var shakeStyle;
  if (shake == true) {
    shakeStyle = {
      animation: "shake .5s",
    };
  } else {
    shakeStyle = {
      animation: "",
    };
  }
  if (props.multiline === true) {
    return (
      <div>
        <FormTextarea
          id={props.id}
          placeholder={props.placeholder}
          type={props.type}
          value={props.value}
          onChange={handleChange}
          invalid={invalid}
          valid={valid}
          style={shakeStyle}
          onAnimationEnd={onAnimationEnd}
          style={{ height: "300px" }}
        />
        <span style={{ color: "red" }}>{props.errorText}</span>
      </div>
    );
  }
  return (
    <div>
      <FormInput
        id={props.id}
        placeholder={props.placeholder}
        type={props.type}
        value={props.value}
        onChange={handleChange}
        invalid={invalid}
        valid={valid}
        style={shakeStyle}
        onAnimationEnd={onAnimationEnd}
      />
      <span style={{ color: "red" }}>{props.errorText}</span>
    </div>
  );
}

function ErrorCard(props) {
  if (props.isVisible) {
    return (
      <Al onClose={props.onClose} severity="error">
        <AlertTitle>Error</AlertTitle>
        {props.messages.map((message) => {
          return <div>{message}</div>;
        })}
      </Al>
    );
  } else {
    return <div></div>;
  }
}
function ImagePreview(props) {
  var { image, onDelete } = props;
  if (image !== undefined && image !== null && image.length > 0) {
    return (
      <div>
        <img
          src={image}
          style={{ marginRight: "1rem", marginTop: "1rem" }}
          width="350px"
        />
        <Button
          id={"delete image"}
          theme="danger"
          size="sm"
          onClick={(e) => {
            onDelete();
          }}
          style={{
            marginTop: "1rem",
            marginBottom: "1.5rem",
            marginBottom: "1.5rem",
          }}
        >
          Remove Image
        </Button>
      </div>
    );
  } else {
    return <div />;
  }
}

function scrollToTop() {
  var position = document.body.scrollTop || document.documentElement.scrollTop;
  if (position) {
    window.scrollBy(0, -Math.max(1, Math.floor(position / 10)));
    var scrollAnimation = setTimeout(() => {
      scrollToTop();
    }, 10);
  } else clearTimeout(scrollAnimation);
}

function EditorStateFromHTML(html) {
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  return EditorState.createWithContent(contentState);
}
