import axios from "axios";
import { backendUrl } from "../Constants/appConstants";

export function AddNewQuestion(deck, question) {
    var data;
    data = axios({
      method: "POST",
      withCredentials: true,
      url: backendUrl + "/api/questions/AddNewQuestion",
      data: { deckName: deck, question: question , username: question.Author},
    });
    return data;
  }


export function EditQuestion(deck, questionID, question) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: backendUrl + "/api/questions/EditQuestion",
    data: { deckName: deck, questionID: questionID, question: question, username: question.Author },
  });
  return data;
}
