import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import { Autocomplete, Stack } from "@mui/material";
import { addCAApi, addManagerApi, addPromotionalCouponApi, getRepresentativesApi } from "../../APIs/CouponAPI";
import { IconButton, MenuItem, Select, Snackbar } from "@material-ui/core";
import { CloseIcon } from "@material-ui/data-grid";
import MyTextField from "./MyTextField";
import MyNumberField from "./MyNumberField";
import { SimpleSnackbar } from "./SimpleSnackBarComponent";
import CouponInputComponent from "./CouponInputComponent";

export default function AddPromotionalCouponPopup() {
  const [open, setOpen] = useState(false);

  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    status: "success",
  })

  const formik = useFormik({
    initialValues: {
      couponCode: "",
      discountPercentage: 20,
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
    validate: (values) => {
      const errors = {};

      if (!values.couponCode) {
        errors.couponCode = "Required";
      }
      else if (values.couponCode.length > 15 || values.couponCode.length < 5 ) {
        errors.couponCode = "length must be between 15 and 5";
      }

      if (!values.discountPercentage) {
        errors.discountPercentage = "Required";
      } else if (
        values.discountPercentage < 0 ||
        values.discountPercentage > 100
      ) {
        errors.discountPercentage = "Invalid Discount Percentage";
      }

      return errors;
    },
  });

  const handleClickOpen = async () => {
    setOpen(true);
  };


  async function handleSubmit() {
    try{
    await addPromotionalCouponApi({
      couponCode: formik.values.couponCode,
      discountPercentage: formik.values.discountPercentage / 100,
      })
      setSnackbar(prev => ({
        ...prev,
        status: "success",
      }))
    } catch (error) {
      setSnackbar(prev => ({
        ...prev,
        status: "error",
      }))
    }
    setSnackbar((prev) => ({
      ...prev,
      isOpen: true,
      }))

    formik.resetForm();

    setOpen(false);
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>

<SimpleSnackbar
        open={snackbar.isOpen}
        handleClose={() => setSnackbar(prev => ({ ...prev, isOpen: false }))}
        message={snackbar.status === "success" ? "Coupon Added Successfully" : "An Error Occurred"}
      />
      <Button onClick={handleClickOpen}>Add Promotional Coupon</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Promotional Coupon</DialogTitle>
        <DialogContent>
          <br />
          <Stack spacing={3} width="30em">
            <CouponInputComponent formik={formik} />
            <MyNumberField
              formik={formik}
              name="discountPercentage"
              label={"Discount Percentage(in Percentage)"}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={!formik.isValid} onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

