/**
 @module FileNameOrCustomModuleName
*/
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { Table } from "antd";
import { backendUrl, slackUrl } from "../../Constants/appConstants";
import {
  orderColumns,
  CAColumns,
  CAHeadColumns,
} from "../../Constants/RepresentativeDashboardConstants";

/**
 *A function that maps the data attribute _id to id so that it can be used by the Table
 *
 * @param {string} data the data from api returned json
 * @return {*} data that can be consumed by the Table
 */
function prepareDataForTable(data) {
  if (data.data.Heads)
    return data.data.Heads.map((val) => ({
      ...val,
      id: val._id,
      key: val._id,
      CA: val.CA.map((val) => ({ ...val, id: val._id, key: val._id })),
    }));
  else if (data.data.CA)
    return data.data.CA.map((val) => ({ ...val, id: val._id, key: val._id }));
  else if (data.data.Orders)
    return data.data.Orders.map((val) => ({
      ...val,
      id: val._id,
      key: val._id,
    }));
}

function getTableBasedOnDesignation(designation, data) {
  switch (designation) {
    case "Campus Ambassador":
      return <OrderTableComponent orders={data} />;
    case "Campus Ambassador Head":
      return <CATableComponent CAs={data} />;
    case "Campus Ambassador Manager":
      return <CAHeadTableComponent CAHeads={data} />;
    default:
      break;
  }
}

export default function RepresentativeDashboard() {
  const { userId } = useParams();
  const [rows, setRows] = useState([]);
  const [commissionInfo, setCommissionInfo] = useState({});
  const [totalCommission, setTotalCommission] = useState(0);

  const [link, setLink] = useState(
    `${slackUrl}/requests/PostReimbursementRequest`
  );

  const searchParams = new URLSearchParams(useLocation().search);
  const designation = searchParams.get("designation");
  useEffect(() => {
    async function func() {
      console.log(
        "🚀 ~ file: RepresentativeDashboard.jsx:65 ~ func ~ designation:",
        designation.split(" ").join("+")
      );
      let CADetails = await axios.get(
        `${backendUrl}/api/representative/getCommissionDetails?id=${userId}&designation=${designation
          .split(" ")
          .join("+")}`
      );
      console.log(
        "🚀 ~ file: RepresentativeDashboard.jsx:60 ~ func ~ searchParams:",
        searchParams
      );
      setCommissionInfo(CADetails.data);
      setRows(prepareDataForTable(CADetails));
    }
    func();
  }, []);

  useEffect(() => {
    setTotalCommission(commissionInfo.TotalCommission ?? 0);
  }, [rows]);

  useEffect(() => {
    console.log(
      "🚀 ~ file: RepresentativeDashboard.jsx:98 ~ useEffect ~ rows",
      rows
    );
  }, [rows]);

  return (
    <>
      <Box padding={10}>
        <Grid container direction="column" spacing={10}>
          <Grid container item spacing={10} xs>
            <Grid item xs>
              <Card>
                <CardContent>
                  <Typography
                    align="center"
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Total Coupon Commission
                  </Typography>
                  <Typography color="text.secondary" align="center">
                    {totalCommission}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card>
                <CardContent>
                  <Typography
                    align="center"
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Pending Balance
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                    align="center"
                  >
                    {commissionInfo.Total ?? 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card>
                <CardContent>
                  <Typography
                    align="center"
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Total Amount Redeemed
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                    align="center"
                  >
                    {commissionInfo.TotalPaid ?? 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card sx={{ width: 275 }}>
                <CardContent>
                  <Typography
                    align="center"
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Total Coupons Redeemed
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                    align="center"
                  >
                    {rows.length}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (true || commissionInfo.Total > 10) {
                  axios.post(link, {
                    amount: commissionInfo.Total,
                    representativeId: userId,
                    username: commissionInfo.username,
                    designation: commissionInfo.designation,
                  });
                }
              }}
            >
              Request for Reimbursement
            </Button>
          </Grid>
          <Grid item xs>
            {/* <CATableComponent CAs={rows} /> */}
            {getTableBasedOnDesignation(designation, rows)}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

function OrderTableComponent({ orders }) {
  return <Table columns={orderColumns} dataSource={orders} size="small" />;
}

function CATableComponent({ CAs }) {
  return (
    <Table
      columns={CAColumns}
      dataSource={CAs}
      size="small"
      expandable={{
        expandedRowRender: (record, index) => (
          <OrderTableComponent orders={CAs[index].Orders} />
        ),
      }}
    />
  );
}

function CAHeadTableComponent({ CAHeads }) {
  return (
    <Table
      columns={CAHeadColumns}
      dataSource={CAHeads}
      size="small"
      expandable={{
        expandedRowRender: (record, index) => (
          // console.log(CAHeads[index].CA);
          <CATableComponent CAs={CAHeads[index].CA} />
        ),
      }}
    />
  );
}
