export const orderColumns = [
  {
    title: "Coupon Code",
    dataIndex: "CouponCode",
    key: "CouponCode",
  },
  {
    title: "UserName",
    dataIndex: "UserName",
    key: "UserName",
  },
  {
    title: "Total Amount",
    dataIndex: "TotalAmount",
    key: "TotalAmount",
  },
  {
    title: "Paid Amount",
    dataIndex: "PaidAmount",
    key: "PaidAmount",
  },
  {
    title: "Addon SKU",
    dataIndex: "AddonSKU",
    key: "AddonSKU",
  },
  {
    title: "Order Status",
    dataIndex: "OrderStatus",
    key: "OrderStatus",
  },
  {
    title: "Referral",
    dataIndex: "Referal",
    key: "Referal",
  },
  {
    title: "Creation Date",
    dataIndex: "CreationDate",
    key: "CreationDate",
  },
];

export const CAColumns = [
  {
    title: "CA Name",
    dataIndex: "username",
    key: "username",
  },
];

export const CAHeadColumns = [
  {
    title: "CA Head Name",
    dataIndex: "username",
    key: "username",
  },
];