/**
 @module FileNameOrCustomModuleName
*/
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { Table } from "antd";
import { backendUrl, slackUrl } from "../../Constants/appConstants";

const managerColumns = [
  {
    title: "Manager Name",
    dataIndex: "fullname",
    key: "fullname",
  },
  {
    title: "Manager Email",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Dashboard Link",
    dataIndex: "userId",
    key: "userId",
    render: (userId) => (
      <Link to={`/RepresentativeDashboard/${userId}?designation=Campus+Ambassador+Manager`}>
        <Button
          variant="contained"
          color="primary"
          // onClick={() => {
          //   window.open(
          //     `${backendUrl}/RepresentativeDashboard/${userId}`,
          //     "_blank"
          //   );
          // }}
      >
        Dashboard
      </Button></Link>
    ),
  },
];

/**
 *A function that maps the data attribute _id to id so that it can be used by the Table
 *
 * @param {string} data the data from api returned json
 * @return {*} data that can be consumed by the Table
 */
function prepareDataForTable(data) {
  return data.data.map((val) => ({ ...val, id: val._id }));
  // if (data.data.Heads)
  //   return data.data.Heads.map((val) => ({
  //     ...val,
  //     id: val._id,
  //     key: val._id,
  //     CA: val.CA.map((val) => ({ ...val, id: val._id, key: val._id })),
  //   }));
  // else if (data.data.CA)
  //   return data.data.CA.map((val) => ({ ...val, id: val._id, key: val._id }));
  // else if (data.data.Orders)
  //   return data.data.Orders.map((val) => ({
  //     ...val,
  //     id: val._id,
  //     key: val._id,
  //   }));
}

export default function AdminRepresentativeDashboard() {
  const { userId } = useParams();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    async function func() {
      let Managers = await axios.get(
        `${backendUrl}/api/representative/getRepresentatives?designation=Campus Ambassador Manager`
      );
      setRows(prepareDataForTable(Managers));
    }
    func();
  }, []);

  return (
    <>
      <Box padding={10}>
        <Grid container direction="column" spacing={10} alignItems="center">
          <Grid item xs>
            <Typography variant="h4">Representatives</Typography>
          </Grid>
          <Grid item xs>
            <Table columns={managerColumns} dataSource={rows} size="small" />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
