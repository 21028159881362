import React, { useState, useEffect } from "react";
import { GetSpecificCoupons, UpdateCoupon } from "../../APIManager";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  FormTextarea,
  FormCheckbox,
  Fade,
  FormRadio,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
  FieldInput,
  Button,
} from "shards-react";

import Snackbar from "@material-ui/core/Snackbar";
import mapKeys from "lodash/mapKeys";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { Category } from "@material-ui/icons";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useParams,
  useHistory,
} from "react-router-dom";

import { AddPayment, GetPayments } from "../../APIManager";

export default function CouponEditor() {
  let { couponCode } = useParams();
  const [allCoupons, SetAllCoupons] = useState([]);

  const coupon = allCoupons.filter((c) => c.CouponCode == couponCode)[0];

  const [username, SetUsername] = useState("");
  const amount = coupon?.DiscountAmount;
  const [singleUse, SetSingleUse] = useState(false);
  const [userSpecific, SetUserSpecific] = useState(false);
  const author = coupon?.Author;
  const [forName, SetForName] = useState("");
  const [category, SetCategory] = useState("");
  const [contactEmail, SetContactEmail] = useState("");
  const [paymentDetails, SetPaymentDetails] = useState("");

  const [usernameValid, SetUsernameValid] = useState(true);
  const [forNameValid, SetForNameValid] = useState(false);
  const [categoryValid, SetCategoryValid] = useState(false);
  const [contactEmailValid, SetContactEmailValid] = useState(true);

  const [usernameErrorText, SetUsernameErrorText] = useState("");
  const [forNameErrorText, SetForNameErrorText] = useState("");
  const [categoryErrorText, SetCategoryErrorText] = useState("");
  const [contactEmailErrorText, SetContactEmailErrorText] = useState("");

  const [contactEmailEnabled, SetContactEmailEnabled] = useState(false);

  useEffect(() => {
    GetSpecificCoupons(couponCode).then((res) => {
      SetAllCoupons(res.data);
      const thiscoupon = res.data.filter((c) => c.CouponCode == couponCode)[0];
      SetUsername(thiscoupon.AssignedUsername);
      SetSingleUse(thiscoupon.SingleUse);
      SetUserSpecific(thiscoupon.UserSpecific);
      SetForName(thiscoupon.ForName);
      SetCategory(thiscoupon.Category);
      SetContactEmail(thiscoupon.ContactEmail);
      SetPaymentDetails(thiscoupon.PaymentDetails);

      ValidateCategory(thiscoupon.Category);
      ValidateForName(thiscoupon.ForName);
      ValidateContactEmail(thiscoupon.ContactEmail);
    });
  }, []);

  console.log(category);

  useEffect(() => {
    var tSet = new Set([
      "Associate",
      "Influencer",
      "Campus Ambassador",
      "Brand Ambassador",
    ]);
    var enabled = tSet.has(category);

    SetContactEmailEnabled(enabled);
    if (enabled && !contactEmail) {
      SetContactEmailValid(false);
    }
    if (!enabled) {
      SetContactEmail("");
      SetContactEmailErrorText("");
      SetContactEmailValid(true);
    }
  }, [category]);

  if (allCoupons.length === 0) {
    return <h1>Fetching data....</h1>;
  }
  if (!coupon) {
    return <h1>Coupon Not Found</h1>;
  }

  function OnUsernameChange(e) {
    var newValue = e.target.value.toLowerCase();
    SetUsername(newValue);
    ValidateUsername(newValue);
  }
  function ValidateUsername(newUsername) {
    if (newUsername.length === 0) {
      SetUsernameErrorText("Email address can't be empty");
      SetUsernameValid(false);
      return false;
    }
    var regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(newUsername)) {
      SetUsernameErrorText("Enter a valid email address");
      SetUsernameValid(false);
      return false;
    } else {
      SetUsernameErrorText("");
      SetUsernameValid(true);
      return true;
    }
  }

  function OnForNameChange(e) {
    var newValue = e.target.value;
    SetForName(newValue);
    ValidateForName(newValue);
  }
  function ValidateForName(newForName) {
    if (newForName?.length === 0) {
      SetForNameErrorText("For Name can't be empty");
      SetForNameValid(false);
      return false;
    } else {
      SetForNameValid(true);
      SetForNameErrorText("");
      return true;
    }
  }

  function OnCategoryChange(e) {
    var newValue = e.target.value;
    SetCategory(newValue);
    ValidateCategory(newValue);
  }
  function ValidateCategory(newCategory) {
    if (newCategory?.length === 0) {
      SetCategoryErrorText("Category can't be empty");
      SetCategoryValid(false);
      return false;
    } else {
      SetCategoryValid(true);
      SetCategoryErrorText("");
      return true;
    }
  }

  function OnContactEmailChange(e) {
    var newValue = e.target.value.toLowerCase().trim();
    SetContactEmail(newValue);
    ValidateContactEmail(newValue);
  }
  function ValidateContactEmail(newContactEmail) {
    if (newContactEmail?.length === 0) {
      SetContactEmailErrorText("Contact Email address can't be empty");
      SetContactEmailValid(false);
      return false;
    }
    var regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(newContactEmail)) {
      SetContactEmailErrorText("Enter a valid email address");
      SetContactEmailValid(false);
      return false;
    } else {
      SetContactEmailErrorText("");
      SetContactEmailValid(true);
      return true;
    }
  }

  return (
    <Card>
      <CardHeader>
        <h3
          style={{ textAlign: "center", fontfamily: "Roboto" }}
          onClick={() => {}}
        >
          Coupon Editor
        </h3>
      </CardHeader>
      <CardBody>
        <div style={{ width: "100%" }}>
          <h3>Edit Coupon Info</h3>
          <DialogContent>
            <TextField
              value={couponCode}
              variant="outlined"
              autoFocus
              margin="dense"
              id="Complaint"
              type="text"
              fullWidth
              label="Coupon Code"
              inputProps={{ maxLength: 400 }}
            />
            <TextField
              value={amount}
              variant="outlined"
              autoFocus
              margin="dense"
              id="Complaint"
              type="number"
              fullWidth
              label="Discount Amount"
              inputProps={{ maxLength: 400 }}
            />
            <TextField
              value={username}
              variant="outlined"
              onChange={OnUsernameChange}
              error={!usernameValid}
              autoFocus
              helperText={!usernameValid ? usernameErrorText : ""}
              margin="dense"
              id="Complaint"
              type="text"
              fullWidth
              label="User Email Address"
              disabled={!userSpecific}
              inputProps={{ maxLength: 400 }}
            />
            <TextField
              value={author}
              variant="outlined"
              autoFocus
              margin="dense"
              id="Complaint"
              type="text"
              fullWidth
              label="Who is making this payment"
              inputProps={{ maxLength: 400 }}
            />
            <TextField
              value={forName}
              variant="outlined"
              onChange={OnForNameChange}
              error={!forNameValid}
              autoFocus
              helperText={!forNameValid ? forNameErrorText : ""}
              margin="dense"
              id="Complaint"
              type="text"
              fullWidth
              label="Who are you making this coupon for"
              inputProps={{ maxLength: 400 }}
            />
            <br />
            Coupon Category
            <Select
              variant="outlined"
              onChange={OnCategoryChange}
              value={String(category)}
              error={!categoryValid}
              autoFocus
              helperText={!categoryValid ? categoryErrorText : ""}
              id="Complaint"
              fullWidth
              label="Who are you making this coupon for"
            >
              <MenuItem value={"Associate"}>{"Associate"}</MenuItem>
              <MenuItem value={"Scholarship"}>{"Scholarship"}</MenuItem>
              <MenuItem value={"Influencer"}>{"Influencer"}</MenuItem>
              <MenuItem value={"Partnership"}>{"Partnership"}</MenuItem>
              <MenuItem value={"Campus Ambassador"}>
                {"Campus Ambassador"}
              </MenuItem>
              <MenuItem value={"Brand Ambassador"}>
                {"Brand Ambassador"}
              </MenuItem>
              <MenuItem value={"PreMed Team"}>{"PreMed Team"}</MenuItem>
              <MenuItem value={"Demo"}>{"Demo"}</MenuItem>
              <MenuItem value={"Testing (Umar)"}>{"Testing (Umar)"}</MenuItem>
            </Select>
            <TextField
              value={contactEmail}
              variant="outlined"
              onChange={OnContactEmailChange}
              error={!contactEmailValid}
              autoFocus
              helperText={!contactEmailValid ? contactEmailErrorText : ""}
              margin="dense"
              id="Complaint"
              type="text"
              fullWidth
              label="Contact Email Address"
              disabled={!contactEmailEnabled}
              inputProps={{ maxLength: 400 }}
            />
            <TextField
              value={paymentDetails}
              variant="outlined"
              onChange={(e) => {
                SetPaymentDetails(e.target.value);
              }}
              autoFocus
              margin="dense"
              id="Payment Details"
              type="text"
              fullWidth
              label="Payment Details"
              inputProps={{ maxLength: 400 }}
              multiline
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={userSpecific}
                  onChange={() => {
                    SetUserSpecific(!userSpecific);
                    if (!!userSpecific) {
                      SetUsername("");
                      SetUsernameErrorText("");
                      SetUsernameValid(true);
                    } else {
                      SetUsernameValid(false);
                      SetSingleUse(true);
                    }
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="User Specific"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={userSpecific ? true : singleUse}
                  onChange={() => {
                    SetSingleUse(!singleUse);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Single Use"
            />
            <br />
            <Button
              disabled={
                !(
                  usernameValid &
                  forNameValid &
                  categoryValid &
                  contactEmailValid
                )
              }
              style={{ margin: "1em" }}
              onClick={() => {
                UpdateCoupon(
                  couponCode,
                  username,
                  forName,
                  category,
                  contactEmail,
                  paymentDetails
                ).then((res) => {
                  window.location.reload();
                });
                /* onSubmit(
                  couponCode,
                  amount,
                  singleUse,
                  userSpecific,
                  username,
                  author,
                  forName,
                  category,
                  contactEmail
                );
 */
              }}
              variant="contained"
              color="primary"
            >
              Save Coupon Changes
            </Button>
          </DialogContent>
        </div>
        <hr />
        <div style={{ width: "100%", fontSize: "1.5em" }}>
          <h3>Usage Details</h3>
          <div>Times Used : {coupon.TimesUsed}</div>
          <div>Verified Uses : {coupon.VerifiedUses}</div>
          <div>Total Commision : Rs. {coupon.Commision}</div>
        </div>
        <hr />
        <ReimburmentsBox key={"RBBOX"} coupon={coupon} />
      </CardBody>
    </Card>
  );
}

const ReimburmentsBox = ({ coupon }) => {
  let { couponCode } = useParams();
  const [modalOpen, SetModalOpen] = useState(false);

  const [payments, SetPayments] = useState([]);

  var totalPayments = payments.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.Amount;
  }, 0);

  useEffect(() => {
    RetreivePayments();
  }, []);

  function RetreivePayments() {
    GetPayments(couponCode).then((res) => {
      SetPayments(res.data.reverse());
    });
  }

  return (
    <div style={{ width: "100%", fontSize: "1.5em" }}>
      <PaymentModal
        key={`PaymentModal ${modalOpen}`}
        modalOpen={modalOpen}
        SetModalOpen={SetModalOpen}
        onSubmit={() => {
          SetModalOpen(false);
          RetreivePayments();
        }}
      />
      <h3>Reimbursments</h3>
      <Button
        onClick={() => {
          SetModalOpen(true);
        }}
      >
        Make Payment
      </Button>
      <div>
        Pending Balance :{" "}
        <strong>Rs. {coupon.Commision - totalPayments}</strong>
      </div>
      <div>Total Payments : Rs. {totalPayments}</div>
      <div>
        {payments.map((p) => {
          return (
            <div style={{ maxWidth: "600px", marginBottom: "1em" }}>
              <Card>
                <CardHeader>
                  Amount: <strong>Rs. {p.Amount}</strong>
                </CardHeader>
                <CardBody>
                  <div> Done By: {p.DoneBy}</div>
                  <div> Date/Time: {new Date(p.DatePak).toUTCString()}</div>
                  Notes:
                  <div
                    style={{
                      whiteSpace: "pre-line",
                      fontSize: "0.8em",
                      fontStyle: "italic",
                    }}
                  >
                    {p.Notes}
                  </div>
                </CardBody>
              </Card>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const PaymentModal = ({ modalOpen, SetModalOpen, onSubmit }) => {
  let { couponCode } = useParams();

  var FileInput;

  const [amount, SetAmount] = useState(1);
  const [date, SetDate] = useState(
    new Date(new Date().getTime() + 0 * 3600 * 1000)
  );
  const [author, SetAuthor] = useState("");
  const [notes, SetNotes] = useState("");

  const [proofOfPayment, SetProofOfPayment] = useState("");

  const [amountValid, SetAmountValid] = useState(true);
  const [authorValid, SetAuthorValid] = useState(false);
  const [proofOfPaymentValid, SetProofOfPaymentValid] = useState(false);

  const [amountErrorText, SetAmountErrorText] = useState("");
  const [authorErrorText, SetAuthorErrorText] = useState("");
  const [proofOfPaymentErrorText, SetProofOfPaymentErrorText] = useState("");

  function OnAmountChange(e) {
    var newValue = Math.floor(parseInt(e.target.value)).toFixed(0);
    SetAmount(newValue);
    ValidateAmount(newValue);
  }
  function ValidateAmount(newAmount) {
    if (isNaN(newAmount)) {
      SetAmountErrorText("Amount can't be empty");
      SetAmountValid(false);
      return false;
    }
    if (newAmount > 100000) {
      SetAmountErrorText("Payment cant be more than Rs 100,000");
      SetAmountValid(false);
      return false;
    }
    if (newAmount < 1) {
      SetAmountErrorText("Payment cant be less than Rs 1");
      SetAmountValid(false);
      return false;
    } else {
      SetAmountErrorText("");
      SetAmountValid(true);
    }
  }
  function OnAuthorChange(e) {
    var newValue = e.target.value.toLowerCase();
    SetAuthor(newValue);
    ValidateAuthor(newValue);
  }
  function ValidateAuthor(newAuthor) {
    if (newAuthor.length === 0) {
      SetAuthorErrorText("Author can't be empty");
      SetAuthorValid(false);
      return false;
    } else {
      SetAuthorValid(true);
      SetAuthorErrorText("");
      return true;
    }
  }

  function OnNotesChange(e) {
    var newValue = e.target.value;
    SetNotes(newValue);
  }

  return (
    <Dialog
      scroll="body"
      open={modalOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        SetModalOpen(false);
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"sm"}
      fullWidth={"sm"}
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Create new payment"}
      </DialogTitle>
      <DialogContent>
        <TextField
          value={amount}
          variant="outlined"
          onChange={OnAmountChange}
          error={!amountValid}
          autoFocus
          helperText={!amountValid ? amountErrorText : ""}
          margin="dense"
          id="Complaint"
          type="number"
          fullWidth
          label="Discount Amount"
          inputProps={{ maxLength: 400 }}
        />
        <TextField
          value={author}
          variant="outlined"
          onChange={OnAuthorChange}
          error={!authorValid}
          autoFocus
          helperText={!authorValid ? authorErrorText : ""}
          margin="dense"
          id="Complaint"
          type="text"
          fullWidth
          label="Who is making this payment"
          inputProps={{ maxLength: 400 }}
        />
        <br />
        <TextField
          value={notes}
          variant="outlined"
          onChange={OnNotesChange}
          autoFocus
          margin="dense"
          id="Complaint"
          type="text"
          fullWidth
          label="Notes"
          inputProps={{ maxLength: 400 }}
          multiline
        />
        <br />
        <br />
        <TextField
          id="date"
          variant="outlined"
          label="Date of payment"
          type="datetime-local"
          onChange={(e) => {
            console.log(e.target.value);
            SetDate(new Date(e.target.value));
          }}
          value={getISOStringWithoutSecsAndMillisecs1(date)}
          defaultValue={getISOStringWithoutSecsAndMillisecs1(date)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <div>{date.toLocaleString()}</div>
        <br />
        <br />
        <input
          type="file"
          name="Payment Proof"
          accept=".png,.jpeg,.jpg"
          ref={(ref) => (FileInput = ref)}
          onChange={(e) => {
            console.log(e.target.files);
            if (e.target.files[0] !== undefined) {
              let file = e.target.files[0];
              let reader = new FileReader();
              reader.addEventListener("load", function (e) {
                let text = e.target.result;

                SetProofOfPayment(text);
                console.log(text);
              });
              reader.readAsDataURL(file);
            } else {
              SetProofOfPayment("");
            }
          }}
        />
        <br />
        <Button
          disabled={!(amountValid & authorValid & (proofOfPayment !== ""))}
          style={{ margin: "1em" }}
          onClick={() => {
            var utcDate = date.toISOString();
            var tempDate = new Date(date);
            tempDate.setHours(tempDate.getHours() + 5);
            var pakDate = tempDate.toISOString();
            AddPayment(
              couponCode,
              amount,
              utcDate,
              pakDate,
              author,
              notes,
              proofOfPayment
            ).then((res) => {
              onSubmit();
            });
          }}
          variant="contained"
          color="primary"
        >
          Record Payment
        </Button>
      </DialogContent>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function getISOStringWithoutSecsAndMillisecs1(date) {
  var test = new Date(new Date(date).getTime() + 5 * 3600 * 1000);

  const dateAndTime = new Date(test).toISOString().split("T");
  const time = dateAndTime[1].split(":");

  return dateAndTime[0] + "T" + time[0] + ":" + time[1];
}
