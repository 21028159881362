import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  FormTextarea,
  FormCheckbox,
  Fade,
  FormRadio,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
  FieldInput,
  Button,
  Badge,
  ButtonGroup,
  ButtonToolbar,
  ModalFooter,
} from "shards-react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import { GetOrder, ChangeOrderStatus } from "../../APIManager";

export default function VerifyOrder() {
  let { orderid } = useParams();
  const [order, SetOrder] = useState();
  const [modalOpen, SetModalOpen] = useState(false);
  const [modalData, SetModalData] = useState({
    Header: "Confirm",
    Content: "Are you sure you want to",
    OnYes: () => {},
  });

  useEffect(() => {
    GetOrder(orderid).then((res) => [SetOrder(res.data)]);
  }, []);

  if (!order) {
    return <h1>Loading...</h1>;
  }

  var CD = new Date(order.CreationDate);
  CD.setHours(CD.getHours() - 5);
  var AD = new Date(order.ActionDate);
  AD.setHours(AD.getHours() - 5);

  return (
    <div>
      <Modal open={modalOpen}>
        <ModalHeader>{modalData.Header}</ModalHeader>
        <ModalBody>{modalData.Content}</ModalBody>
        <ModalFooter>
          <Button
            theme="success"
            onClick={() => {
              SetModalOpen(false);
              modalData.OnYes();
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              SetModalOpen(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Card>
        <CardHeader>
          <h3
            style={{ textAlign: "center", fontfamily: "Roboto" }}
            onClick={() => {}}
          >
            Verify Order
          </h3>
        </CardHeader>
        <CardBody>
          <div style={{ fontSize: "1.4em" }}>
            <div>Order ID: {order._id}</div>
            <div>User Name: {order.UserName}</div>
            <div>Product: {order.AddonSKU}</div>
            <div>Total Amount: {order.TotalAmount}</div>
            <div>Discount Amount: {order.DiscountAmount}</div>
            <div>Paid Amount: {order.PaidAmount}</div>
            <div>Coupon Code: {order.CouponCode}</div>
            <div>Payment Method: {order.PaymentMethod}</div>
            <div>
              Order Status:{" "}
              <Badge theme={GetBadgeTheme(order.OrderStatus)}>
                {order.OrderStatus}
              </Badge>
            </div>
            <div>Creation Date: {CD.toLocaleString()}</div>
            <div>Action Date: {AD.toLocaleString()}</div>
          </div>
          <br />
          <img
            style={{ width: "100%", maxWidth: "600px" }}
            src={`https://premedpk-cdn.sgp1.digitaloceanspaces.com/PaymentProofs/${order._id}`}
          />
        </CardBody>
        <CardFooter
          style={{
            position: "fixed",
            bottom: "0",
            width: "100%",
            opacity: "1",
            "background-color": "rgba(235, 236, 240, 1)",
          }}
        >
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                disabled={order.OrderStatus === "Accepted"}
                onClick={() => {
                  SetModalOpen(true);
                  SetModalData({
                    Header: "Confrim",
                    Content: "Are you sure you want to ACCEPT this order",
                    OnYes: () => {
                      ChangeOrderStatus(order._id, "Accepted").then(() => {
                        window.location.reload();
                      });
                    },
                  });
                }}
                theme="success"
              >
                Accept
              </Button>
              <Button
                disabled={order.OrderStatus === "Accepted"}
                theme="danger"
                onClick={() => {
                  SetModalOpen(true);
                  SetModalData({
                    Header: "Confrim",
                    Content: "Are you sure you want to DENY this order",
                    OnYes: () => {
                      ChangeOrderStatus(order._id, "Denied").then(() => {
                        window.location.reload();
                      });
                    },
                  });
                }}
              >
                Deny
              </Button>
              <Button
                disabled={order.OrderStatus === "Accepted"}
                theme="secondary"
                onClick={() => {
                  SetModalOpen(true);
                  SetModalData({
                    Header: "Confrim",
                    Content: "Are you sure you want to CANCEL this order",
                    OnYes: () => {
                      ChangeOrderStatus(order._id, "Cancelled").then(() => {
                        window.location.reload();
                      });
                    },
                  });
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </CardFooter>
      </Card>
    </div>
  );
}

function GetBadgeTheme(text) {
  switch (text) {
    case "Pending":
      return "warning";
    case "Accepted":
      return "success";
    case "Denied":
      return "danger";
    case "Cancelled":
      return "secondary";
    default:
      break;
  }
}
