import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useState } from "react";
import { couponToCAApi } from "../../APIs/CouponAPI";
import { SimpleSnackbar } from "./SimpleSnackBarComponent";

export default function CouponToCAPopup() {
  const [open, setOpen] = React.useState(false);

  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    status: "success",
  });

  const formik = useFormik({
    initialValues: {
      couponCode: "",
      CAEmail: "",
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
    validate: (values) => {
      console.log(
        "🚀 ~ file: CouponToCAPopup.jsx:33 ~ CouponToCAPopup ~ values.couponCode:",
        values.couponCode
      );
      const errors = {};
      if (!values.couponCode) {
        errors.couponCode = "Coupon Code is Required";
      }
      if (!values.CAEmail) {
        errors.CAEmail = "Campus Ambassador Email is Required";
      }
      return errors;
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  async function handleSubmit() {
    if (!formik.isValid) {
      return;
    }
    try {
      await couponToCAApi({
        couponCode: formik.values.couponCode,
        username: formik.values.CAEmail,
      });
      setSnackbar((prev) => ({
        ...prev,
        status: "success",
      }));
    } catch (error) {
      setSnackbar((prev) => ({
        ...prev,
        status: "error",
      }));
    }
    setSnackbar((prev) => ({
      ...prev,
      isOpen: true,
    }));

    formik.resetForm();
    setOpen(false);
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <SimpleSnackbar
        open={snackbar.isOpen}
        handleClose={() => setSnackbar((prev) => ({ ...prev, isOpen: false }))}
        message={
          snackbar.status === "success"
            ? "Coupon assigned Successfully"
            : "An Error Occurred"
        }
      />
      <Button color="primary" onClick={handleClickOpen}>
        Associate Coupon To CA
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Associate Coupon To CA</DialogTitle>
        <DialogContent>
          <br />
          <TextField
            fullWidth
            name="couponCode"
            label={"Coupon Code"}
            value={formik.values.couponCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.couponCode && Boolean(formik.errors.couponCode)
            }
            helperText={formik.touched.couponCode && formik.errors.couponCode}
          />
          <TextField
            fullWidth
            name="CAEmail"
            label={"CAEmail"}
            value={formik.values.CAEmail}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.CAEmail && Boolean(formik.errors.CAEmail)}
            helperText={formik.touched.CAEmail && formik.errors.CAEmail}
          />
          {/* </Stack> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={!formik.isValid} onClick={handleSubmit}>
            Create Association
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
