import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Alert, Badge } from "shards-react";
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import {
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  CardFooter,
  Button,
  Fade,
} from "shards-react";
import { Progress } from "shards-react";
import { Breadcrumb, BreadcrumbItem } from "shards-react";
let Question = {
  QuestionID: "001",
  QuestionContent: "Which of the following is a Mammal",
  QuestionOptions: [
    { OptionLetter: "A", OptionContent: "Frog", isCorrect: false },
    { OptionLetter: "B", OptionContent: "Dolphin", isCorrect: true },
    { OptionLetter: "C", OptionContent: "Shark", isCorrect: false },
    { OptionLetter: "D", OptionContent: "Aligator", isCorrect: false },
  ],
};

let option1 = { OptionID: "1", Content: "A", isCorrect: false };

const QuizOptionButton = (props) => {
  let Correctoptionstyle = {
    marginLeft: "0.5rem",
    backgroundColor: "Green",
  };
  let InCorrectoptionstyle = {
    marginLeft: "0.5rem",
    backgroundColor: "Red",
    hover: "transform: scale(1.1);",
  };

  let InLineStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };

  let Selectedoptionstyle = {
    border: "3px solid black",
  };
  function GetBadgeText() {
    if (props.isCorrect) {
      return "Correct Answer";
    } else {
      return "Incorrect Answer";
    }
  }
  function getStyle() {
    if (props.isCorrect) {
      return Correctoptionstyle;
    } else {
      return InCorrectoptionstyle;
    }
  }

  function RenderBadges() {
    var a = props.ShowBadges;
    if (a == true) {
      return (
        <div style={InLineStyle}>
          <Fade in={true}>
            <Badge style={getStyle()}>
              <h8> {GetBadgeText()}</h8>
            </Badge>
          </Fade>
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <div style={InLineStyle}>
      <Button
        onClick={() => {
          props.EventHandler(props);
        }}
      >
        {props.Content} &rarr;
      </Button>{" "}
      {RenderBadges()}
    </div>
  );
};

function QuizDeck() {
  const [showAnswer, SetShowAnswer] = useState(false);

  let CardStyle = {
    width: "100%",
    height: "100%",
    left: "0",
    top: "0",
  };

  function RenderAnswerFooter() {
    if (showAnswer == true) {
      return (
        <div>
          <Fade in={true}>
            <CardFooter>Answer Explanation: Dolphins are Mamamals</CardFooter>
          </Fade>
        </div>
      );
    } else {
      return null;
    }
  }

  const {
    QuestionID: QuestionID,
    QuestionContent: QuestionContent,
    QuestionOptions: QuestionOptions,
  } = Question;

  return (
    <div class="animatedParent">
      <div class="animated bounceInDown">
        <Fade in={true}>
          <Card style={CardStyle}>
            <CardHeader>{"Question: " + QuestionID}</CardHeader>

            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#">Home</a>
              </BreadcrumbItem>
              <BreadcrumbItem active>Library</BreadcrumbItem>
            </Breadcrumb>

            <CardBody>
              <CardTitle>{QuestionContent}</CardTitle>
              <p>
                {QuestionOptions.map((option) => (
                  <h7>
                    {option.OptionLetter + ") " + option.OptionContent} <br />{" "}
                  </h7>
                ))}
              </p>
              {QuestionOptions.map((option) => (
                <div>
                  <QuizOptionButton
                    Content={option.OptionLetter}
                    isCorrect={option.isCorrect}
                    ShowBadges={showAnswer}
                    EventHandler={() => SetShowAnswer(true)}
                  ></QuizOptionButton>{" "}
                  <br />
                </div>
              ))}
            </CardBody>
            <nav aria-label="Page navigation example">
              <ul
                class="pagination justify-content-center"
                style={{ fontSize: "1.3rem" }}
              >
                <li class="page-item">
                  <a class="page-link" href="#">
                    Previous
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </nav>

            <div
              class="progress"
              style={{ height: "20px", backgroundColor: "lightgray" }}
            >
              <div
                class="progress-bar bg-info progress-bar-animated"
                role="progressbar"
                style={{ width: "35%", height: "20px" }}
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <div style={{ fontSize: ".9rem" }}>
                  40 out of 200 Questions Completed
                </div>
              </div>
            </div>

            {RenderAnswerFooter()}
          </Card>
        </Fade>
      </div>
    </div>
  );
}
export default QuizDeck;
