import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import { Stack } from "@mui/material";
import { addManagerApi } from "../../APIs/CouponAPI";
import { IconButton, MenuItem, Select, Snackbar } from "@material-ui/core";
import { CloseIcon } from "@material-ui/data-grid";
import MyTextField from "./MyTextField";
import MyNumberField from "./MyNumberField";
import { SimpleSnackbar } from "./SimpleSnackBarComponent";

export default function AddManagerPopup() {
  const [open, setOpen] = React.useState(false);


  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    status: "success",
  })

  const formik = useFormik({
    initialValues: {
      managerName: "",
      managerEmail: "",
      commissionRate: 10,
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
    validate: (values) => {
      const errors = {};

      if(!values.managerName) {
        errors.managerName = "Required";
      } else if (
        isValidName(values.managerName)
      ) {
        errors.managerName = "Invalid name";
      }

      if (!values.managerEmail) {
        errors.managerEmail = "Required";
      } else if (
        isValidEmail(values.managerEmail)
      ) {
        errors.managerEmail = "Invalid email address";
      }

      if(!values.commissionRate){
        errors.commissionRate = "Required";
      } 
      else if (
        values.commissionRate < 0 ||
        values.commissionRate > 100
      ) {
        errors.commissionRate = "Invalid Commission Rate";
      }


      return errors;
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  function isValidEmail(email) {
    return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }

  async function handleSubmit() {
    try{
    await addManagerApi({
      managerName: formik.values.managerName,
      managerEmail: formik.values.managerEmail,
      commissionRate: formik.values.commissionRate / 100,
    });
    setSnackbar(prev => ({
      ...prev,
      status: "success",
    }))
  } catch (error) {
    setSnackbar(prev => ({
      ...prev,
      status: "error",
    }))
  }
  setSnackbar((prev) => ({
    ...prev,
    isOpen: true,
    }))

    formik.resetForm();
    setOpen(false);
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>

<SimpleSnackbar
        open={snackbar.isOpen}
        handleClose={() => setSnackbar(prev => ({ ...prev, isOpen: false }))}
        message={snackbar.status === "success" ? "Manager Added Successfully" : "An Error Occurred"}
      />
      <Button onClick={handleClickOpen}>Add Manager</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Manager</DialogTitle>
        <DialogContent>
          <br />
          <Stack spacing={3} width="30em">
            <MyTextField
              formik={formik}
              name="managerName"
              label={"Manager Name"}
              />
            <MyTextField
              formik={formik}
              name="managerEmail"
              label={"Manager Email"}
              />
            <MyNumberField
              formik={formik}
              name="commissionRate"
              label={"Commission Rate (in Percentage)"}
              />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={!formik.isValid} onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
function isValidName(values) {
  return !/^[a-zA-Z ]{2,30}$/i.test(
    values.managerName
  );
}

