import { TextField } from "@material-ui/core";
import React from "react";

export default function MyTextField({ formik, label, name, type, onChange }) {
  return (
    <TextField
      fullWidth
      name={name}
      label={label}
      value={formik.values[name]}
      onChange={onChange || formik.handleChange}
      onBlur={formik.handleBlur}
      type={type}
      error={
        formik.touched[name] && Boolean(formik.errors[name])
      }
      helperText={formik.touched[name] && formik.errors[name]}
    />
  );
}
