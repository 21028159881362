import "shards-ui/dist/css/shards.min.css";
import "./AddQuestion.css";
import axios from "axios";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Al from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import React, { Component, useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  FormTextarea,
  FormCheckbox,
  Fade,
  FormRadio,
  FormSelect,
} from "shards-react";
import { Form, FormInput, FormGroup } from "shards-react";
import { SubmitNewDeck,GetDeckNames,GetQuestionIDs,SubmitNewMock } from "../../APIManager";

const AddNewDeck = () => {

  const [decks, SetDecks] = useState([]);
  const [copyDeckName , SetCopyDeckName] = useState("TEST DECK");

  const [deckName, SetDeckName] = useState("");
  const [mockIDs, SetMockIDs] = useState("");

  const [isValidationError, setisValidationError] = useState(false);
  const [validationErrorMessages, setValidationErrorMessages] = useState([]);

  var fileInput;

  useEffect(() => {
    if (decks.length < 1) {
      GetDeckNames()
        .then((res) => {
          var rawdecknames = res.data;
          var deckNames = [];
          rawdecknames.forEach((deck) => {
            deckNames.push(deck.Name);
          });
          SetDecks([...deckNames]);
        })
        .catch((error) => {
          HandleSubmitError(error);
        });
    }
  }, []);

  function ValidateInputs() {
    var messages = [];
    // Validate Deck Name
    if (deckName === "" || deckName.length === 0) {
      messages.push("Deck Name cannot be left empty");
    }
    // Validate Deck Description
    if (mockIDs === "" || mockIDs.length === 0) {
      messages.push("Mock cannot be left empty");
    }
    /*  if (
      image === undefined ||
      image === null ||
      image === "" ||
      image.length === 0
    ) {
      messages.push("An image for the deck preview must be uploaded");
    } */
    //Return Logic for Function
    if (messages.length === 0) {
      setValidationErrorMessages([]);
      setisValidationError(false);
      return true;
    } else {
      setValidationErrorMessages([...messages]);
      setisValidationError(true);
      scrollToTop();
      return false;
    }
  }

  function OnSubmitClicked() {
    if (ValidateInputs() === true) {
       SubmitNewMock(deckName , mockIDs.split("\n"))
        .then((res) => ResolveResponse(res))
        .catch((error) => {
          HandleSubmitError(error);
        }); 
      console.log(mockIDs.split("\n"))
    } else {
      scrollToTop();
    }
  }
  function ResolveResponse(res) {
    var data = res.data;
    if (data.Error) {
      setValidationErrorMessages([...[data.ErrorText]]);
      setisValidationError(true);
      scrollToTop();
    }
    if (data.Status === "Success") {
      console.log("Saved Successfully");
      setisValidationError(false);
    }
  }
  function HandleSubmitError(error) {
    if (error.response) {
      var messages = [];
      messages.push("Could not save as server returned an error");
      setisValidationError(true);
      setValidationErrorMessages([...messages]);
      scrollToTop();
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      var messages = [];
      messages.push("Network Error / Cannot reach server");
      setisValidationError(true);
      setValidationErrorMessages([...messages]);
      scrollToTop();
    } else {
      // Something happened in setting up the request that triggered an Error
      var messages = [];
      messages.push("Could not save due to unknown error");
      setisValidationError(true);
      setValidationErrorMessages([...messages]);
      scrollToTop();
    }
  }
  return (
    <div className="flexMe">
      <Card>
        <CardHeader className="Header">
          <h3 style={{ textAlign: "center", fontfamily: "Roboto" }}>
            Add a new Mock Paper
          </h3>
        </CardHeader>

        <CardBody className="CardBody">
          <ErrorCard
            isVisible={isValidationError}
            messages={validationErrorMessages}
          />

          <label htmlFor="Deck Name">
            <h5 style={{ marginTop: "1.5rem" }}>Mock Name / Title</h5>
          </label>
          <FieldInput
            id="#Deck Name"
            placeholder="Deck Name"
            onChange={() => {}}
            type="text"
            multiline={false}
            size="sm"
            value={deckName}
            onChange={(e) => {
              SetDeckName(e.target.value);
            }}
            /*  invalid={!this.state.TextFields.FullNameIsValid}
                errorText={this.state.TextFields.FullNameErrorText} */
          />
          <hr />
          <label htmlFor="Question IDs">
            <h5 style={{ marginTop: "0rem" }}>Question IDs</h5>
          </label>
          <FieldInput
            style={{ height: "20px" }}
            id="#Question IDs"
            placeholder="Question IDs"
            onChange={() => {}}
            type="text"
            multiline={true}
            value = {mockIDs}
            onChange={(e) => {
              console.log(mockIDs)
              SetMockIDs(e.target.value);
            }}
            /*  invalid={!this.state.TextFields.FullNameIsValid}
                errorText={this.state.TextFields.FullNameErrorText} */
          />
            <label htmlFor="Question Tags">
                <h5>Select Deck to auto copy ids from</h5>
              </label>
              <FormSelect
               onChange={(e) => {
                SetCopyDeckName(e.target.value);
                }}
               value={copyDeckName}
              >
                {decks.map((name) => (
                  <option value={name}>{name}</option>
                ))}
              </FormSelect>
              <Button onClick = {()=>
              {GetQuestionIDs(copyDeckName).then((res) =>{
                var idstring = ""
                var rawids = [];
                res.data.forEach(element => {
                  rawids.push(element.Questions._id)
                });
                idstring = rawids.join('\n')
                SetMockIDs(idstring)
              })}
              }>
                Copy IDs
              </Button>
          <hr />
          <hr />
          <div class="form-row text-center">
            <div class="col-12">
              <div className="centerMe">
                <Button
                  style={{ marginTop: "2rem" }}
                  pill
                  theme="success"
                  onClick={() => {
                    OnSubmitClicked();
                  }}
                >
                  Create New Deck
                </Button>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

function FieldInput(props) {
  const id = props.id;
  const placeholder = props.placeholder;
  const type = props.type;
  const errorText = props.errorText;
  const invalid = props.invalid;
  const valid = props.valid;
  const onChange = props.onChange;
  const shake = props.shake;
  const onAnimationEnd = props.onAnimationEnd;

  function handleChange(e) {
    onChange(e);
  }

  var shakeStyle;
  if (shake == true) {
    shakeStyle = {
      animation: "shake .5s",
    };
  } else {
    shakeStyle = {
      animation: "",
    };
  }
  if (props.multiline === true) {
    return (
      <div>
        <FormTextarea
          id={props.id}
          placeholder={props.placeholder}
          type={props.type}
          value={props.value}
          onChange={handleChange}
          invalid={invalid}
          valid={valid}
          style={shakeStyle}
          onAnimationEnd={onAnimationEnd}
          style={{ height: "100px" }}
        />
        <span style={{ color: "red" }}>{props.errorText}</span>
      </div>
    );
  }
  return (
    <div>
      <FormInput
        id={props.id}
        placeholder={props.placeholder}
        type={props.type}
        value={props.value}
        onChange={handleChange}
        invalid={invalid}
        valid={valid}
        style={shakeStyle}
        onAnimationEnd={onAnimationEnd}
      />
      <span style={{ color: "red" }}>{props.errorText}</span>
    </div>
  );
}
function ImagePreview(props) {
  var { image, onDelete } = props;
  if (image !== undefined && image !== null && image.length > 0) {
    return (
      <div>
        <img
          src={image}
          width="350px"
          style={{ marginTop: "10px", marginRight: "10px" }}
        />
        <Button
          id={"delete image"}
          theme="danger"
          size="sm"
          onClick={(e) => {
            onDelete();
          }}
          style={{ marginTop: "1rem", marginBottom: "1.5rem" }}
        >
          Remove Image
        </Button>
      </div>
    );
  } else {
    return <div />;
  }
}
function ErrorCard(props) {
  if (props.isVisible) {
    return (
      <Al onClose={props.onClose} severity="error">
        <AlertTitle>Error</AlertTitle>
        {props.messages.map((message) => {
          return <div>{message}</div>;
        })}
      </Al>
    );
  } else {
    return <div></div>;
  }
}
function scrollToTop() {
  var position = document.body.scrollTop || document.documentElement.scrollTop;
  if (position) {
    window.scrollBy(0, -Math.max(1, Math.floor(position / 10)));
    var scrollAnimation = setTimeout(() => {
      scrollToTop();
    }, 10);
  } else clearTimeout(scrollAnimation);
}

export default AddNewDeck;
