import axios from "axios";
import { backendUrl } from "../Constants/appConstants";

const apiURL = `${backendUrl}/api`;
const representativeEndpoint = "representative";
const url = `${apiURL}/${representativeEndpoint}`;
export async function couponToCAApi(body) {
  return axios.put(`${url}/addCoupon`, body);
}
export async function CAToCAHeadApi(body) {
  return axios.put(`${url}/assignReportsTo`, body);
}
export async function CAHeadToCAManagerApi(body) {
  return axios.put(`${url}/assignReportsTo`, body);
}

export async function addRepresentativeApi({
  username,
  reportsToEmail,
  designation,
}) {
  return axios.post(`${url}/addRepresentative`, {
    username,
    reportsToEmail,
    designation,
  });
}

export async function addManagerApi({
  managerName,
  managerEmail,
  commissionRate,
}) {
  await axios.post(`${url}/addRepresentative`, {
    fullname: managerName,
    username: managerEmail,
    designation: "Campus Ambassador Manager",
  });
  return axios.patch(`${url}/addCommission`, {
    username: managerEmail,
    Commission: commissionRate,
  });
}

export async function addHeadApi({
  headName,
  headEmail,
  managerEmail,
  commissionRate,
}) {
  await axios.post(`${url}/addCAHead`, {
    fullname: headName,
    username: headEmail,
    reportsToEmail: managerEmail,
    designation: "Campus Ambassador Head",
    Commission: commissionRate,
  });
}


export async function addCAApi({
  couponCode,
  discountPercentage,
  CAName,
  CAEmail,
  commissionRate,
  headEmail,
}) {
  // try{
    return await axios.post(`${url}/AddNewCA`, {
      fullname: CAName,
      username: CAEmail,
      reportsToEmail: headEmail,
      designation: "Campus Ambassador",
      Commission: commissionRate,
      CouponCode: couponCode,
      Amount: discountPercentage,
      ContactEmail: CAEmail,
    });
  // }catch(err){
    // return err.response;
  // }
}

export async function addPromotionalCouponApi({
  couponCode,
  discountPercentage,
}) {
  return await axios.post(`${url}/AddNewCoupon`, {
    CouponCode: couponCode,
    Amount: discountPercentage,
  });
}

export async function addCommissionApi({ username, Commission }) {
  return axios.patch(`${url}/addCommission`, {
    username,
    Commission,
  });
}

export async function assignReportsToApi({ username, reportsToEmail, rate }) {
  return axios.put(`${url}/assignReportsTo`, {
    username,
    reportsToEmail,
    rate,
  });
}

export async function getCommissionDetailsApi({
  representativeId,
  designation,
}) {
  return await axios.get(
    `${backendUrl}/api/representative/getCommissionDetails?id=${representativeId}&designation=${designation
      .split(" ")
      .join("+")}`
  );
}

export async function getRepresentativesApi(designation) {
  return await axios.get(
    `${url}/getRepresentatives?designation=${designation}`
  );
}
